import React, { useEffect } from 'react'
import { useShowSidebar } from '../../zustand/Index'
import LinkChild from './LinkChild'
import { Link, useNavigate } from 'react-router-dom'
import ButtonFunction from '../../component/buttons/ButtonFunction'

const Sidebar = () => {
    const { showSidebar, setShowSidebar } = useShowSidebar()
    const navigate = useNavigate()
    let sideElement = [
        {
            link: '/dashboard',
            classIcon: 'fa fa-home',
            text: 'Home',
            roleAccess: ['admin', 'operator', 'koordinator'],
        },
        {
            link: 'user',
            classIcon: 'fa-sharp fa-solid fa-users',
            text: 'Users',
            roleAccess: ['admin'],
        },
        {
            link: 'dokumen',
            classIcon: 'fa-solid fa-folder-open',
            text: 'Dokumen',
            roleAccess: ['admin', 'operator', 'koordinator'],
            child: [
                {
                    id: 1,
                    text: 'SPM',
                    iconClass: 'fa-solid fa-file-lines',
                    link: `/dashboard/dokumen/daftar-dokumen/${1}`,
                },
                {
                    id: 2,
                    text: 'SPB',
                    iconClass: 'fa-solid fa-file-lines',
                    link: `/dashboard/dokumen/daftar-dokumen/${2}`
                },
                {
                    id: 3,
                    text: 'HIBAH',
                    iconClass: 'fa-solid fa-file-lines',
                    link: `/dashboard/dokumen/daftar-dokumen/${3}`
                },
            ]
        },
        {
            link: `profile/${localStorage.getItem('id')}`,
            classIcon: 'fa-sharp fa-solid fa-user',
            text: 'Profile',
            roleAccess: ['admin', 'operator', 'koordinator'],
        },
    ]
    const logout = () => {
        localStorage.clear()
        navigate('/', { replace: true })
    }
    useEffect(() => {
        setShowSidebar(false)
    }, [])

    return (
        <div className={`p-2 absolute z-10 w-80 h-full ${showSidebar == true ? 'block' : 'hidden'} lg:h-auto lg:relative lg:w-80 lg:block`}>
            <div className='rounded-xl bg-white w-full h-full'>
                <nav className="h-4/5">
                    <div className='p-2'>
                        <button onClick={() => setShowSidebar(showSidebar == true ? false : true)}>
                            <div className='w-8 justify-center items-center rounded-full border-2 border-default lg:hidden'>
                                <center>
                                    <i className='fa-solid fa-xmark text-lg text-default'></i>
                                </center>
                            </div>
                        </button>
                    </div>
                    <div className='flex flex-col py-2 justify-center items-center'>
                        <div>
                            <img className="h-48" src={process.env.PUBLIC_URL + "/icon/logoApp.png"} alt="" />
                        </div>
                    </div>
                    <div className='px-4'>
                        <ul>
                            {sideElement.map(element => {
                                return (
                                    <li className="my-10 lg:my-10">
                                        {element.roleAccess.includes(localStorage.getItem('role')) ? (
                                            <>
                                                {element.child ? (
                                                    <LinkChild iconClass={element.classIcon} setShowSidebar={() => setShowSidebar(false)} text={element.link} child={element.child} />
                                                ) : (
                                                    <Link onClick={() => setShowSidebar(false)} to={element.link}><span className={`${element.classIcon} text-default mr-2`}></span><h7 className="text-default">{element.text}</h7></Link>
                                                )}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </nav>
                <div className='h-1/6 relative'>
                    <div className='absolute w-full bottom-2 start-0 p-2 border-t-8 border-dashed border-gray-200'>
                        <ButtonFunction
                            text="Logout"
                            icon="fa-solid fa-right-from-bracket"
                            onclick={() => { logout() }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar

import React from 'react'
import { useShowSidebar } from '../../zustand/Index'

const ButtonSidebar = () => {
    const {showSidebar, setShowSidebar} = useShowSidebar()
    return (
        <div>
            <button onClick={() => setShowSidebar(showSidebar == true ? false : true)} className='lg:hidden my-4'>
                <div className='w-8 justify-center items-center rounded-full border-2 border-default'>
                    <center>
                        <i className='fa-solid fa-bars text-lg text-default'></i>
                    </center>
                </div>
            </button>
        </div>
    )
}

export default ButtonSidebar

import React from 'react'
import { Helmet } from 'react-helmet'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import StaticData from '../../connection/StaticData'
import ButtonSidebar from '../../component/buttons/ButtonSidebar'
import UserCard from '../../container/card/UserCard'
import InputFindDocument from '../../container/inputs/InputFindDocument'
import Sidebar from '../../container/sidebar/Sidebar'

const Template = () => {
  const staticData = new StaticData()
  const StyledDiv = styled.div`
  /* Untuk menyembunyikan scrollbar di browser WebKit */
  overflow: scroll;
  height: 100vh;
  flex: 1;
  background-color: 'red';

  &::-webkit-scrollbar {
    width: 0em; /* Atur lebar scrollbar sesuai kebutuhan */
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent; /* Warna thumb scrollbar */
  }

  /* Untuk menyembunyikan scrollbar di Firefox */
  scrollbar-width: thin; /* Atur ketebalan scrollbar sesuai kebutuhan */
`;
  return (
    <>
      <Helmet>
        <title>Dashboard Aplikasi Keuangan</title>
      </Helmet>
      <div className='bg-third'>
        <div className="relative flex flex-auto">
          <Sidebar />
          <StyledDiv>
            <div className='px-4'>
              <div className="">
                <ButtonSidebar />
                <div className='lg:grid xl:grid-cols-12 my-2 gap-2 flex flex-col-reverse lg:flex-col'>
                  <div className='xl:col-span-8 my-1 lg:my-0'>
                    <InputFindDocument/>
                  </div>
                  <div className='xl:col-span-4 my-1 lg:my-0'>
                    <UserCard customTailwind={`h-14`} />
                  </div>
                </div>
              </div>
              <div className=''>
                <Outlet />
              </div>
            </div>
          </StyledDiv>
        </div >
      </div >
    </>
  )
}

export default Template

import React, {useState} from 'react'
import { getApi } from '../../connection/Api'
import TailwindModal from '../modal/TailwindModal'

const PinManagement = ({user, setUser}) => {
    const [showPin, setShowPin] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [loading, setLoading] = useState(false)
    const changePin = async () => {
        setLoading(true)
        const result = await getApi(`user/change-pin/${user.username}`)
        const userResult = await getApi(`user/get-by-id/${user.id}`)
        setUser(userResult.data)
        localStorage.setItem('pin', userResult.data.username)
        setShowConfirm(false)
        setLoading(false)
    }
    return (
        <div className='mb-3'>
            <span className='block font-bold mb-2'>Pin</span>
            <div className='flex flex-row gap-2'>
                <div className=''>
                    <button type="button" className='bg-secondary px-2 py-1 rounded-lg w-full text-purple-900' onClick={() => setShowPin(showPin === false ? true : false)}>
                        <span className='mr-2'>{showPin === false ? "Lihat PIN" : user.username}</span>
                        <i className={`fa-solid ${showPin === false ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                    </button>
                </div>
                <div className=''>
                    <button type="button" className='bg-yellow-200 px-2 py-1 rounded-lg w-full text-yellow-900' onClick={() => setShowConfirm(true)}>
                        <span className='mr-2'>Ganti PIN</span>
                        <i className='fa-solid fa-rotate'></i>
                    </button>
                </div>
            </div>
            {showConfirm === true && 
                <TailwindModal cancelButtonText={"Batal"} confirmButtonText={"Ganti PIN"} showCancelButton={true} showConfirmButton={true} title={"Konfirmasi Ganti PIN"} text={"Apakah anda yakin ?"} canceButtonAction={() => setShowConfirm(false)} confirmButtonAction={changePin} loadingConfirm={loading}/>
            }
        </div>
    )
}

export default PinManagement

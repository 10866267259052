import React from 'react'
import { useNavigate } from 'react-router-dom'

const LsCard = () => {
    const navigate = useNavigate()
    return (
        <div className='flex flex-auto h-full'>
            <div className='w-full rounded-xl bg-white p-2'>
                <div className='flex flex-row'>
                    <div className='w-auto h-full p-2 justify-center items-center'>
                        <i className='fa-solid fa-shield text-dark text-4xl'></i>
                    </div>
                    <div className='flex flex-col px-2'>
                        <div>
                            <span className='text-dark text-lg'>Lihat Dokumen</span>
                        </div>
                        <div className='flex flex-row'>
                            <div className='mr-2'>
                                <button onClick={() => navigate(`/dashboard/dokumen/daftar-dokumen/${1}`)} className='rounded-xl border-2 border-default py-0 px-2 text-default font-bold'>SPM</button>
                            </div>
                            <div className='mr-2'>
                                <button onClick={() => navigate(`/dashboard/dokumen/daftar-dokumen/${2}`)} className='rounded-xl border-2 border-default py-0 px-2 text-default font-bold'>SPB</button>
                            </div> 
                            <div className='mr-2'>
                                <button onClick={() => navigate(`/dashboard/dokumen/daftar-dokumen/${3}`)} className='rounded-xl border-2 border-default py-0 px-2 text-default font-bold'>HIBAH</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LsCard

import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import Input from './Input'

const InputFindDocument = () => {
    const [number, setNumber] = useState()
    const navigate = useNavigate()
    const searchData = () => {
        navigate(`/dashboard/dokumen/cari/${number}`)
    }
    return (
        <div className='flex flex-row w-full gap-2'>
            <div className='flex flex-auto'>
                <Input
                    onchange={(e) => setNumber(e.target.value)}
                    placeholder='Cari nomor dokumen'
                    firstIcon='fa-solid fa-magnifying-glass'
                    tailwindTheme='gray-200'
                    customTailwind='h-14 w-full'
                />
            </div>
            <div>
                <button onClick={searchData} className='w-full bg-default h-full rounded-lg text-white px-5'>Cari</button>
            </div>
        </div>
    )
}

export default InputFindDocument

import React from 'react'
import { useNavigate } from 'react-router-dom'

const CardDataDocument = ({ documentNumber, documentNumberId }) => {
    const navigation = useNavigate()
    const mappingFormOption = (array, param, key) => {
        const lowerCaseSearchTerm = param.toLowerCase();
        const result = array.find(item => item[key] == lowerCaseSearchTerm)
        console.log(array)
        return result
    }

    return (
        <div className='p-4 bg-white rounded-xl w-auto h-min'>

            {documentNumber.document.map(item => (
                <div>
                    <p className='text-dark font-bold'>{item.form_document_by_ls.label}</p>
                    {item.form_document_by_ls.id == 1 || item.form_document_by_ls.id == 20 || item.form_document_by_ls.id == 9 || item.form_document_by_ls.id == 28 ? (
                        <p className='ml-2 text-dark'>{mappingFormOption(item.form_document_by_ls.form_option, item.data_value, 'value').text}</p>
                    ) : (
                        <p className='ml-2 text-dark'>{item.data_value}</p>
                    )}
                </div>
            ))}
            <div className='w-full mt-4'>
                <button className='my-1 w-full p-2 bg-default rounded-xl text-white' onClick={() => navigation(`/dashboard/dokumen/edit/${documentNumberId}`)}>Edit</button>
            </div>
        </div>
    )
}

export default CardDataDocument

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getApi, postApi } from '../../connection/Api';
import StaticData from '../../connection/StaticData';
import TailwindModal from '../modal/TailwindModal';

const DocumentCard = ({ item, position = {}, allTrack = {}, allStatusTrack = {}, setDoc = {}, showValidasiButton = true }) => {
    const [showDetail, setShowDetail] = useState(false)
    const [showModalReset, setShowModalReset] = useState(false)
    const [loadingReset, setLoadingReset] = useState(false)
    const [idReset, setIdReset] = useState()
    const [loadingTrack, setLoadingTrack] = useState(false)
    const staticData = new StaticData
    const navigate = useNavigate()
    const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "#ffffff",
    };
    const formatDate = (dateTime) => {
        // Membuat objek Date dari string
        const waktu = new Date(dateTime);

        // Mendapatkan tanggal dengan format dua digit
        const tanggal = waktu.toLocaleDateString('id-ID', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
        return tanggal
    }
    const getDocumentByIdForm = (data, id, keyFilter) => {
        const result = data.filter(element => element[keyFilter] === id);
        return result[0]
    }
    const filterPosition = (id) => {
        let result = position.filter(element => element.id === parseInt(id))
        return result[0]
    }
    const saveTrack = async (e) => {
        setLoadingTrack(true)
        e.preventDefault()
        const formData = new FormData(e.target)
        let data = Object.fromEntries(formData.entries())
        let dataTrack = {
            'document_number_id': item.id,
            'track_id': data.track_id,
            'status_track': data.status_track,
        }
        let postTrack = await postApi('document/manage-track', dataTrack)
        let doc = await getApi(`document-number/get-by-id?id=${item.id}`)
        setDoc(doc.data)
        setLoadingTrack(false)
    }
    const resetTrack = async (id) => {
        setLoadingReset(true)
        let result = await getApi(`document-number/reset-track?id=${id}`)
        console.log(result)
        setDoc(result.data)
        setLoadingReset(false)
        setShowModalReset(false)
    }
    return (
        <div className='p-4 bg-white rounded-xl w-auto h-min'>
            <div className='grid grid-cols-2'>
                <div>
                    <span className='text-dark'>{formatDate(item.updated_at)}</span>
                </div>
                <div className='flex flex-row justify-end items-end'>
                    <span className='text-dark font-bold'>#{item.number}</span>
                </div>
            </div>
            <div>
                <div>
                    <div className={`
                        font-bold
                        text-dark
                        ${showDetail == false
                            ?
                            `teks-container 
                            max-w-full 
                            overflow-hidden 
                            whitespace-nowrap 
                            overflow-ellipsis`
                            :
                            ``
                        }
                    `}>
                        {
                            item.jenis_ls_id === staticData.spmId &&
                            getDocumentByIdForm(item.document, 2, "form_document_by_ls_id").data_value
                        }
                        {
                            item.jenis_ls_id === staticData.spbId &&
                            getDocumentByIdForm(item.document, 21, "form_document_by_ls_id").data_value
                        }
                        {
                            item.jenis_ls_id === staticData.hibahId &&
                            getDocumentByIdForm(item.document, 35, "form_document_by_ls_id").data_value
                        }
                    </div>
                </div>
                <div>
                    {
                        item.jenis_ls_id === staticData.spmId &&
                        <span className='text-dark'>{filterPosition(getDocumentByIdForm(item.document, 1, "form_document_by_ls_id").data_value).position_name}</span>
                    }
                    {
                        item.jenis_ls_id === staticData.spbId &&
                        <span className='text-dark'>{filterPosition(getDocumentByIdForm(item.document, 20, "form_document_by_ls_id").data_value).position_name}</span>
                    }
                </div>
                <div className='grid grid-cols-12 my-3'>
                    <div className='col-span-10 flex flex-auto'>
                        <span className={`
                            px-2 
                            rounded-xl
                            ${item.document_track[item.document_track.length - 1].status_track_id == allStatusTrack[0].id &&
                            `   
                                    bg-emerald-400
                                    border-2
                                    text-green-900
                                    border-green-900
                                `
                            }
                            ${item.document_track[item.document_track.length - 1].status_track_id == allStatusTrack[1].id &&
                            `   
                                    bg-gray-400
                                    border-2
                                    text-dark
                                    border-gray-600
                                `
                            }
                            ${item.document_track[item.document_track.length - 1].status_track_id == allStatusTrack[2].id &&
                            `   
                                    bg-red-500
                                    border-2
                                    text-red-1000
                                    border-red-900
                                `
                            }
                    `}>{item.document_track[item.document_track.length - 1].track.track_name}
                        </span>
                    </div>
                    <div className='col-span-2 flex flex-auto justify-end items-center'>
                        <button onClick={() => setShowDetail(showDetail == false ? true : false)}>
                            <i className={`fa-solid ${showDetail == false ? 'fa-chevron-down' : 'fa-chevron-up'}`}></i>
                        </button>
                    </div>
                </div>
                {showDetail == true &&
                    <div className='mt-2'>
                        <div className='border-t-2 border-t-secondary border-dashed my-2'></div>
                        <div>
                            {allTrack.map((data, key) => (
                                <>
                                    <div className='flex flex-row'>
                                        <div className='flex flex-col justify-center items-center w-7'>
                                            {
                                                item.document_track[key] ? (
                                                    <div>
                                                        {
                                                            item.document_track[key].status_track_id == allStatusTrack[0].id &&
                                                            <div className='rounded-full bg-green-500 w-5 h-5 flex m-1 justify-center items-center'>
                                                                <i className='fa-solid fa-check text-white'></i>
                                                            </div>
                                                        }
                                                        {
                                                            item.document_track[key].status_track_id == allStatusTrack[1].id &&
                                                            <div className='rounded-full bg-gray-500 w-5 h-5 flex m-1 justify-center items-center'>
                                                                <i className='fa-solid fa-clock text-white'></i>
                                                            </div>
                                                        }
                                                        {
                                                            item.document_track[key].status_track_id == allStatusTrack[2].id &&
                                                            <div className='rounded-full bg-red-500 w-5 h-5 flex m-1 justify-center items-center'>
                                                                <i className='fa-solid fa-xmark text-white'></i>
                                                            </div>
                                                        }
                                                    </div>
                                                ) : (
                                                    <div className='rounded-full h-5 w-5 bg-gray-300 mx-1'>

                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className='flex flex-auto'>
                                            <span>{data.track_name}</span>
                                        </div>
                                    </div>
                                    <div className='flex flex-row'>
                                        <div className='h-7 w-7 flex justify-center items-center'>
                                            {allTrack.length > key + 1 &&
                                                <div className='border-l-2 border-l-secondary border-dashed h-full w-1'>

                                                </div>
                                            }
                                        </div>
                                        <div className='flex flex-auto'>
                                            {
                                                item.document_track[key] && item.document_track[key].track_id != 1 && item.document_track[key].status_track_id != 1 ? (
                                                    <div>
                                                        <form onSubmit={(e) => { saveTrack(e) }}>
                                                            <div className='flex flex-row'>
                                                                <div className='w-32'>
                                                                    <input type="hidden" name={'track_id'} defaultValue={data.id} />
                                                                    <select name="status_track" id="" className='border-2 border-dark rounded-xl px-3 py-0 h-full w-full'>
                                                                        <option value="">Status</option>
                                                                        {allStatusTrack.map(track => (
                                                                            <option selected={track.id == item.document_track[key].status_track_id ? true : false} value={track.id}>{track.status_track_name}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className='mx-2'>
                                                                    <button type="submit" className='bg-default px-2 py-0 rounded-xl text-white font-bold h-full w-10'>
                                                                        {
                                                                            loadingTrack == false ? "Set" : (
                                                                                <div role="status">
                                                                                    <svg aria-hidden="true" class="w-full h-full text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                                                    </svg>
                                                                                    <span class="sr-only">Loading...</span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                ) : (
                                                    <div className='flex xl:flex-row w-full'>
                                                        {item.document_track[key] &&
                                                            <>
                                                                <div className='flex flex-1'>
                                                                    {
                                                                        item.document_track[key].user &&
                                                                        <span>{item.document_track[key].user.name}</span>
                                                                    }
                                                                </div>
                                                                <div className='text-right'>
                                                                    <span className='text-sm'>{staticData.formatDate(item.document_track[key].updated_at)}</span>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </>
                            ))}
                            {localStorage.getItem('role') != "operator" &&
                                <div className='my-2 flex flex-auto'>
                                    <button className='w-full p-2 border-2 border-yellow-300 rounded-xl text-yellow-300' onClick={() => {
                                        setShowModalReset(true)
                                        setIdReset(item.id)
                                    }}>Reset</button>
                                </div>
                            }
                        </div>
                        <div className='border-t-2 border-t-secondary border-dashed my-2'></div>
                        <div className='my-2'>
                            <span className='block text-dark'>Jenis LS</span>
                            <span className='text-dark font-bold'>{item.jenis_ls.text}</span>
                        </div>
                        <div className='my-2'>
                            <span className='block text-dark'>Nama Perusahaan</span>
                            <span className='text-dark font-bold'>
                                {
                                    item.jenis_ls_id === staticData.spmId &&
                                    getDocumentByIdForm(item.document, 12, "form_document_by_ls_id").data_value
                                }
                                {
                                    item.jenis_ls_id === staticData.spbId &&
                                    getDocumentByIdForm(item.document, 31, "form_document_by_ls_id").data_value
                                }
                            </span>
                        </div>
                        {showValidasiButton === true &&
                            <div>
                                {localStorage.getItem('role') == "koordinator" &&
                                    <div className='my-2 flex flex-auto'>
                                        <button className='w-full p-2 border-2 border-default rounded-xl text-default' onClick={() => navigate(`/dashboard/dokumen/validasi/${item.id}`)}>Validasi</button>
                                    </div>
                                }
                            </div>
                        }
                        {localStorage.getItem('role') == "operator" &&
                            <div>
                                <button className='my-1 w-full p-2 border-2 border-default rounded-xl text-default' onClick={() => navigate(`/dashboard/dokumen/detail/${item.number}`)}>Detail</button>
                            </div>
                        }
                    </div>
                }
            </div>
            {showModalReset === true &&
                <TailwindModal cancelButtonText={"Batal"} showCancelButton={true} confirmButtonText={"Reset"} showConfirmButton={true} loadingConfirm={loadingReset} title={"Reset Status Dokumen"} text={"Apakah anda yakin ?"} canceButtonAction={() => setShowModalReset(false)} confirmButtonAction={() => resetTrack(idReset)} type="warning" />
            }
        </div>
    )
}

export default DocumentCard

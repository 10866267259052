import { motion } from "framer-motion"
import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
// import ButtonPrimary from "../../component/button/ButtonPrimary"
// import { apiPublicWithParams, postApiPublic } from "../../connection/Api"
import Input from "../../container/inputs/Input"
import ButtonPrimary from "../../component/buttons/ButtonPrimary"
import { apiPublicWithParams, postApiPublic } from "../../connection/Api"
import { useAccount } from "../../zustand/Index"

const CardAuth = () => {
    const [showPage, setShowPage] = useState(1)
    const [loadingFind, setLoadingFind] = useState(false)
    const [loadingLogin, setLoadingLogin] = useState(false)
    const [findInfo, setFindInfo] = useState()
    const [loginInfo, setLoginInfo] = useState()
    const [showPin, setShowPin] = useState('password')
    const {setAccount} = useAccount()
    const navigate = useNavigate()

    const findDocument = async (e) => {
        e.preventDefault()
        setFindInfo(null)
        setLoadingFind(true)
        var form = new FormData(e.target)
        var data = Object.fromEntries(form.entries())
        window.location.href = `/dokumen-nomor-rekening/${data.nomor_rekening}`
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoadingLogin(true)
        var form = new FormData(e.target)
        var data = Object.fromEntries(form.entries())

        let dataLogin = {
            'pin': data.pin,
        }

        let resultLogin = await postApiPublic("login", dataLogin)
        let postLogin = resultLogin.data
        if (resultLogin.status === "Success") {
            localStorage.clear();
            localStorage.setItem('pin', data.pin)
            localStorage.setItem('id', postLogin.id)
            localStorage.setItem('name', postLogin.name)
            localStorage.setItem('role', postLogin.role)
            localStorage.setItem('token', postLogin.access_token)
            localStorage.setItem('avatar', postLogin.avatar)
            localStorage.setItem('position', JSON.stringify(postLogin.positions))
            
            setAccount({
                pin: data.pin,
                id: postLogin.id,
                name: postLogin.name,
                role: postLogin.role,
                token: postLogin.access_token,
                avatar: postLogin.avatar,
                position: JSON.stringify(postLogin.positions)
            })
            navigate('/dashboard', { replace: false })

            setLoadingLogin(false)
        } else {
            setLoadingLogin(false)
            setLoginInfo('PIN Salah')
        }
    }
    return (
        <>
            <div className="rounded-3xl bg-white p-5 w-full">
                <center>
                    <motion.img
                        animate={{ scale: 0.95 }}
                        transition={{ yoyo: Infinity, duration: 0.5 }}
                        className="w-60" src={process.env.PUBLIC_URL + "/icon/logoApp.png"} alt="" />
                </center>
                {/* <center>
                    <span className="text-default space-x-1 text-4xl fw-bold">SIITAKA</span>
                </center> */}
                <div className="grid grid-cols-2 border-b-2 border-b-default my-3">
                    <div className="flex-auto">
                        <button onClick={() => setShowPage(1)} className={`w-full ${showPage === 1 ? 'font-bold text-default bg-lightBlue border-b-4 border-b-default' : ''} p-2`}>Tracking</button>
                    </div>
                    <div className="flex-auto">
                        <button onClick={() => setShowPage(2)} className={`w-full ${showPage === 2 ? 'font-bold text-default bg-lightBlue border-b-4 border-b-default' : ''} p-2`}>Login</button>
                    </div>
                </div>
                <div className="my-2">
                    {showPage === 1 ? (
                        <div>
                            <form id="form-input-nomor-berkas" onSubmit={(e) => findDocument(e)}>
                                {findInfo &&
                                    <div className="p-3 my-1 rounded-lg w-full bg-red-300 relative">
                                        <span className="text-light">{findInfo}</span>
                                        <button onClick={() => setFindInfo(null)}><i className="fa-solid fa-xmark absolute end-3 top-5 text-light"></i></button>
                                    </div>
                                }
                                <div className="">
                                    {/* <Input name="nomor_berkas" id="nomor-berkas" required="true" type="text" textLabel="Nomor Berkas" tailwindTheme={'default'} firstIcon='fa-solid fa-magnifying-glass' /> */}
                                    <Input name="nomor_rekening" id="nomor-rekening" required="true" type="text" textLabel="Nomor Rekening" tailwindTheme={'default'} firstIcon='fa-solid fa-magnifying-glass' />
                                </div>
                                <div className="mt-2">
                                    <ButtonPrimary type="submit" text_button={'Cari'} loading={loadingFind} style={`p-3 bg-default`} textStyle={`text-white`} />
                                </div>
                            </form>
                        </div>
                    ) : (
                        <div>
                            <form id="form-input-nomor-pin" onSubmit={(e) => { handleSubmit(e) }}>
                                {loginInfo &&
                                    <div className="p-3 my-1 rounded-lg w-full bg-red-300 relative">
                                        <span className="text-light">{loginInfo}</span>
                                        <button onClick={() => setLoginInfo(null)}><i className="fa-solid fa-xmark absolute end-3 top-5 text-light"></i></button>
                                    </div>
                                }
                                <Input tailwindTheme={'default'} textLabel={'PIN'} name={'pin'} secondIcon={`fa-solid ${showPin === 'password' ? 'fa-eye' : 'fa-eye-slash'}`} type={showPin} secondIconClick={() => setShowPin(showPin === 'password' ? 'text' : 'password')} />
                                <div className="mt-2">
                                    <ButtonPrimary type="submit" loading={loadingLogin} text_button={'Login'} style={`p-3 bg-default`} textStyle={`text-white`} />
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default CardAuth
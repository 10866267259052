import { Navigate, Outlet } from "react-router-dom"
import IsLogin from "../auth/IsLogin"

const LoggedIn = () => {
    const islogin = IsLogin()
    // return islogin ? <Outlet/> : <Navigate to="/login"/>
    if (islogin) {
        return <Outlet/>
    } else {
        return <Navigate to="/"/>
    }
}
export default LoggedIn
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CircleLoader } from 'react-spinners'
import { getApi } from '../../../connection/Api'
import StaticData from '../../../connection/StaticData'
import DocumentCard from '../../../container/card/DocumentCard'
import DocumentCardAdmin from '../../../container/card/DocumentCardAdmin'
import LsCard from '../../../container/card/LsCard'
import Select from '../../../container/inputs/Select'
import { useAllStatusTrack, useAllTrack, useDocs, usePosition } from '../../../zustand/Index'

const DocumentList = () => {
    const { lsId } = useParams()
    const [track, setTrack] = useState("")
    const [dateFilterValue, setDateFilterValue] = useState()
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [page, setPage] = useState(1)
    const [loadingFilter, setLoadingFilter] = useState(false)
    const { docs, setDocs } = useDocs()
    const [ready, setReady] = useState(false)
    const { position, setPosition } = usePosition()
    const { allTrack, setAllTrack } = useAllTrack()
    const { allStatusTrack, setAllStatusTrack } = useAllStatusTrack()
    const { changeDocById } = useDocs()
    const staticData = new StaticData()
    const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "#ffffff",
    };
    let optionDateFilter = [
        {
            text: "30 hari terakhir",
            value: 1,
        },
        {
            text: "90 hari terakhir",
            value: 2,
        },
        {
            text: "Pilih tanggal sendiri",
            value: 3,
        },
    ]
    async function getData() {
        setReady(false)
        // setPage(1)
        const resultDocument = await getApi(`document/new?page=${1}&jenis_ls_id=${lsId}`)
        setDocs(resultDocument.data)

        const resultPosition = await getApi('form/all-position')
        setPosition(resultPosition.data)

        // let resultAllTrack = await getApi('track/all')
        // setAllTrack(resultAllTrack.data)
        const resultAllTrack = await getApi('document/all-track')
        setAllTrack(resultAllTrack.data)

        const resultAllStatusTrack = await getApi('document/all-status-track')
        setAllStatusTrack(resultAllStatusTrack.data)

        setReady(true)
    }
    const settingData = async (currentPage) => {
        // setLoadingFilter(true)
        const resultDocument = await getApi(`document/new?jenis_ls_id=${lsId}&page=${currentPage}&track_id=${track}&start_date=${startDate}&end_date=${endDate}`)
        setPage(currentPage)
        setDocs(resultDocument.data)
        // setLoadingFilter(false)
        window.location.href = '#document-wrapper'
    }
    const changeFilterStatus = (e) => {
        setTrack(e.target.value)
    }
    const changeFilterDate = (e) => {
        let currentDate = new Date()
        console.log(e.target.value)
        if (e.target.value === "1") {
            setStartDate(staticData.dateWithRange(currentDate, 30))
            setEndDate(currentDate.toISOString().split('T')[0])
        } else if (e.target.value === "2") {
            setStartDate(staticData.dateWithRange(currentDate, 90))
            setEndDate(currentDate.toISOString().split('T')[0])
        } else if (e.target.value === "") {
            setStartDate("")
            setEndDate("")
        } else {

        }
        setDateFilterValue(e.target.value)
    }
    useEffect(() => {
        getData()
    }, [lsId])

    if (ready === false) {
        return (
            <></>
        )
    } else {
        return (
            <div className='flex flex-col h-max justify-between'>
                <div className='my-5'>
                    <div className='lg:grid lg:grid-cols-12 gap-2'>
                        <div className='my-2 lg:my-0 col-span-4'>
                            <LsCard />
                        </div>
                        <div className='my-2 lg:my-0 col-span-8 bg-white rounded-xl p-3'>
                            <div className='lg:grid lg:grid-cols-12 items-center gap-2 py-2 lg:py-0'>
                                <div className='my-2 lg:my-0 col-span-4'>
                                    <Select name={"status"} tailwindTheme='-gray-100' selectCustomClass={'px-2 py-3 w-full'} option={allTrack} optionKeyValue={'id'} optionKeyName={'track_name'} firstOptionText='Status Berkas' onchange={(e) => changeFilterStatus(e)} />
                                </div>
                                <div className='my-2 lg:my-0 col-span-4'>
                                    <Select name={"date"} tailwindTheme='-gray-100' selectCustomClass={'px-2 py-3 w-full'} option={optionDateFilter} optionKeyValue={'value'} optionKeyName={'text'} firstOptionText='Tanggal' onchange={(e) => changeFilterDate(e)} />
                                </div>
                                {dateFilterValue === "3" &&
                                    <div className='flex flex-row my-2 gap-2 inline lg:hidden'>
                                        <div className='w-full'>
                                            <label htmlFor="">Tanggal Mulai</label>
                                            <input type="date" className='w-full border-2 border-gray-400 rounded-lg' onChange={(e) => setStartDate(e.target.value)} />
                                        </div>
                                        <div className='w-full'>
                                            <label htmlFor="">Tanggal Akhir</label>
                                            <input type="date" className='w-full border-2 border-gray-400 rounded-lg' onChange={(e) => setEndDate(e.target.value)} />
                                        </div>
                                    </div>
                                }
                                <div className='my-2 lg:my-0 col-span-4'>
                                    <button onClick={() => settingData(1)} className='bg-default w-full rounded-xl px-2 py-4 text-white hover:bg-gray-200'>
                                        {loadingFilter === false ? "Filter" : (
                                            <CircleLoader
                                                color={'#ffffff'}
                                                loading={true}
                                                cssOverride={override}
                                                size={12}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            />
                                        )}
                                    </button>
                                </div>
                            </div>
                            {dateFilterValue === "3" &&
                                <div className='flex-row my-2 gap-2 hidden lg:flex'>
                                    <div className='w-full'>
                                        <label htmlFor="">Tanggal Mulai</label>
                                        <input type="date" className='w-full border-2 border-gray-400 rounded-lg' onChange={(e) => setStartDate(e.target.value)} />
                                    </div>
                                    <div className='w-full'>
                                        <label htmlFor="">Tanggal Akhir</label>
                                        <input type="date" className='w-full border-2 border-gray-400 rounded-lg' onChange={(e) => setEndDate(e.target.value)} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div id='document-wrapper'>
                    {localStorage.getItem('role') === 'admin' ? (

                        <div className=''>
                            {docs.data.map((item) => (
                                <DocumentCardAdmin item={item} position={position} allStatusTrack={allStatusTrack} />
                            ))}
                        </div>
                    ) : (
                        <div className='lg:grid lg:grid-cols-3 justify-center gap-2'>
                            {docs.data.map((item) => (
                                <div className='my-2 lg:my-0'>
                                    <DocumentCard item={item} position={position} allTrack={allTrack} allStatusTrack={allStatusTrack} setDoc={(value) => changeDocById(item.id, value)} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <center>
                    <div className='flex flex-auto justify-center items-center'>
                        <div className='gap-1 w-full lg:w-auto'>
                            {docs.links.map(item => {
                                return (
                                    <button
                                        disabled={item.url === null ? true : false}
                                        className={`  
                                        px-2 
                                        my-2
                                        mx-1
                                        rounded-lg
                                        ${item.active === true &&
                                            `bg-default text-white border-default text-default`
                                            }
                                        ${item.active === false && item.url !== null ?
                                                `bg-secondary border-default text-default` : ``
                                            }
                                        ${item.active === false && item.url === null ?
                                                `bg-white border-gray text-gray-300` : ``
                                            }`}
                                        onClick={() => {
                                            let showPage
                                            if (item.label === 'pagination.previous' && item.url !== null) {
                                                showPage = docs.current_page - 1
                                            } else if (item.label === 'pagination.next' && item.url !== null) {
                                                showPage = docs.current_page + 1
                                            } else if (item.label !== 'pagination.next' && item.label !== 'pagination.previous') {
                                                showPage = item.label
                                            } else {
                                                showPage = 1
                                            }
                                            console.log(item.label)
                                            settingData(showPage)
                                        }}
                                    >
                                        {item.label === 'pagination.previous' &&
                                            <i className='fa-solid fa-chevron-left'></i>
                                        }
                                        {item.label === 'pagination.next' &&
                                            <i className='fa-solid fa-chevron-right'></i>
                                        }
                                        {item.label !== 'pagination.next' && item.label !== 'pagination.previous' ? (
                                            item.label
                                        ) : ''
                                        }
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                </center>
            </div>
        )
    }
}

export default DocumentList

import React from 'react'

const Select = ({ name, tailwindTheme = "default", selectCustomClass, option, optionKeyValue, optionKeyName, selectedValue, firstOptionText = "", onchange = () => { }, label = "", customTailwind = "", required = false }) => {
    return (
        <div className={customTailwind}>
            {label != '' &&
                <div className=''>
                    <label htmlFor="" className={`text-${tailwindTheme}`}>{label}</label>
                </div>
            }
            <select name={name} className={`rounded-lg px-2 py-2 border-2 w-full border-${tailwindTheme} ${selectCustomClass}`} onChange={onchange} required={required}>
                {firstOptionText != "" &&
                    <option value="">{firstOptionText}</option>
                }
                {option.map(item => (
                    <option selected={item[optionKeyValue] == selectedValue ? true : false} value={item[optionKeyValue]}>{item[optionKeyName]}</option>
                ))}
            </select>
        </div>
    )
}

export default Select

import ApexCharts from 'apexcharts';
import React, { useEffect, useState } from 'react';
import CardCount from '../../container/card/CardCount';
import { getApi, getApiPublic, postApi } from '../../connection/Api';
import DocumentCard from '../../container/card/DocumentCard';
import DocumentCardAdmin from '../../container/card/DocumentCardAdmin';
import LsCard from '../../container/card/LsCard';
import RegisterCard from '../../container/card/RegisterCard';
import { useAllStatusTrack, useAllTrack, useDocs, usePosition } from '../../zustand/Index';


const Index = () => {
    const [countByTrack, setCountByTrack] = useState()
    const [countByYear, setCountByYear] = useState()
    const [information, setInformation] = useState()
    const [yearDataFilter, setYearDataFilter] = useState()
    // const [current]
    const [ready, setReady] = useState(false)
    const { docs, setDocs } = useDocs()
    const { position, setPosition } = usePosition()
    const { allTrack, setAllTrack } = useAllTrack()
    const { allStatusTrack, setAllStatusTrack } = useAllStatusTrack()
    const { changeDocById } = useDocs()
    let dataY = []
    let dataX = []

    const getData = async () => {
        const dataCountByTrack = await getApi('statistics/by-track?year=2024')
        setCountByTrack(dataCountByTrack.data)

        const dataCountByYear = await getApi('statistics/by-year')
        setCountByYear(dataCountByYear.data)

        const info = await getApiPublic('information')
        setInformation(info.data)

        const newDoc = await getApi('document/new')
        setDocs(newDoc.data)

        const resultPosition = await getApi('form/all-position')
        setPosition(resultPosition.data)

        const resultAllTrack = await getApi('document/all-track')
        setAllTrack(resultAllTrack.data)

        const resultAllStatusTrack = await getApi('document/all-status-track')
        setAllStatusTrack(resultAllStatusTrack.data)

        let currentDate = new Date();

        let currentYear = currentDate.getFullYear();
        
        let yearData = []
        for(let i = currentYear; i >= currentYear - 5; i-- ){
            console.log(i)
            yearData.push(i)
        }
        console.log(yearData)
        setYearDataFilter(yearData)
        setReady(true)
    }
    const setFilterCount = async (e) => {
        let year = e.target.value
        console.log(year)

        const dataCountByTrack = await getApi(`statistics/by-track?year=${year}`)
        setCountByTrack(dataCountByTrack.data)
    }

    async function mapping() {
        countByYear.map(element => {
            dataX.push(element.year)
            dataY.push(element.count)
        })
        var options = {
            chart: {
                type: 'bar',
                height: 465,
            },
            series: [{
                name: 'Jumlah Dokumen',
                data: dataY
            }],
            xaxis: {
                categories: dataX,
            },
            stroke: {
                show: true,
                // curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    borderRadius: 10, // Sesuaikan nilai ini sesuai keinginan Anda
                }
            },
            fill: {
                colors: ['#8E4957', '#FFD9DE', '#FFD9DE'],
            },
        }

        var chart = new ApexCharts(document.querySelector("#chart"), options);

        chart.render();
    }

    function showEditInfo() {
        document.getElementById('wrap-text-info').classList.add('d-none')
        document.getElementById('wrap-form-info').classList.remove('d-none')
    }
    function hideEditInfo() {
        document.getElementById('wrap-form-info').classList.add('d-none')
        document.getElementById('wrap-text-info').classList.remove('d-none')
    }

    async function updateInfo(e) {
        let btn = document.getElementById('btn-submit-info')
        btn.innerHTML = `<div class="spinner-border text-primary spinner-border-sm" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>`
        e.preventDefault();
        let form = new FormData(e.target)
        let data = Object.fromEntries(form.entries())
        let resultUpdate = await postApi('information/update', data)

        hideEditInfo()
        setInformation(resultUpdate.data.text_info)
        setTimeout(() => {
            document.getElementById('text-info').textContent = resultUpdate.data.text_info
        }, 100);

        btn.innerHTML = "Simpan"
    }


    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if (ready === true) {
            mapping()
        }
    }, [ready])


    return (
        <div>
            {ready == true ? (
                <>
                    <div className='mt-5'>
                        <span className='text-default text-xl'>Statistik Permohonan</span>
                    </div>
                    <div className='lg:grid lg:grid-flow-col lg:h-auto'>
                        <div className='lg:grid xl:grid-cols-12 my-2'>
                            <div className='py-2 xl:col-span-8 lg:px-1 lg:p-6'>
                                <div className='lg:col-span-2'>
                                    <div className='p-6 rounded-2xl bg-secondary'>
                                        <div id="chart">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='py-2 xl:col-span-4 lg:px-1 lg:p-6'>
                                <div className='mb-3 w-full'>
                                    <select onChange={(e) => setFilterCount(e)} name="" id="" className='w-full border-0 rounded-xl bg-secondary text-default font-bold'>
                                        {yearDataFilter.map(item => (
                                            <option className='text-default' value={item}>{item}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='grid grid-cols-2 gap-6'>
                                    {ready === true &&
                                        countByTrack.map(element => (
                                            <div>
                                                <CardCount number={element.count} text={element.track_name} />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='my-2'>
                        <div className='lg:grid lg:grid-cols-12 gap-2'>
                            <div className='col-span-6 my-2 lg:my-0'>
                                <LsCard />
                            </div>
                            <div className='col-span-6 my-2 lg:my-0'>
                                {localStorage.getItem('role') == 'operator' ? (
                                    <RegisterCard />
                                ) : (
                                    <div className='rounded-xl bg-gray-200 p-2 hidden lg:block h-full'>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='my-2'>
                        <div className='my-5'>
                            <span className='text-default text-xl'>Dokumen Terbaru</span>
                        </div>
                        {localStorage.getItem('role') == 'admin' ? (

                            <div className=''>
                                {docs.data.map((item) => (
                                    <DocumentCardAdmin item={item} position={position} allStatusTrack={allStatusTrack} />
                                ))}
                            </div>
                        ) : (
                            <div className='lg:grid lg:grid-cols-3 justify-center gap-2'>
                                {docs.data.map((item) => (
                                    <div className='my-2 lg:my-0'>
                                        <DocumentCard item={item} position={position} allTrack={allTrack} allStatusTrack={allStatusTrack} setDoc={(value) => changeDocById(item.id, value)} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <></>
            )}
        </div>
    )
}

export default Index

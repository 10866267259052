import React, { useEffect, useState } from 'react'
import { apiWithParams, getApi, postApi } from '../../../connection/Api'
import Select from '../../../container/inputs/Select'
import Input from '../../../container/inputs/Input'
import InputRadio from '../../../container/inputs/InputRadio'
import StaticData from '../../../connection/StaticData'
import ButtonPrimary from '../../../component/buttons/ButtonPrimary'
import { useAllStatusTrack, useAllTrack, usePosition, useValueForm } from '../../../zustand/Index'
import $ from 'jquery'
import DocumentCard from '../../../container/card/DocumentCard'
import { useNavigate } from 'react-router-dom'
import TailwindModal from '../../../container/modal/TailwindModal'

const RegistrationDocument = () => {
    const staticData = new StaticData()
    const [jenisLsId, setJenisLsId] = useState(1)
    const [jenisLs, setJenisLs] = useState()
    const [dataKodeRekening, setDataKodeRekening] = useState(staticData.accountCode)
    const [allJenisLs, setAllJenisLs] = useState()
    const [form, setForm] = useState()
    const [nilai, setNilai] = useState()
    const [pajak, setPajak] = useState()
    const [persenPajak, setPersenPajak] = useState()
    const [nilaiPajak, setNilaiPajak] = useState()
    const [namaPajak, setNamaPajak] = useState()
    const [pph, setPph] = useState()
    const [nilaiPph, setNilaiPph] = useState()
    const [idPajak, setIdPajak] = useState()
    const [jenisKodeRekening, setJenisKodeRekening] = useState()
    const [nilaiBersih, setNilaiBersih] = useState()
    const [loading, setLoading] = useState(true)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [showError, setShowError] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [doc, setDoc] = useState()
    const { position, setPosition } = usePosition()
    const { allTrack, setAllTrack } = useAllTrack()
    const { allStatusTrack, setAllStatusTrack } = useAllStatusTrack()
    const navigate = useNavigate()

    const getData = async () => {
        const resultJenisLs = await getApi('form/jenis-ls')
        setJenisLs(resultJenisLs.data)

        const formByLs = await apiWithParams('form/form-by-ls', jenisLsId)
        setForm(formByLs.data)

        const position = await getApi('form/all-position')
        setPosition(position.data)

        const resultAllTrack = await getApi('document/all-track')
        setAllTrack(resultAllTrack.data)

        const resultAllStatusTrack = await getApi('document/all-status-track')
        setAllStatusTrack(resultAllStatusTrack.data)
        setLoading(false)
    }
    const showww = (company) => {
        document.getElementsByName('nama_perusahaan')[0].value = company.company_name
        document.getElementsByName('nama_pimpinan')[0].value = company.directur_name
        document.getElementsByName('no_rekening')[0].value = company.account_number
        document.getElementsByName('no_npwp')[0].value = company.npwp_number
    }
    const handleInput = async (e) => {
        var Nilai, NilaiPajak, Pph, NilaiPph, JenisKodeRekening, PersenPajak, ShowNilaiPajak, ShowNilaiPph, PersenPph, JenisLs, IdPajak, ElementPajak

        JenisLs = document.getElementsByName('jenis_ls')[0].value

        if (JenisLs === '3') {
            if (e.target.name === 'nilai_hibah') {
                document.getElementsByName('nilai_hibah')[0].value = staticData.intToRupiah(staticData.rupiahToInt(document.getElementsByName('nilai_hibah')[0].value))
            }
            if (e.target.name === "tgl_spp") {
                document.getElementsByName('tgl_spm')[0].value = e.target.value
            }
        } else {
            Nilai = staticData.rupiahToInt(document.getElementsByName('nilai')[0].value)

            if (e.target.name === 'nilai') {
                document.getElementsByName('nilai')[0].value = staticData.intToRupiah(Nilai)
            }
            if (e.target.name === 'nilai_pajak') {
                document.getElementsByName('nilai_pajak')[0].value = staticData.intToRupiah(staticData.rupiahToInt(document.getElementsByName('nilai_pajak')[0].value))
            }
            if (e.target.name === 'nilai_pph') {
                document.getElementsByName('nilai_pph')[0].value = staticData.intToRupiah(staticData.rupiahToInt(document.getElementsByName('nilai_pph')[0].value))
            }

            if (e.target.name === "nama_perusahaan") {
                let div, span
                if (e.target.value != "") {
                    const data = {
                        'company_name': e.target.value
                    }
                    const company = await postApi('company/all', data)
                    let result = company.data
                    if (document.getElementById('wrap-company-name')) {
                        document.getElementById('wrap-company-name').remove()
                    }
                    if (result.length >= 1) {
                        div = document.createElement('div')
                        div.setAttribute('id', 'wrap-company-name')
                        result.map(company => {
                            span = document.createElement('span')
                            span.setAttribute('class', 'block mt-1 text-sm')
                            span.onclick = () => { showww(company) }
                            span.innerHTML = '> ' + company.company_name
                            div.append(span)
                        })
                        e.target.parentNode.parentNode.parentNode.append(div)
                    }
                } else {
                    if (document.getElementById('wrap-company-name')) {
                        document.getElementById('wrap-company-name').remove()
                    }
                }
            }

            if (JenisLs === '1') {
                if (e.target.name === "tgl_spp") {
                    document.getElementsByName('tgl_spm')[0].value = e.target.value
                }
            }

            if (e.target.name === 'nilai' || e.target.name === 'pph') {
                PersenPph = document.getElementsByName('pph')[0].value
                ShowNilaiPph = Nilai * (PersenPph / 100)
                document.getElementsByName('nilai_pph')[0].value = staticData.intToRupiah(ShowNilaiPph.toFixed())
            }

            ElementPajak = document.getElementsByName('pajak')
            for (let i = 0; i < ElementPajak.length; i++) {
                if (ElementPajak[i].checked) {
                    IdPajak = ElementPajak[i].id
                }
            }

            if (e.target.name === 'pajak' || e.target.name === 'nilai') {
                if (IdPajak === "25" || IdPajak === "34") {
                    PersenPajak = 11
                    ShowNilaiPajak = Nilai * (PersenPajak / 100)
                    document.getElementsByName('nilai_pajak')[0].value = staticData.intToRupiah(ShowNilaiPajak.toFixed())

                } else if (IdPajak === "26" || IdPajak === "35") {
                    PersenPajak = 10
                    ShowNilaiPajak = Nilai * (PersenPajak / 100)
                    document.getElementsByName('nilai_pajak')[0].value = staticData.intToRupiah(ShowNilaiPajak.toFixed())
                }
            }

            JenisKodeRekening = document.getElementsByName('jenis_kode_rekening')[0].value
            NilaiPajak = staticData.rupiahToInt(document.getElementsByName('nilai_pajak')[0].value)
            NilaiPph = staticData.rupiahToInt(document.getElementsByName('nilai_pph')[0].value)


            if (isNaN(NilaiPajak)) {
                NilaiPajak = 0
            }
            if (isNaN(NilaiPph)) {
                NilaiPph = 0
            }

            if (IdPajak === "25" || IdPajak === "34") {
                if (Nilai >= 2300000) {
                    Nilai = Nilai - NilaiPajak
                    document.getElementsByName('nilai_pajak')[0].classList.remove('input-bg-secondary')
                } else {
                    document.getElementsByName('nilai_pajak')[0].classList.add('input-bg-secondary')
                }
            } else if (IdPajak === "26" || IdPajak === "35") {
                if (JenisKodeRekening === 'Makan & Minum') {
                    Nilai = Nilai - NilaiPajak
                    document.getElementsByName('nilai_pajak')[0].classList.remove('input-bg-secondary')
                } else {
                    document.getElementsByName('nilai_pajak')[0].classList.add('input-bg-secondary')

                }
            }

            Nilai = Nilai - NilaiPph

            if (Nilai < 0) {
                Nilai = 0
            }
            document.getElementsByName('nilai_bersih')[0].value = staticData.intToRupiah(Nilai)
        }

    }
    const changeLs = async (e) => {
        console.log(e.target.name)
        setJenisLsId(e.target.value)
        const resultForm = await apiWithParams('form/form-by-ls', e.target.value)
        setForm(resultForm.data)

        let valLs = $('select[name="jenis_ls"]').val()
        $('#form-regis-document')[0].reset()
        $('select[name="jenis_ls"]').val(valLs)

        setNilai(0)
        setPajak(0)
        setNamaPajak(0)
        setPph(2)
        setJenisKodeRekening('')
        setNilaiBersih(0)
    }
    const showInput = () => {
        console.log(form)
        return (
            form.map(item => {
                if (item.type === "option") {
                    return (
                        <Select label={item.label} name={item.name} option={item.form_option} optionKeyName={'text'} optionKeyValue={'value'} firstOptionText={item.firstOption === 1 ? `Pilih ${item.label}` : ``} onchange={(e) => {
                            handleInput(e)
                        }} tailwindTheme='gray-400' required={item.required} />
                    )
                } else if (item.type === "text" || item.type === "date") {
                    return (
                        <div>
                            <Input textLabel={item.label} name={item.name} required={item.required} type={item.type} onchange={(e) => handleInput(e)} readonly={item.readonly} tailwindTheme='gray-400' />
                            <div>
                                {item.id == 4 || item.id == 23 ? (
                                    <div className='bg-gray-300 p-3 rounded-xl mt-2'>
                                        <div>
                                            <input type="text" className='rounded-xl border-gray-200 w-full' placeholder='Cari' onChange={(e) => {
                                                let value = e.target.value
                                                // Convert the searchTerm to lowercase for case-insensitive search
                                                const lowerCaseSearchTerm = value.toLowerCase();

                                                // Filter the JSON array based on the search term
                                                let dataJson = staticData.accountCode
                                                const searchResults = dataJson.filter(item => {
                                                    // Check if the searchTerm matches any key in the current object
                                                    return Object.values(item).some(value =>
                                                        value.toString().toLowerCase().includes(lowerCaseSearchTerm)
                                                    );
                                                });

                                                setDataKodeRekening(searchResults)
                                            }} />
                                        </div>
                                        <div className='max-h-60 overflow-y-scroll my-3'>
                                            {dataKodeRekening.map((data, index) => (
                                                <div id={data.kode} className='my-2 mx-2' onClick={() => {
                                                    $('input[name="kode_rekening"]').val(data.kode)
                                                }}>
                                                    <p>{`${index + 1}. ${data.kode} -> ${data.nama} -> ${data.bagian}`}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>

                    )
                } else if (item.type === "radio") {
                    return (
                        <InputRadio textLabel={item.label} name={item.name} required={item.required} type={item.type} options={item.form_option} onclick={(e) => handleInput(e)} readonly={item.readonly} tailwindTheme='gray-400' />
                    )
                } else {
                    return (
                        <div>
                            <span>Field Tidak Ada</span>
                        </div>
                    )
                }
            })
        )
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        let data = Object.fromEntries(formData.entries())
        const result = await postApi('document/registration', data)
        const post = result.data
        if (post.status === 'Error') {
            setShowError(true)
        } else {
            setShowSuccess(true)
            setDoc(post)
        }
        // navigate(`/dashbaord/dokumen/berhasil-mendaftarkan/${1}`)
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <div className="lg:grid lg:grid-cols-12 gap-2">
            {loading === false ? (
                <>
                    <div className='lg:col-span-8 lg:mb-0 mb-2 flex-col-reverse'>
                        <div className='bg-white rounded-xl w-full px-4 py-2'>
                            <div className='flex flex-auto justify-center items-center'>
                                <span className='text-2xl text-dark'>Registrasi Dokumen</span>
                            </div>
                            <form id="form-regis-document" onSubmit={(e) => handleSubmit(e)}>
                                <Select name={'jenis_ls'} option={jenisLs} optionKeyName={'text'} optionKeyValue={'value'} label='Pilih Jenis LS' onchange={(e) => changeLs(e)} tailwindTheme='gray-400' required={true} />
                                {showInput()}
                                <div className='my-2'>
                                    <ButtonPrimary loading={loadingSubmit} text_button={'Simpan'} textStyle={'text-white'} style={'bg-default'} />
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='sticky top-2 lg:col-span-4 lg:mb-0 mb-2'>
                        {doc != null &&
                            <div className='sticky top-2'>
                                <DocumentCard item={doc} allStatusTrack={allStatusTrack} allTrack={allTrack} position={position} setDoc={(value) => setDoc(value)} showValidasiButton={false} />
                            </div>
                        }
                    </div>
                </>
            ) : (
                <></>
            )}
            {showError === true &&
                <TailwindModal confirmButtonText={"Ok"} showCancelButton={false} showConfirmButton={true} title={`Ada masalah pada server`} text={'Error'} confirmButtonAction={() => setShowError(false)} type="danger" />
            }
            {showSuccess === true &&
                <TailwindModal confirmButtonText={"Ok"} showCancelButton={false} showConfirmButton={true} title={`Berhasil`} text={'Pendaftaran berkas berhasil'} confirmButtonAction={() => setShowSuccess(false)} type="success" />
            }
        </div>
    )
}

export default RegistrationDocument

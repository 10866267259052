import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const Operator = () => {
    const role = localStorage.getItem('role')
    // return islogin ? <Outlet/> : <Navigate to="/login"/>
    if (role === "operator") {
        return <Outlet/>
    } else {
        return <Navigate to="*"/>
    }
}

export default Operator

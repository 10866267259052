import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getApi } from '../../../connection/Api'
import StaticData from '../../../connection/StaticData'
import PinManagement from '../../../container/mix/PinManagement'

const AccountDetail = () => {
    const { id } = useParams()
    const [user, setUser] = useState()
    const [loading, setLoading] = useState(true)
    const staticData = new StaticData()
    const navigate = useNavigate()
    const getData = async () => {
        const userResult = await getApi(`user/get-by-id/${id}`)
        setUser(userResult.data)
        setLoading(false)
    }
    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='lg:grid lg:grid-cols-12 gap-2'>
            {loading === false ? (
                <>
                    <div className='lg:col-span-4'>
                        <div className='mb-2'>
                            <div className='flex flex-col justify-center items-center bg-default rounded-xl py-5'>
                                <div className='w-28 h-28 relative'>
                                    <img src={`${staticData.url}avatar/${user.avatar ?? ""}`} className='w-full h-full rounded-full border-2 border-white' alt="" />
                                    <div className='absolute bottom-0 end-0 rounded-full bg-white p-1 w-7 h-7 justify-center items-center flex'>
                                        <i className='fa-solid fa-image text-default'></i>
                                    </div>
                                </div>
                                <div className='flex flex-col justify-center items-center my-3'>
                                    <span className='text-white'>{user.role.name}</span>
                                    <span className='text-white text-xl uppercase'>{user.name}</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='rounded-xl bg-white p-3'>
                                <div className='mb-3'>
                                    <span className='block font-bold'>Bagian</span>
                                    {
                                        user.role.name == "admin" ? (
                                            <span>Admin</span>
                                        ) : (
                                            <div>
                                                {user.positions.map(item => (
                                                    <span className='block'>{item.position_name}</span>
                                                ))}
                                            </div>
                                        )
                                    }
                                </div>
                                <div className='mb-3'>
                                    <span className='block font-bold'>Email</span>
                                    <span className=''>{user.email}</span>
                                </div>
                                <PinManagement user={user} setUser={setUser}/>
                            </div>
                        </div>
                        <div className='mt-2'>
                            <button onClick={() => navigate(`/dashboard/edit-profile/${localStorage.getItem('id')}`)} className='bg-default p-3 rounded-lg text-white w-full'>
                                Edit Profil <i className='fa-solid fa-pen-to-square ml-1'></i>
                            </button>
                        </div>
                    </div>
                    <div className='lg:col-span-8'>

                    </div>
                </>
            ) : (
                <></>
            )}
        </div>
    )
}

export default AccountDetail

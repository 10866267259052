import React from 'react'
const ButtonFunction = ({onclick, type, text, icon = "", buttonStyle = "bg-default", textStyle = "text-white"}) => {
    return (
        <button className={`w-full rounded-lg p-2 ${buttonStyle}`} onClick={onclick} type={type}>
            <div className=''>
                {
                    icon != "" &&
                    <i className={`${icon} ${textStyle}`}></i>
                }
                <span className={`${textStyle} mx-2`}>{text}</span>
            </div>
        </button>
    )
}
export default ButtonFunction
import React, { useEffect, useState } from 'react'
import { getApi } from '../../connection/Api'
import StaticData from '../../connection/StaticData'

const UserCard = ({ customTailwind }) => {

    const [showPin, setShowPin] = useState(false)
    const [user, setUser] = useState()
    const [loading, setLoading] = useState(true)
    const staticData = new StaticData()
    const getData = async () => {
        const result = await getApi(`user/get-by-id/${localStorage.getItem('id')}`)
        setUser(result.data)
        setLoading(false)
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <div className='h-auto lg:h-full bg-default rounded-lg'>
            {loading == false ? (
                <div className={`grid grid-cols-12 px-2 ${customTailwind}`}>
                    <div className='col-span-2 justify-center items-center flex'>
                        <div className=''>
                            <img src={`${staticData.url}avatar/${user.avatar}`} alt="" className='h-12 w-12 rounded-full border-2 border-white' />
                        </div>
                    </div>
                    <div className='col-span-10'>
                        <div className=''>
                            <span className='text-white font-bold'>{user.name}</span>
                        </div>
                        <div className='py-0'>
                            <div className='grid grid-cols-12'>
                                <div className='col-span-7'>
                                    <span className='text-white'>{user.role.name}</span>
                                </div>
                                <div className='col-span-5'>
                                    <button className='bg-secondary px-2 rounded-lg w-full' onClick={() => setShowPin(showPin === false ? true : false)}>
                                        {showPin === true ? (
                                            <div className='iniline h-full'>
                                                <span className='text-blue'>{user.username} <i className='fa-solid fa-eye-slash'></i></span>
                                            </div>
                                        ) : (
                                            <>
                                                <div className='iniline h-full'>
                                                    <span className='text-blue'>Lihat PIN <i className='fa-solid fa-eye'></i></span>
                                                </div>
                                            </>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

export default UserCard

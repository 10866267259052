import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getApi, postApi } from "../../connection/Api";
import StaticData from "../../connection/StaticData";
import ButtonFunction from "../../component/buttons/ButtonFunction";

const DocumentCardGuest = ({
  item,
  position = {},
  allTrack = {},
  allStatusTrack = {},
  setDoc = {},
}) => {
  const [showDetail, setShowDetail] = useState(false);
  const [showModalReset, setShowModalReset] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);
  const [idReset, setIdReset] = useState();
  const [loadingTrack, setLoadingTrack] = useState(false);
  const staticData = new StaticData();
  const navigate = useNavigate();
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#ffffff",
  };
  const formatDate = (dateTime) => {
    // Membuat objek Date dari string
    const waktu = new Date(dateTime);

    // Mendapatkan tanggal dengan format dua digit
    const tanggal = waktu.toLocaleDateString("id-ID", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    return tanggal;
  };
  const getDocumentByIdForm = (data, id, keyFilter) => {
    const result = data.filter((element) => element[keyFilter] === id);
    return result[0];
  };
  const filterPosition = (id) => {
    let result = position.filter((element) => element.id === parseInt(id));
    return result[0];
  };
  const saveTrack = async (e) => {
    setLoadingTrack(true);
    e.preventDefault();
    const formData = new FormData(e.target);
    let data = Object.fromEntries(formData.entries());
    let dataTrack = {
      document_number_id: item.id,
      track_id: data.track_id,
      status_track: data.status_track,
    };
    let postTrack = await postApi("document/manage-track", dataTrack);
    let doc = await getApi(`document-number/get-by-id?id=${item.id}`);
    setDoc(doc.data);
    setLoadingTrack(false);
  };
  const resetTrack = async (id) => {
    setLoadingReset(true);
    let result = await getApi(`document-number/reset-track?id=${id}`);
    console.log(result);
    setDoc(result.data);
    setLoadingReset(false);
    setShowModalReset(false);
  };
  return (
    <div className="p-4 bg-white rounded-xl w-auto h-min">
      <div className="grid grid-cols-2">
        <div>
          <span className="text-dark">{formatDate(item.updated_at)}</span>
        </div>
        <div className="flex flex-row justify-end items-end">
          <span className="text-dark font-bold">#{item.number}</span>
        </div>
      </div>
      <div>
        <div>
          <div
            className={`
                        font-bold
                        text-dark
                        ${showDetail == false
                ? `teks-container 
                            max-w-full 
                            overflow-hidden 
                            whitespace-nowrap 
                            overflow-ellipsis`
                : ``
              }
                    `}
          >
            {item.jenis_ls_id === staticData.spmId &&
              getDocumentByIdForm(item.document, 2, "form_document_by_ls_id")
                .data_value}
            {item.jenis_ls_id === staticData.spbId &&
              getDocumentByIdForm(item.document, 21, "form_document_by_ls_id")
                .data_value}
            {item.jenis_ls_id === staticData.hibahId &&
              getDocumentByIdForm(item.document, 35, "form_document_by_ls_id")
                .data_value}
          </div>
        </div>
        <div>
          {item.jenis_ls_id === staticData.spmId && (
            <span className="text-dark">
              {
                filterPosition(
                  getDocumentByIdForm(
                    item.document,
                    1,
                    "form_document_by_ls_id"
                  ).data_value
                ).position_name
              }
            </span>
          )}
          {item.jenis_ls_id === staticData.spbId && (
            <span className="text-dark">
              {
                filterPosition(
                  getDocumentByIdForm(
                    item.document,
                    20,
                    "form_document_by_ls_id"
                  ).data_value
                ).position_name
              }
            </span>
          )}
        </div>
        <div className="grid grid-cols-12 my-3">
          <div className="col-span-10 flex flex-auto">
            <span
              className={`
                            px-2 
                            rounded-xl
                            ${item.document_track[
                  item.document_track.length - 1
                ].status_track_id == allStatusTrack[0].id &&
                `   
                                    bg-emerald-400
                                    border-2
                                    text-green-900
                                    border-green-900
                                `
                }
                            ${item.document_track[
                  item.document_track.length - 1
                ].status_track_id == allStatusTrack[1].id &&
                `   
                                    bg-gray-400
                                    border-2
                                    text-dark
                                    border-gray-600
                                `
                }
                            ${item.document_track[
                  item.document_track.length - 1
                ].status_track_id == allStatusTrack[2].id &&
                `   
                                    bg-red-500
                                    border-2
                                    text-red-1000
                                    border-red-900
                                `
                }
                    `}
            >
              {
                item.document_track[item.document_track.length - 1].track
                  .track_name
              }
            </span>
          </div>
          <div className="col-span-2 flex flex-auto justify-end items-center">
            <button
              onClick={() => setShowDetail(showDetail == false ? true : false)}
            >
              <i
                className={`fa-solid ${showDetail == false ? "fa-chevron-down" : "fa-chevron-up"
                  }`}
              ></i>
            </button>
          </div>
        </div>
        {showDetail == true && (
          <div className="mt-2">
            <div className="border-t-2 border-t-secondary border-dashed my-2"></div>
            <div>
              {allTrack.map((data, key) => (
                <>
                  <div className="flex flex-row">
                    <div className="flex flex-col justify-center items-center w-7">
                      {item.document_track[key] ? (
                        <div>
                          {item.document_track[key].status_track_id ==
                            allStatusTrack[0].id && (
                              <div className="rounded-full bg-green-500 w-5 h-5 flex m-1 justify-center items-center">
                                <i className="fa-solid fa-check text-white"></i>
                              </div>
                            )}
                          {item.document_track[key].status_track_id ==
                            allStatusTrack[1].id && (
                              <div className="rounded-full bg-gray-500 w-5 h-5 flex m-1 justify-center items-center">
                                <i className="fa-solid fa-clock text-white"></i>
                              </div>
                            )}
                          {item.document_track[key].status_track_id ==
                            allStatusTrack[2].id && (
                              <div className="rounded-full bg-red-500 w-5 h-5 flex m-1 justify-center items-center">
                                <i className="fa-solid fa-xmark text-white"></i>
                              </div>
                            )}
                        </div>
                      ) : (
                        <div className="rounded-full h-5 w-5 bg-gray-300 mx-1"></div>
                      )}
                    </div>
                    <div className="flex flex-auto">
                      <span>{data.track_name}</span>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="h-7 w-7 flex justify-center items-center">
                      {allTrack.length > key + 1 && (
                        <div className="border-l-2 border-l-secondary border-dashed h-full w-1"></div>
                      )}
                    </div>
                    <div className="flex flex-auto">
                      <div className="flex xl:flex-row w-full flex-col-reverse">
                        {item.document_track[key] && (
                          <>
                            <div className="flex flex-1">
                              {item.document_track[key].user && (
                                <span>
                                  {item.document_track[key].user.name}
                                </span>
                              )}
                            </div>
                            <div className="text-right">
                              <span className="text-sm">
                                {staticData.formatDate(
                                  item.document_track[key].updated_at
                                )}
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
            <div className="border-t-2 border-t-secondary border-dashed my-2"></div>
            <div className="my-2">
              <span className="block text-dark">Jenis LS</span>
              <span className="text-dark font-bold">{item.jenis_ls.text}</span>
            </div>
            {/* <div className="my-2">
              <span className="block text-dark">Nama Perusahaan</span>
              <span className="text-dark font-bold">
                {item.jenis_ls_id === staticData.spmId &&
                  getDocumentByIdForm(
                    item.document,
                    12,
                    "form_document_by_ls_id"
                  ).data_value}
                {item.jenis_ls_id === staticData.spbId &&
                  getDocumentByIdForm(
                    item.document,
                    31,
                    "form_document_by_ls_id"
                  ).data_value}
              </span>
            </div> */}
            <div className="my-2 w-full">
              <ButtonFunction onclick={() => navigate(`/dokumen-info/${item.number}`)} type={'button'} text={'Detail'} icon="fa-solid fa-circle-info"/>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentCardGuest;

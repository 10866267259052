import React from 'react'

const CardTitleDetailDocument = ({number, statusTrackId, statusTrackName, allStatusTrack}) => {
    return (
        <div className='flex flex-row'>
            <div className=''>
                <span className='text-default block'>Tracking</span>
                <span className='text-dark text-2xl'>{number}</span>
            </div>
            <div className='flex flex-auto justify-end items-center h-auto'>
                <div className={`
                    px-2
                    rounded-full
                    ${statusTrackId === allStatusTrack[0].id && 
                        'bg-green-600'
                    }
                    ${statusTrackId === allStatusTrack[1].id && 
                        'bg-gray-400'
                    }
                    ${statusTrackId === allStatusTrack[2].id && 
                        'bg-red-500'
                    }
                `}>
                    <span className='text-white'>{statusTrackName}</span>
                </div>
            </div>
        </div>
    )
}

export default CardTitleDetailDocument

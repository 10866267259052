export default function InputRadio({ textLabel, name, required, readonly, type, options, onclick = () => { }, id = null, value = null, tailwindTheme="default", customTailwind=""}) {
    return (
        <div className={customTailwind}>
            <label className={`text-${tailwindTheme}`} htmlFor="nomor-berkas">{textLabel}</label>
            <div className="flex flex-row gap-2">
                {options.map((option) => {
                    return (
                        <div className="w-6/12">
                            <label className={`border-2 border-${tailwindTheme} rounded-lg flex flex-auto items-center p-2`} htmlFor={option.id}>
                                <input className="radio" id={option.id} type={type} name={name} value={option.value} onClick={onclick} required={ required } defaultChecked={option.value === value && true}  readOnly={true}/>
                                <label htmlFor={option.id} className={`ml-2`}>{ option.text }</label>
                            </label>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
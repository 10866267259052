import React from 'react'

const CardDetailDokumen = ({jenisLS, namaKegiatan, namaBagian, namaPerusahaan, tanggal}) => {
    const data = [
        {
            text: "Jenis Ls",
            value: jenisLS
        },
        {
            text: "Nama Kegiatan",
            value: namaKegiatan
        },
        {
            text: "Nama Bagian",
            value: namaBagian
        },
        {
            text: "Nama Perusahaan",
            value: namaPerusahaan
        },
        {
            text: "Tanggal",
            value: tanggal
        },
    ]
    return (
        <div className='w-full rounded-xl bg-white p-3'>
            <div>
                <span className='text-default text-lg'>Detail Dokumen</span>
            </div>
            {data.map(item => (
                <div className='my-1'>
                    <span className='block text-dark'>{item.text}</span>
                    <span className='text-dark font-bold'>{item.value}</span>
                </div>
            ))}
        </div>
    )
}

export default CardDetailDokumen

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getApi } from '../../../connection/Api'
import DocumentCard from '../../../container/card/DocumentCard'
import DocumentCardAdmin from '../../../container/card/DocumentCardAdmin'
import LsCard from '../../../container/card/LsCard'

const FindDocument = () => {
    const { number } = useParams()
    const [document, setDocument] = useState()
    const [position, setPosition] = useState()
    const [allTrack, setAllTrack] = useState()
    const [allStatusTrack, setAllStatusTrack] = useState()
    const [loading, setLoading] = useState(true)

    const getData = async () => {
        const resultDocument = await getApi(`document/get-by-number/${number}`)
        setDocument(resultDocument.data)

        const resultPosition = await getApi('form/all-position')
        setPosition(resultPosition.data)

        let resultAllTrack = await getApi('document/all-track')
        setAllTrack(resultAllTrack.data)

        const resultAllStatusTrack = await getApi('document/all-status-track')
        setAllStatusTrack(resultAllStatusTrack.data)
        setLoading(false)
    }
    const deleteDocument = async (e, number) => {
        setDocument(null)
    }
    useEffect(() => {
        getData()
    }, [])

    return (
        <div>
            {loading === false ? (
                <div className='my-5 h-full'>
                    <div className='my-2 lg:my-0 col-span-4'>
                        <LsCard />
                    </div>
                    {
                        document ? (
                            <div className='mt-3'>
                                {
                                    localStorage.getItem('role') == "admin" ? (
                                        <DocumentCardAdmin item={document} position={position} allStatusTrack={allStatusTrack} deleteDocumentFromParent={deleteDocument} />
                                    ) : (
                                        <DocumentCard item={document} position={position} allTrack={allTrack} allStatusTrack={allStatusTrack} setDoc={(value) => setDocument(value)} />
                                    )
                                }
                            </div>
                        ) : (
                            <div className='mt-3'>
                                <div class="p-4 text-yellow-900 bg-yellow-100 border border-yellow-200 rounded-md">
                                    <div class="flex justify-between flex-wrap">
                                        <div class="w-0 flex-1 flex">
                                            <div class="mr-3 pt-1">
                                                <svg width="26" height="26" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                                                    <path d="M13.6086 3.247l8.1916 15.8c.0999.2.1998.5.1998.8 0 1-.7992 1.8-1.7982 1.8H3.7188c-.2997 0-.4995-.1-.7992-.2-.7992-.5-1.1988-1.5-.6993-2.4 5.3067-10.1184 8.0706-15.385 8.2915-15.8.3314-.6222.8681-.8886 1.4817-.897.6135-.008 1.273.2807 1.6151.897zM12 18.95c.718 0 1.3-.582 1.3-1.3 0-.718-.582-1.3-1.3-1.3-.718 0-1.3.582-1.3 1.3 0 .718.582 1.3 1.3 1.3zm-.8895-10.203v5.4c0 .5.4.9.9.9s.9-.4.9-.9v-5.3c0-.5-.4-.9-.9-.9s-.9.4-.9.8z"></path>
                                                </svg>
                                            </div>
                                            <div>
                                                <h4 class="text-xl leading-6 font-medium">
                                                    Terjadi Kesalahan
                                                </h4>
                                                <p class="text-md">
                                                    Data yang anda cari tidak ditemukan
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

export default FindDocument

import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { apiWithParams, getApi, postApiWithFile } from '../../../connection/Api'
import StaticData from '../../../connection/StaticData'
import TailwindSpin from '../../../component/loading/TailwindSpin'
import Select from '../../../container/inputs/Select'

const UpdateProfile = () => {
    const { id } = useParams()
    const [position, setPosition] = useState([])
    const [user, setUser] = useState([])
    const [ready, setReady] = useState(false)
    const [profileImage, setProfileImage] = useState()
    const [formUser, setFormUser] = useState()
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const navigate = useNavigate()
    const fileInputRef = useRef(null);
    const staticData = new StaticData()

    const getData = async () => {
        const getUser = await apiWithParams('user/get-by-id', id)
        setUser(getUser.data)
        setProfileImage(`${staticData.url}avatar/${getUser.data.avatar}`)

        let mapPosition = []
        let position = await getApi('form/all-position')
        await position.data.map(element => {
            mapPosition.push({
                id: element.id,
                name: element.position_name
            })

        })
        setPosition(mapPosition)

        setFormUser([
            {
                label: 'Nama',
                name: 'name',
                type: 'text',
                id: 'name',
                value: getUser.data.name,
            },
            {
                label: 'Email',
                name: 'email',
                type: 'email',
                id: 'email',
                value: getUser.data.email
            },
        ])
        setReady(true)
    }

    const submitFormUser = async (e) => {
        e.preventDefault()
        setLoadingSubmit(true)
        var form = new FormData(e.target)

        let inputFile = formUser.filter((item) => item.type === 'file')

        inputFile.map((item) => {
            var files = document.getElementById(item.id).files
            if (files) {
                form.append(item.name, files[0])
            }
        })

        form.append('id', id)

        let resultPost = await postApiWithFile(`user/update-profile/${id}`, form)

        console.log(resultPost)

        let textErr = document.getElementsByClassName('text-err')
        for (let i = 0; i < textErr.length; i++) {
            textErr[i].textContent = ""
        }

        if (resultPost.status === 'Error') {
            document.querySelector(`div[name=${resultPost.field}]`).textContent = resultPost.message
        }

        if (resultPost.status === "Success") {
            localStorage.setItem('avatar', resultPost.data.avatar)
            localStorage.setItem('name', resultPost.data.name)
            localStorage.setItem('email', resultPost.data.email)
            window.location.href = `/dashboard/profile/${id}`
            setLoadingSubmit(false)
        }
    }
    useEffect(() => {
        getData()
    }, [])


    if (ready === false) {
        return (
            <></>
        )
    } else {
        return (
            <div className='lg:grid lg:grid-cols-12'>
                <div className='lg:col-span-8'>
                    <form onSubmit={(e) => { submitFormUser(e) }} encType="multipart/form-data" method='POST'>
                        <div className='my-5'>
                            <div className='flex flex-auto justify-center items-center my-5'>
                                <div className='w-40 h-40 relative '>
                                    <img src={profileImage} alt="" className='w-full h-full rounded-full border-2 border-default' onClick={() => fileInputRef.current.click()} />
                                    <input name="avatar" id="avatar" ref={fileInputRef} type="file" className='hidden' accept='.png, .jpg, .jpeg' onChange={(e) => setProfileImage(URL.createObjectURL(e.target.files[0]))} />
                                    <div className='absolute bottom-4 end-2 rounded-full bg-default flex flex-auto justify-center items-center'>
                                        <i className='fa-solid fa-image text-white m-2'></i>
                                    </div>
                                    <div className='text-red-500' name="avatar"></div>
                                </div>
                            </div>
                        </div>
                        <div className='p-4 rounded-xl bg-white flex flex-col gap-4'>
                            {formUser.map(element => {
                                if (element.type === 'option') {
                                    return (
                                        <div className='mb-3'>
                                            <div className='mb-1'>
                                                <label htmlFor={element.id} className="form-label">{element.label}</label>
                                            </div>
                                            <div className='flex flex-auto'>
                                                <Select name={element.name} option={element.data} optionKeyName={'name'} optionKeyValue={'id'} selectedValue={element.value} firstOptionText={`-- Pilih ${element.lebel} --`} customTailwind='w-full' tailwindTheme='dark' selectCustomClass={'border-0'} />
                                            </div>
                                            <div className='text-danger text-err' name={element.name}></div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="flex flex-col">
                                            <div className='mb-1'>
                                                <label htmlFor={element.id} className="form-label">{element.label}</label>
                                            </div>
                                            <div className='flex flex-auto'>
                                                <input defaultValue={element.value} type={element.type} name={element.name} className="w-full rounded-md border-2 border-dark" id={element.id} accept={element.accept && element.accept} />
                                                <div className='text-danger text-err' name={element.name}></div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                            <div className='flex flex-auto gap-2'>
                                <button id="btn-submit-user" type="submit" className="bg-default px-5 py-2 rounded-xl text-white">
                                    {loadingSubmit === true ? (<TailwindSpin/>) : "Simpan"}
                                </button>
                                <Link className='bg-gray-200 px-5 py-2 rounded-xl text-dark' to={`/dashboard/profile/${id}`}>Cancel</Link>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='lg:col-span-4'>

                </div>
            </div>
        )
    }

}

export default UpdateProfile


import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from '../pages/Index';
import Operator from '../middleware/Operator';
import PageNotFound from '../pages/PageNotFound';
import IndexDasboard from '../pages/dashboard/Index';
import Template from '../pages/dashboard/Template';
import DocumentDetail from '../pages/dashboard/document/DocumentDetail';
import DocumentList from '../pages/dashboard/document/DocumentList';
import DocumentValidation from '../pages/dashboard/document/DocumentValidation';
import EditDocument from '../pages/dashboard/document/EditDocument';
import RegistrationDocument from '../pages/dashboard/document/RegistrationDocument';
import AccountDetail from '../pages/dashboard/profile/AccountDetail';
import AddUser from '../pages/dashboard/user/AddUser';
import EditUser from '../pages/dashboard/user/EditUser';
import UserList from '../pages/dashboard/user/UserList';
import PageDocumentTrack from '../pages/guest/PageDocumentTrack';
import LoggedIn from '../middleware/LoggedIn';
import UpdateProfile from '../pages/dashboard/profile/UpdateProfile';
import FindDocument from '../pages/dashboard/document/FindDocument';
import ListDocumentByAccountNumber from '../pages/guest/ListDocumentByAccountNumber';

class Router extends Component {
    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route element={<LoggedIn />}>
                        <Route path="/dashboard" element={<Template />}>
                            <Route index element={<IndexDasboard />} />
                            <Route path="user" >
                                <Route index element={<UserList />} />
                                <Route path="tambah" element={<AddUser />} />
                                <Route path="edit/:id" element={<EditUser />} />
                            </Route>
                            <Route path="dokumen" >
                                <Route path="daftar-dokumen/:lsId" element={<DocumentList />} />
                                <Route path="edit/:id" element={<EditDocument />} />
                                <Route path="validasi/:id" element={<DocumentValidation />} />
                                <Route element={<Operator />}>
                                    <Route path="registrasi" element={<RegistrationDocument />} />
                                    <Route path="detail/:number" element={<DocumentDetail />} />
                                </Route>
                                <Route path="cari/:number" element={<FindDocument/>}/>
                            </Route>
                            <Route path="profile/:id" element={<AccountDetail />} />
                            <Route path="edit-profile/:id" element={<UpdateProfile />} />
                        </Route>
                    </Route>
                    <Route path="/dokumen-nomor-rekening/:accountNumber" element={<ListDocumentByAccountNumber/>}/> 
                    <Route path="/dokumen-info/:number" element={<PageDocumentTrack />} />
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </BrowserRouter>
        )
    }
}
export default Router


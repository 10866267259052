import { create } from 'zustand'

export const useDocs = create((set, get) => ({
    docs: {},
    setDocs: (value) => set(() => ({ docs: value })),
    changeDocById: (id, newData) => set((state) => {
        const updatedData = state.docs.data.map((item) => {
            if (item.id === id) {
                return { ...item, ...newData };
            }
            return item;
        });

        return {
            docs: {
                ...state.docs,
                data: updatedData,
            },
        };
    }),
    removeDocByKeyValue: (key, value) => {
        set((state) => ({
            docs: {
                ...state.docs,
                data: state.docs.data.filter(item => item[key] !== value),
            },
        }));
    },
    getDocByNumber: (number) => {
        const state = get();
        if (!state.docs.data) return [];  // Tambahkan pemeriksaan ini
        return state.docs.data.filter(item => item.number === number);
    }
}))
export const useUsers = create((set) => ({
    users: {},
    setUsers: (value) => set(() => ({ users: value })),
    changeUserById: (id, newData) => set((state) => {
        const updatedData = state.users.data.map((item) => {
            if (item.id === id) {
                return { ...item, ...newData };
            }
            return item;
        });

        return {
            users: {
                ...state.users,
                data: updatedData,
            },
        };
    }),
    removeUserByKeyValue: (key, value) => {
        set((state) => ({
            users: {
                ...state.users,
                data: state.users.data.filter(item => item[key] !== value),
            },
        }));
    },
}))

export const usePosition = create((set) => ({
    position: {},
    setPosition: (value) => set(() => ({ position: value }))
}))

export const useShowPin = create((set) => ({
    showPin: false,
    setShowPin: (value) => set(() => ({ showPin: value }))
}))

export const useAllTrack = create((set) => ({
    allTrack: {},
    setAllTrack: (value) => set(() => ({ allTrack: value }))
}))
export const useShowSidebar = create((set) => ({
    showSidebar: {},
    setShowSidebar: (value) => set(() => ({ showSidebar: value }))
}))

export const useAllStatusTrack = create((set) => ({
    allStatusTrack: {},
    setAllStatusTrack: (value) => set(() => ({ allStatusTrack: value })),
}))

export const useValueForm = create((set) => ({
    valueForm: {},
    setValueForm:(value) => set(() => ({valueForm: value})),
    updateValueForm: (key, value) => set((state) => ({
        valueForm: {
            ...state.valueForm,
            [key]: value,
        }
    })),
}))

export const useAccount = create((set) => ({
    account: {},
    setAccount: (value) => set(() => ({account: value})),
    updateAccount: (key, value) => set((state) => ({
        account: {
            ...state.account,
            [key]: value,
        }
    })),
}))
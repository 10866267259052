import React from 'react'

const CardCount = ({number, text}) => {
    return (
        <div className='flex flex-col h-40 p-2 justify-center items-center rounded-3xl bg-secondary'>
            <span className='text-default text-3xl block font-bold'>{number.toLocaleString('id-ID')}</span>
            <span className='text-default font-bold'>{text}</span>
        </div>
    )
}

export default CardCount

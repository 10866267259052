import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { postApi } from '../../connection/Api'
import StaticData from '../../connection/StaticData'
import { useUsers } from '../../zustand/Index'
import TailwindModal from '../modal/TailwindModal'

const UserListCard = ({user}) => {
    const [showDetail, setShowDetail] = useState(false)
    const [showPin, setShowPin] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const {removeUserByKeyValue} = useUsers()
    const navigate = useNavigate()
    const staticData = new StaticData()
    const destroy = async () => {
        setLoadingDelete(true)
        let data = {
            id: user.id
        }
        const result = await postApi('user/delete', data)
        removeUserByKeyValue("id", user.id)
        setShowModalDelete(false)
        setLoadingDelete(false)
    }
    return (
        <div className='bg-white rounded-xl p-3'>
            <div className='lg:grid lg:grid-cols-12'>
                <div className='lg:col-span-7'>
                    <div className='flex flex-row'>
                        <div className='flex flex-col justify-center items-center'>
                            <img src={`${staticData.url}/avatar/${user.avatar}`} alt="" className='h-10 w-10 rounded-full' />
                        </div>
                        <div className='flex flex-auto px-2'>
                            <div className='flex flex-col lg:flex-row w-full'>
                                <div className='flex flex-col w-full'>
                                    <div>
                                        <span className='text-xs font-bold'>{user.role.name}</span>
                                    </div>
                                    <div>
                                        <span>{user.name}</span>
                                    </div>
                                </div>
                                <div className='flex flex-row w-full lg:w-auto lg:justify-center lg:items-center'>
                                    <div className='flex flex-auto'>
                                        <button className='px-2 rounded-lg bg-purple-200 w-36' onClick={() => setShowPin(showPin === false ? true : false)}>
                                            <span className='px-2'>{showPin === false ? 'Lihat PIN' : user.username}</span>
                                            <i className={`fa-solid fa-eye${showPin === false ? '' : '-slash'}`}></i>
                                        </button>
                                    </div>
                                    <div className='lg:hidden justify-end items-end' onClick={() => setShowDetail(showDetail === false ? true : false)}>
                                        <i className={`fa-solid fa-chevron-${showDetail === true ? 'up' : 'down'}`}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`lg:col-span-5 ${showDetail === false ? 'hidden' : 'inline'} lg:inline`}>
                    <div className='border-2 border-gray-400 border-dashed mb-3 mt-3 lg:hidden'>

                    </div>
                    <div className='flex lg:flex-row flex-col'>
                        <div className='flex flex-auto px-2'>
                            <div className='flex flex-col w-full'>
                                <div>
                                    <span className='text-xs font-bold'>Bagian</span>
                                </div>
                                <div>
                                    {user.positions.map(item => (
                                        <span className='block'>{item.position_name}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='lg:mt-0 mt-3 flex flex-row lg:w-40 justify-center items-center px-2 gap-2'>
                            <button onClick={() => navigate(`/dashboard/user/edit/${user.id}`)} className='border-2 border-default rounded-lg bg-third px-2 text-default flex flex-auto w-full justify-center items-center'>
                                <span className='font-bold'>Edit</span> 
                            </button>
                            <button onClick={() => setShowModalDelete(showModalDelete === true ? false : true)} className='border-2 border-red-500 rounded-lg bg-red-200 text-red-500 px-2'> <i className='fa-solid fa-trash'></i></button>
                        </div>
                    </div>
                </div>
            </div>
            {showModalDelete === true &&
                <TailwindModal title={"Konfirmasi Hapus"} text={"Apakah anda yakin ?"} cancelButtonText={"Batal"} confirmButtonText={"Hapus"} showCancelButton={true} showConfirmButton={true} canceButtonAction={() => setShowModalDelete(false)} confirmButtonAction={() => destroy()} loadingConfirm={loadingDelete}/>
            }
        </div>
    )
}

export default UserListCard

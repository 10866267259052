const IsLogin = () => {
    
    const pin = localStorage.getItem("pin")
    const token = localStorage.getItem("token")
    
    if (pin !== null && token !== null) {
        return true
    } else {
        return false
    }
}
export default IsLogin
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { postApi } from '../../connection/Api'
import StaticData from '../../connection/StaticData'
import { useDocs } from '../../zustand/Index'
import TailwindModal from '../modal/TailwindModal'

const DocumentCardAdmin = ({ item, position = {}, allStatusTrack = {}, deleteDocumentFromParent }) => {
    const staticData = new StaticData()
    const [showButton, setShowButton] = useState(false)
    const [showDetail, setShowDetail] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const { removeDocByKeyValue } = useDocs()
    const navigation = useNavigate()

    const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "#ffffff",
    };

    const getDocumentByIdForm = (data, id, keyFilter) => {
        const result = data.filter(item => item[keyFilter] === id);
        return result[0]
    }
    const filterPosition = (id) => {
        let result = position.filter(item => item.id === parseInt(id))
        return result[0]
    }
    const deleteDocument = async (e, number) => {
        setLoadingDelete(true)
        let data = {
            document_number : number
        }
        await postApi('document/delete', data)

        if (deleteDocumentFromParent) {
            await deleteDocumentFromParent(e, number);
        }
        removeDocByKeyValue("number", number)
        setShowModalDelete(false)
        setShowButton(false)
        setLoadingDelete(false)
    }
    return (
        <div className='bg-white rounded-xl p-3 lg:grid lg:grid-cols-12 my-4'>
            <div className={`${showButton == false ? `col-span-3` : `col-span-2`}`}>
                <div className='flex flex-row'>
                    <div className='flex flex-auto'>
                        <span>{staticData.formatDate(item.updated_at)}</span>
                    </div>
                    <div>
                        <span className='font-bold'>{`#${item.number}`}</span>
                    </div>
                </div>
                <div>
                    <div className={`
                        font-bold
                        text-dark
                        ${showDetail == false ? `
                            teks-container 
                            max-w-full 
                            overflow-hidden 
                            whitespace-nowrap 
                            overflow-ellipsis
                        ` : ``}
                    `}>
                        {
                            item.jenis_ls_id === staticData.spmId &&
                            getDocumentByIdForm(item.document, 2, "form_document_by_ls_id").data_value
                        }
                        {
                            item.jenis_ls_id === staticData.spbId &&
                            getDocumentByIdForm(item.document, 21, "form_document_by_ls_id").data_value
                        }
                        {
                            item.jenis_ls_id === staticData.hibahId &&
                            getDocumentByIdForm(item.document, 35, "form_document_by_ls_id").data_value
                        }
                    </div>
                </div>
            </div>
            <div className='col-span-2 flex flex-row lg:flex-1 justify-center items-center my-2 lg:my-0'>
                <div className='lg:flex lg:flex-1 w-full lg:w-min lg:justify-center lg:items-center'>
                    <span className={`
                            px-2 
                            rounded-xl
                            ${item.document_track[item.document_track.length - 1].status_track_id == allStatusTrack[0].id &&
                        `   
                                    bg-emerald-400
                                    border-2
                                    text-green-900
                                    border-green-900
                                `
                        }
                            ${item.document_track[item.document_track.length - 1].status_track_id == allStatusTrack[1].id &&
                        `   
                                    bg-gray-400
                                    border-2
                                    text-dark
                                    border-gray-600
                                `
                        }
                            ${item.document_track[item.document_track.length - 1].status_track_id == allStatusTrack[2].id &&
                        `   
                                    bg-red-500
                                    border-2
                                    text-red-1000
                                    border-red-900
                                `
                        }
                    `}>{item.document_track[item.document_track.length - 1].track.track_name}
                    </span>
                </div>
                <div className='block lg:hidden' onClick={() => setShowDetail(showDetail == false ? true : false)}>
                    <i className={`fa-solid ${showDetail == true ? `fa-chevron-up` : 'fa-chevron-down'}`}></i>
                </div>
            </div>
            <div className={`${showDetail == false ? `hidden` : `block`} lg:hidden border-2 border-dashed border-gray-300 my-2`}>

            </div>
            <div className={`lg:col-span-1 mb-2 lg:mb-0 ${showDetail == false ? `hidden` : `block`} lg:inline`}>
                <p>Jenis LS</p>
                <p className='font-bold'>{item.jenis_ls.text}</p>
            </div>
            <div className={`${showButton == false ? `lg:col-span-3` : `lg:col-span-2`} mb-2 lg:mb-0 ${showDetail == false ? `hidden` : `block`} lg:inline`}>
                <p>Nama Perusahan</p>
                <p className='
                    font-bold
                    mr-2
                    teks-container 
                    max-w-full 
                    overflow-hidden 
                    whitespace-nowrap 
                    overflow-ellipsis
                '>
                    {
                        item.jenis_ls_id === staticData.spmId &&
                        getDocumentByIdForm(item.document, 12, "form_document_by_ls_id").data_value
                    }
                    {
                        item.jenis_ls_id === staticData.spbId &&
                        getDocumentByIdForm(item.document, 31, "form_document_by_ls_id").data_value
                    }
                </p>
            </div>
            <div className={`lg:col-span-2 ${showDetail == false ? `hidden` : `block`} lg:inline`}>
                <p>Bagian</p>
                <p className='font-bold'>
                    {
                        item.jenis_ls_id === staticData.spmId &&
                        <span className='text-dark'>{filterPosition(getDocumentByIdForm(item.document, 1, "form_document_by_ls_id").data_value).position_name}</span>
                    }
                    {
                        item.jenis_ls_id === staticData.spbId &&
                        <span className='text-dark'>{filterPosition(getDocumentByIdForm(item.document, 20, "form_document_by_ls_id").data_value).position_name}</span>
                    }
                </p>
            </div>
            <div className={`lg:col-span-2 my-2 lg:my-0 ${showDetail == false ? `hidden` : `block`} ${showButton == false ? `lg:hidden` : `lg:block`}`}>
                <div className='grid grid-cols-12 lg:h-full lg:justify-center lg:items-center gap-1'>
                    <div className='col-span-10'>
                        <button className='border-default border-2 rounded-xl px-2 bg-third text-default w-full' onClick={() => navigation(`/dashboard/dokumen/validasi/${item.id}`)}>Validasi</button>
                    </div>
                    <div className='col-span-2'>
                        <button className='border-red-500 border-2 rounded-xl bg-red-100 w-full' onClick={() => setShowModalDelete(true)}>
                            <i className='fa-solid fa-trash mx-2 text-red-500'></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className='col-span-1 flex flex-auto justify-center items-center hidden lg:inline-flex'>
                <div className='bg-white border-2 border-default rounded-lg w-min' onClick={() => setShowButton(showButton == false ? true : false)}>
                    <i className={`fa-solid ${showButton == true ? `fa-chevron-right` : `fa-chevron-left`} text-default mx-2`}></i>
                </div>
            </div>
            {
                showModalDelete == true &&
                    <TailwindModal cancelButtonText={"Batal"} confirmButtonText={"Hapus"} loadingConfirm={loadingDelete} showCancelButton={true} showConfirmButton={true} title={"Hapus Dokumen"} text={"Apakah anda yakin ?"} canceButtonAction={() => setShowModalDelete(false)} confirmButtonAction={(e) => deleteDocument(e, item.number)}/>
            }
        </div>

    )
}

export default DocumentCardAdmin

import React from 'react'
import AvailibilityAccordion from '../../component/accordions/AvailibilityAccordion'

const CardAvailibility = ({formAvailibilty}) => {
    return (
        <div className='w-full rounded-xl bg-white p-3'>
            <div className='mb-3'>
                <span className='text-default text-xl'>Kelengkapan Berkas</span>
            </div>
            <div>
                {formAvailibilty.map(item => (
                    <div className='border-t-2 border-t-gray-300 border-b-300 py-1'>
                        <AvailibilityAccordion name={item.name} type={item.availibility_file[0].type} desc={item.availibility_file[0].desc_availibility_file ? item.availibility_file[0].desc_availibility_file.desc : ""} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CardAvailibility

import React, { useState } from 'react'
import StaticData from '../../connection/StaticData'

const CardDocumentTrack = ({ item, allTrack = {}, allStatusTrack = {} }) => {
    const staticData = new StaticData()
    const [showDetail, setShowDetail] = useState(true)
    return (
        <div className='rounded-xl w-full p-3 bg-white'>
            <div>
                <span className='text-default'>Status</span>
            </div>
            {showDetail === true &&
                <div className='mb-3'>
                    {allTrack.map((data, key) => (
                        <>
                            <div className='flex flex-row'>
                                <div className='flex flex-col justify-center items-center w-7'>
                                    {
                                        item.document_track[key] ? (
                                            <div>
                                                {
                                                    item.document_track[key].status_track_id === allStatusTrack[0].id &&
                                                    <div className='rounded-full bg-green-500 w-5 h-5 flex m-1 justify-center items-center'>
                                                        <i className='fa-solid fa-check text-white'></i>
                                                    </div>
                                                }
                                                {
                                                    item.document_track[key].status_track_id === allStatusTrack[1].id &&
                                                    <div className='rounded-full bg-gray-500 w-5 h-5 flex m-1 justify-center items-center'>
                                                        <i className='fa-solid fa-clock text-white'></i>
                                                    </div>
                                                }
                                                {
                                                    item.document_track[key].status_track_id === allStatusTrack[2].id &&
                                                    <div className='rounded-full bg-red-500 w-5 h-5 flex m-1 justify-center items-center'>
                                                        <i className='fa-solid fa-xmark text-white'></i>
                                                    </div>
                                                }
                                            </div>
                                        ) : (
                                            <div className='rounded-full h-5 w-5 bg-gray-300 mx-1'>

                                            </div>
                                        )
                                    }
                                </div>
                                <div className='flex flex-auto'>
                                    <span>{data.track_name}</span>
                                </div>
                            </div>
                            <div className='flex flex-row'>
                                {allTrack.length > key + 1 &&
                                    <div className='h-11 w-7 flex justify-center items-center'>
                                        <div className='border-l-2 border-l-secondary border-dashed h-full w-1'>

                                        </div>
                                    </div>
                                }
                                <div className='flex flex-auto'>
                                    <div className='flex xl:flex-row w-full flex-col-reverse'>
                                        {item.document_track[key] &&
                                            <>
                                                <div className=''>
                                                    <span className='text-sm block'>{staticData.formatDate(item.document_track[key].updated_at)}</span>
                                                    {
                                                        item.document_track[key].user &&
                                                        <span>{item.document_track[key].user.name}</span>
                                                    }
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            }
            {showDetail === false &&
                <div className='mb-3'>
                    <div className='flex flex-row'>
                        <div className='flex flex-col justify-center items-center w-7'>
                            {
                                item.document_track[0] ? (
                                    <div>
                                        {
                                            item.document_track[0].status_track_id === allStatusTrack[0].id &&
                                            <div className='rounded-full bg-green-500 w-5 h-5 flex m-1 justify-center items-center'>
                                                <i className='fa-solid fa-check text-white'></i>
                                            </div>
                                        }
                                        {
                                            item.document_track[0].status_track_id === allStatusTrack[1].id &&
                                            <div className='rounded-full bg-gray-500 w-5 h-5 flex m-1 justify-center items-center'>
                                                <i className='fa-solid fa-clock text-white'></i>
                                            </div>
                                        }
                                        {
                                            item.document_track[0].status_track_id === allStatusTrack[2].id &&
                                            <div className='rounded-full bg-red-500 w-5 h-5 flex m-1 justify-center items-center'>
                                                <i className='fa-solid fa-xmark text-white'></i>
                                            </div>
                                        }
                                    </div>
                                ) : (
                                    <div className='rounded-full h-5 w-5 bg-gray-300 mx-1'>

                                    </div>
                                )
                            }
                        </div>
                        <div className='flex flex-auto'>
                            <span>{allTrack[0].track_name}</span>
                        </div>
                    </div>
                    <div className='flex flex-row'>
                        <div className='min-h-7 w-7 flex justify-center items-center'>
                            <div className='border-l-2 border-l-secondary border-dashed h-full w-1'>

                            </div>
                        </div>
                        <div className='flex flex-auto'>
                            <div className='flex xl:flex-row w-full flex-col-reverse'>
                                {item.document_track[0] &&
                                    <>
                                        <div className=''>
                                            <span className='text-sm block'>{staticData.formatDate(item.document_track[0].updated_at)}</span>
                                            {
                                                item.document_track[0].user &&
                                                <span>{item.document_track[0].user.name}</span>
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div>
                <button onClick={() => setShowDetail(showDetail === false ? true : false)} className='px-3 rounded-full bg-gray-300'>
                    <span className='mr-6'>{showDetail === false ? "Selengkapnya" : "Sembunyikan"}</span>
                    <i className={`text-dark fa-solid fa-chevron-${showDetail === false ? `down` : `up`}`}></i>
                </button>
            </div>
        </div>
    )
}

export default CardDocumentTrack

import { useEffect, useState } from "react"

export default function InputOptionWithDesc({ label, name, selectValue, descSelect}) {
    const [value, setValue] = useState()
    useEffect(() => {
        setValue(selectValue)
    }, [])
    function change(e) {
        setValue(e.target.value)
    }
    return (
        <div className="">
            <div className="mb-2">
                <label >{label}</label>
            </div>
            <select value={value} name={name} id="" className="w-full p-2 rounded-xl border-2 border-gray-200" onChange={(e) => {change(e)}} required>
                <option value="1">Ada</option>
                <option value="2">Tidak Ada</option>
                <option value="3">Tidak Diperlukan</option>
                <option value="4">Diperbaiki</option>
            </select>
            {value === "4" && 
                <div className="my-2">
                    <textarea name={'desc_' + name} id="" className="rounded-xl w-full border-2 border-gray-200" cols="30" rows="5" defaultValue={ descSelect } required></textarea>
                </div>
            }
        </div>
    )
}
import React from 'react'
import CardAuth from "../container/card/CardAuth"

const Index = () => {
    const bgIndex = {
        background: `url(${process.env.PUBLIC_URL}/image/backgroundLogin.jpg)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        weight: '100vw'
    }
    return (
        <>
            <div style={bgIndex}>
                <div className="h-full">
                    <div className="h-full grid grid-cols-1 md:grid-cols-4">
                        <div className="flex flex-auto p-4 justify-center items-center">
                            <CardAuth/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index
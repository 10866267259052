import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const LinkChild = ({ text, child = {}, iconClass, setShowSidebar }) => {
    const [showChild, setShowChild] = useState(false)
    return (
        <div>
            <div className='flex flex-row' onClick={() => setShowChild(showChild == true ? false : true)}>
                <div>
                    <i className={`${iconClass} text-default mr-1`}></i>
                </div>
                <div className='flex flex-auto'>
                    <span className='text-default'>{text}</span>
                </div>
                <div className='mx-1'>
                    <i className={`
                        fa-solid
                        text-default
                        ${showChild == true ? `fa-chevron-up` : `fa-chevron-down`}
                    `}></i>
                </div>
            </div>
            {showChild == true &&
                <div className='ml-2'>
                    {child.map(item => (
                        <Link className='block' to={item.link} onClick={setShowSidebar}>
                            <div className='flex flex-row my-2 lg:my-6'>
                                <div className='mx-1'>
                                    <i className={`${item.iconClass} text-default mx-1`}></i>
                                </div>
                                <div className='flex flex-auto'>
                                    <span className='text-default'>{item.text}</span>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            }
        </div>
    )
}

export default LinkChild

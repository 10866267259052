import React, { useEffect, useState } from 'react'
import UserListCard from '../../../container/card/UserListCard'
import { getApi } from '../../../connection/Api'
import { useUsers } from '../../../zustand/Index'
import { useNavigate } from 'react-router-dom'

const UserList = () => {
    const { users, setUsers } = useUsers()
    const [loading, setLoading] = useState(true)
    const [keySearch, setKeySearch] = useState("")
    const [page, setPage] = useState(1)
    const navigate = useNavigate()
    const getData = async () => {
        const resultUsers = await getApi('user/')
        setUsers(resultUsers.data)
        setLoading(false)
    }
    const settingData = async (currentPage) => {
        // setLoading(true)
        setPage(currentPage)
        let url = 'user'
        if(currentPage){
            url = url+`?page=${currentPage}`
        }
        if(currentPage){
            url = url+`?page=${currentPage}`
        }
        const result = await getApi(`user?page=${currentPage}&key=${keySearch}`)
        console.log(result.data)
        setUsers(result.data)
        window.location.href = '#user-wrapper'
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <div>
            {loading === false ? (
                <div className='flex flex-col w-full'>
                    <div className='my-3 grid grid-cols-12 w-full gap-2'>
                        <div className='col-span-3'>
                            <button onClick={() => navigate('/dashboard/user/tambah')} className='px-2 py-1 rounded-lg w-full bg-default text-white h-full'>
                                <span className='mr-2 hidden lg:inline'>Tambah User</span>
                                <i className="fa-solid fa-user-plus"></i>
                            </button>
                        </div>
                        <div className='col-span-2 lg:col-span-4'>

                        </div>
                        <div className='col-span-7 lg:col-span-5 w-full'>
                            <div className='grid grid-cols-12 gap-1'>
                                <div className='col-span-9'>
                                    <input type="text" className='h-full rounded-lg w-full' placeholder='Cari User' onChange={(e) => setKeySearch(e.target.value)}/>
                                </div>
                                <div className='col-span-3'>
                                    <button onClick={() => settingData(1)} type="button" className='px-2 rounded-lg bg-default h-full w-full text-white'><i class="fa-solid fa-magnifying-glass"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="user-wrapper" className='w-full flex flex-col gap-2'>
                        {users.data.map(item => (
                            <UserListCard user={item} />
                        ))}
                    </div>
                    <center>
                        <div className='flex flex-auto justify-center items-center'>
                            <div className='gap-1 w-full lg:w-auto'>
                                {users.links.map(item => {
                                    return (
                                        <button
                                            disabled={item.url === null ? true : false}
                                            className={`  
                                        px-2 
                                        my-2
                                        mx-1
                                        rounded-lg
                                        ${item.active === true &&
                                                `bg-default text-white border-default text-default`
                                                }
                                        ${item.active === false && item.url !== null ?
                                                    `bg-purple-100 border-default text-default` : ``
                                                }
                                        ${item.active === false && item.url === null ?
                                                    `bg-white border-gray text-gray-300` : ``
                                                }`}
                                            onClick={() => {
                                                let showPage
                                                if (item.label === 'pagination.previous' && item.url !== null) {
                                                    showPage = users.current_page - 1
                                                } else if (item.label === 'pagination.next' && item.url !== null) {
                                                    showPage = users.current_page + 1
                                                } else if (item.label !== 'pagination.next' && item.label !== 'pagination.previous') {
                                                    showPage = item.label
                                                } else {
                                                    showPage = 1
                                                }
                                                settingData(showPage)
                                            }}
                                        >
                                            {item.label === 'pagination.previous' &&
                                                <i className='fa-solid fa-chevron-left'></i>
                                            }
                                            {item.label === 'pagination.next' &&
                                                <i className='fa-solid fa-chevron-right'></i>
                                            }
                                            {item.label !== 'pagination.next' && item.label !== 'pagination.previous' ? (
                                                item.label
                                            ) : ''
                                            }
                                        </button>
                                    )
                                })}
                            </div>
                        </div>
                    </center>
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

export default UserList

import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getApi, postApiWithFile } from '../../../connection/Api'
import TailwindSpin from "../../../component/loading/TailwindSpin";
const AddUser = () => {
    const [role, setRole] = useState([])
    const [position, setPosition] = useState([])
    const [profileImage, setProfileImage] = useState(process.env.PUBLIC_URL + "/icon/user.png")
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const navigate = useNavigate()
    const fileInputRef = useRef(null);

    async function getRole() {
        let role = await getApi('user/role')
        setRole(role.data)
    }
    async function getAllPosition() {
        let mapPosition = []
        let position = await getApi('form/all-position')
        position.data.map(element => {
            mapPosition.push({
                id: element.id,
                name: element.position_name
            })
        })
        setPosition(mapPosition)
    }

    const formUser = [
        {
            label: 'Nama',
            name: 'name',
            type: 'text',
            id: 'name',
        },
        {
            label: 'Email',
            name: 'email',
            type: 'email',
            id: 'email',
        },
        {
            label: 'Role',
            name: 'role',
            type: 'option',
            id: 'role',
            data: role,
        },
        {
            label: 'Posisi',
            name: 'position[]',
            type: 'checkbox',
            id: 'position',
            data: position,
        },
    ]

    const submitFormUser = async (e) => {
        setLoadingSubmit(true)
        e.preventDefault()
        var form = new FormData(e.target)

        let inputFile = formUser.filter((item) => item.type === 'file')

        inputFile.map((item) => {
            var files = document.getElementById(item.id).files
            form.append(item.name, files[0])
        })

        let resultPost = await postApiWithFile('user/post', form)

        let textErr = document.getElementsByClassName('text-err')
        for (let i = 0; i < textErr.length; i++) {
            textErr[i].textContent = ""
        }

        if (resultPost.status === 'Error') {
            document.querySelector(`div[name=${resultPost.field}]`).textContent = resultPost.message
        }
        setLoadingSubmit(false)
        if (resultPost.status === "Success") {
            navigate('/dashboard/user', { replace: true })
        }
    }

    useEffect(() => {
        getRole()
        getAllPosition()
    }, [])
    return (
        <div>
            <div className='lg:grid lg:grid-cols-12'>
                <div className='lg:col-span-8 mb-6'>
                    <form onSubmit={(e) => { submitFormUser(e) }} encType="multipart/form-data" method='POST'>
                        <div className='flex flex-auto justify-center items-center my-5'>
                            <div className='w-40 h-40 relative '>
                                <img src={profileImage} alt="" className='w-full h-full rounded-full border-2 border-default' onClick={() => fileInputRef.current.click()} />
                                <input name="avatar" id="avatar" ref={fileInputRef} type="file" className='hidden' accept='.png, .jpg, .jpeg' onChange={(e) => setProfileImage(URL.createObjectURL(e.target.files[0]))} />
                                <div className='absolute bottom-4 end-2 rounded-full bg-default flex flex-auto justify-center items-center'>
                                    <i className='fa-solid fa-image text-white m-2'></i>
                                </div>
                                <div className='text-red-500' name="avatar"></div>
                            </div>
                        </div>
                        <div className='p-4 rounded-xl bg-white flex flex-col gap-4'>
                            {formUser.map(element => {
                                if (element.type === "checkbox") {
                                    return (
                                        <div className='flex flex-col'>
                                            <div className='mb-1'>
                                                <span>{element.label}</span>
                                            </div>
                                            <div className="lg:grid lg:grid-cols-12">
                                                {element.data.map(data => {
                                                    return (
                                                        <div className="col-span-6 my-1">
                                                            <input type={element.type} id={data.id} multiple name={element.name} className='rounded-md mr-3' value={data.id} />
                                                            <label htmlFor={data.id}>{data.name}</label>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className='text-danger text-err' name={element.id}></div>
                                        </div>
                                    )
                                } else if (element.type === "option") {
                                    return (
                                        <div className='flex flex-col'>
                                            <div className='mb-1'>
                                                <label className='' htmlFor={element.id}>{element.label}</label>
                                            </div>
                                            <div className='flex flex-auto'>
                                                <select name={element.name} id={element.id} className="w-full rounded-lg">
                                                    <option value="">{'-- Pilih ' + element.label + ' --'}</option>
                                                    {element.data.map(data => {
                                                        return (
                                                            <option value={data.id}>{data.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <div className='text-danger text-err' name={element.name}></div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="flex flex-col">
                                            <div className='mb-1'>
                                                <label htmlFor={element.id} className="form-label">{element.label}</label>
                                            </div>
                                            <input type={element.type} name={element.name} className="rounded-lg" id={element.id} accept={element.accept && element.accept} />
                                            <div className='text-danger text-err' name={element.name}></div>
                                        </div>
                                    )
                                }
                            })}
                            <div className='flex flex-auto gap-2 justify-end items-end'>
                                <button id="btn-submit-user" type="submit" className="bg-default px-5 py-2 rounded-xl text-white">
                                    {
                                        loadingSubmit === true ? (
                                            <TailwindSpin/>
                                        ) : (
                                            <span>Simpan</span>
                                        )
                                    }
                                </button>
                                <Link className="bg-gray-200 px-5 py-2 rounded-xl text-dark" to="/dashboard/user">Cancel</Link>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='lg:col-span-4'>

                </div>
            </div>
        </div>
    )
}

export default AddUser

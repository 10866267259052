import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getApi, getApiPublic } from '../../connection/Api'
import StaticData from '../../connection/StaticData'
import { useAllStatusTrack, useAllTrack, usePosition } from '../../zustand/Index'
import CardAuth from '../../container/card/CardAuth'
import CardAvailibility from '../../container/card/CardAvailibility'
import CardDetailDokumen from '../../container/card/CardDetailDokumen'
import CardDocumentTrack from '../../container/card/CardDocumentTrack'
import CardTitleDetailDocument from '../../container/card/CardTitleDetailDocument'

const PageDocumentTrack = () => {
    const [loading, setLoading] = useState(true)
    const [doc, setDoc] = useState()
    const [formAvailibilty, setFormAvailibility] = useState()

    const { position, setPosition } = usePosition()
    const { allTrack, setAllTrack } = useAllTrack()
    const { allStatusTrack, setAllStatusTrack } = useAllStatusTrack()
    const { number } = useParams()
    const navigate = useNavigate()
    const staticData = new StaticData()
    const getData = async () => {
        setLoading(true)
        const resultDocument = await getApi(`document/by-number/${number}`)
        if (resultDocument.data === null) {
            navigate('/')
        } else {
            setDoc(resultDocument.data)
        }
        const resultPosition = await getApi('form/all-position')
        setPosition(resultPosition.data)

        const resultAllTrack = await getApi('document/get-all-track')
        setAllTrack(resultAllTrack.data)

        const resultAllStatusTrack = await getApi('document/all-status-track')
        setAllStatusTrack(resultAllStatusTrack.data)

        const dataFormAva = await getApiPublic(`document/availibility-file-with-form/${resultDocument.data.jenis_ls_id}/${resultDocument.data.id}`)
        setFormAvailibility(dataFormAva.data)
        // console.log(dataFormAva.data)


        setLoading(false)
    }
    const getDocumentByIdForm = (data, id, keyFilter) => {
        const result = data.filter(element => element[keyFilter] === id);
        return result[0]
    }
    const filterPosition = (id) => {
        let result = position.filter(element => element.id === parseInt(id))
        return result[0]
    }
    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='flex flex-1 bg-secondary w-full min-h-screen'>
            {loading === false ? (
                <div className='lg:grid lg:grid-cols-12 w-full gap-2'>
                    <div className='lg:col-span-3 p-6 my-1 lg:my-0'>
                        <CardAuth />
                    </div>
                    <div className='lg:col-span-9 p-6'>
                        <div>
                            <CardTitleDetailDocument 
                                number={doc.number} 
                                statusTrackId={doc.document_track[doc.document_track.length - 1].status_track_id} 
                                allStatusTrack={allStatusTrack} 
                                statusTrackName={doc.document_track[doc.document_track.length - 1].track.track_name} 
                            />
                        </div>
                        <div className='flex lg:flex-row flex-col-reverse w-full mt-4 gap-4'>
                            <div className='lg:w-6/12'>
                                <div className='mb-3'>
                                    <CardDocumentTrack item={doc} allStatusTrack={allStatusTrack} allTrack={allTrack} />
                                </div>
                                <div className='my-3'>
                                    <CardAvailibility formAvailibilty={formAvailibilty}/>
                                </div>
                            </div>
                            <div className='lg:w-6/12'>
                                <div className='sticky top-0 my-1 lg:my-0'>
                                    <CardDetailDokumen
                                        jenisLS={doc.jenis_ls.text}
                                        namaKegiatan={`
                                            ${doc.jenis_ls_id === staticData.spmId ? getDocumentByIdForm(doc.document, 2, "form_document_by_ls_id").data_value : ``
                                            }
                                            ${doc.jenis_ls_id === staticData.spbId ? getDocumentByIdForm(doc.document, 21, "form_document_by_ls_id").data_value : ``
                                            }
                                            ${doc.jenis_ls_id === staticData.hibahId ? getDocumentByIdForm(doc.document, 35, "form_document_by_ls_id").data_value : ``
                                            }
                                        `}
                                        namaBagian={`
                                            ${doc.jenis_ls_id === staticData.spmId ? filterPosition(getDocumentByIdForm(doc.document, 1, "form_document_by_ls_id").data_value).position_name : ``}
                                            ${doc.jenis_ls_id === staticData.spbId ? filterPosition(getDocumentByIdForm(doc.document, 20, "form_document_by_ls_id").data_value).position_name : ``}
                                        `}
                                        namaPerusahaan={`
                                            ${doc.jenis_ls_id === staticData.spmId ? getDocumentByIdForm(doc.document, 12, "form_document_by_ls_id").data_value : ``
                                            }
                                            ${doc.jenis_ls_id === staticData.spbId ? getDocumentByIdForm(doc.document, 31, "form_document_by_ls_id").data_value : ``
                                            }
                                        `}
                                        tanggal={staticData.formatDate(doc.updated_at)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

export default PageDocumentTrack

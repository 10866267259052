import Swal from 'sweetalert2';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from 'jquery'

class StaticData{
    baseUrl = "https://keuangan-be.hanami.studio/api/"
    url = "https://keuangan-be.hanami.studio/"

    spmId = 1
    spbId = 2
    hibahId = 3


    swalFire = async (title, icon, text, confirmButton = true, redirect = null) => {
        if (redirect === null) {
            return Swal.fire({
                title: title,
                icon: icon,
                text: text,
                showDenyButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: false,
                showConfirmButton: confirmButton,
            })
        } else {
            return Swal.fire({
                title: title,
                icon: icon,
                text: text,
                showDenyButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                showConfirmButton: confirmButton,
            }).then(function () {
                window.location = redirect
            })
        }
    }

    swalLoading = (title, text) => {
        return Swal.fire({
            title: title,
            text: text,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: function () {
                Swal.showLoading()
            },
        })
    }

    dataTable = (idTable) => {
        let id = '#' + idTable
        if (!$.fn.DataTable.isDataTable(id)) {
            $(document).ready(function () {
                setTimeout(function () {
                    $(id).DataTable({
                        pagingType: "full_numbers",
                        pageLength: 10,
                        processing: true,
                        dom: "Bfrtip",
                        select: {
                            style: "single",
                        },

                        buttons: [
                            {
                                extend: "pageLength",
                                className: "btn btn-secondary bg-secondary",
                            },
                            {
                                extend: "copy",
                                className: "btn btn-secondary bg-secondary",
                            },
                            {
                                extend: "csv",
                                className: "btn btn-secondary bg-secondary",
                            },
                            {
                                extend: "print",
                                customize: function (win) {
                                    $(win.document.body).css("font-size", "10pt");
                                    $(win.document.body)
                                        .find("table")
                                        .addClass("compact")
                                        .css("font-size", "inherit");
                                },
                                className: "btn btn-secondary bg-secondary",
                            },
                        ],

                        fnRowCallback: function (
                            nRow,
                            aData,
                            iDisplayIndex,
                            iDisplayIndexFull
                        ) {
                            var index = iDisplayIndexFull + 1;
                            $("td:first", nRow).html(index);
                            return nRow;
                        },

                        lengthMenu: [
                            [10, 20, 30, 50, -1],
                            [10, 20, 30, 50, "All"],
                        ],
                        columnDefs: [
                            {
                                targets: 0,
                                render: function (data, type, row, meta) {
                                    return type === "export" ? meta.row + 1 : data;
                                },
                            },
                        ],
                    });
                }, 1000);
            });
        }
    }

    intToRupiah = (angka) => {
        let prefix = 'Rp .'
        // angka = angka.toFixed()
        angka = angka.toString()
        var number_string = angka.replace(/[^\d]/g, '').toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        if (ribuan) {
            let separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }

        rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix === undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }

    rupiahToInt = (rupiah) => {
        let number_string = rupiah.toString()
        let normalInt = parseInt(number_string.replace(/,.*|[^0-9]/g, ""), 10);

        return normalInt
    }

    formatDate = (param) => {
        if (param) {
            const month = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
            let date = new Date(param)
            return date.getDate() + " " + month[date.getMonth()] + " " + date.getFullYear()
        } else {
            return ""
        }
    }

    formatTime = (param) => {
        if (param) {
            const addZero = (i) => {
                if (i < 10) { i = "0" + i }
                return i;
            }
            let date = new Date(param)
            return addZero(date.getHours()) + ":" + date.getMinutes()
        } else {
            return ""
        }
    }
    dateWithRange = (firstDate, range) => {

        // Kurangkan 30 hari dari tanggal saat ini
        var lastDate = new Date(firstDate);
        lastDate.setDate(firstDate.getDate() - range);

        // Format tanggal ke dalam string (jika diperlukan)
        var dateFormatted = lastDate.toISOString().split('T')[0];

        return dateFormatted;
    }

    accountCode = [
        {
            "kode": "2.13.05.2.01",
            "nama": "Pemberdayaan Lembaga Kemasyarakatan yang Bergerak di Bidang Pemberdayaan Desa dan Lembaga Adat Tingkat Daerah Kabupaten/Kota serta Pemberdayaan Masyarakat Hukum Adat yang Masyarakat Pelakunya Hukum Adat yang Sama dalam Daerah Kabupaten/Kota",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "2.13.05.2.01.03",
            "nama": "Peningkatan Kapasitas Kelembagaan Lembaga Kemasyarakatan Desa/Kelurahan (RT, RW, PKK, Posyandu, LPM, dan Karang Taruna), Lembaga Adat Desa/Kelurahan dan Masyarakat Hukum Adat",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0001",
            "nama": "Belanja Bahan-Bahan Bangunan dan Konstruksi",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0008",
            "nama": "Belanja Bahan-Bahan/Bibit Tanaman",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0032",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Perlengkapan Dinas",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0036",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat/Bahan untuk Kegiatan Kantor Lainnya",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0037",
            "nama": "Belanja Obat-Obatan-Obat",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0043",
            "nama": "Belanja Natura dan Pakan-Natura",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0053 ",
            "nama": " Belanja Makanan dan Minuman Jamuan Tamu ",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0058",
            "nama": "Belanja Makanan dan Minuman Aktivitas Lapangan",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0075",
            "nama": "Belanja Pakaian Batik Tradisional",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0076",
            "nama": "Belanja Pakaian Olahraga",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.01.0007",
            "nama": "Honorarium Rohaniwan",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.01.0025",
            "nama": "Belanja Jasa Tenaga Kesenian dan Kebudayaan",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.01.0027",
            "nama": "Belanja Jasa Tenaga Operator Komputer",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.04.0123",
            "nama": "Belanja Sewa Alat Rumah Tangga Lainnya (Home Use)",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.04.0117",
            "nama": "Belanja Sewa Alat Kantor Lainnya ",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.04.0118 ",
            "nama": "Belanja Sewa Mebel",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.04.0121",
            "nama": "Belanja Sewa Alat Pendingin",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.04.0137",
            "nama": "Belanja Sewa Alat Studio Lainnya",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.05.0030",
            "nama": "Belanja Sewa Bangunan Gedung Tempat Kerja Lainnya",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.05.05.01.0001",
            "nama": "Belanja Hibah Uang kepada Badan dan Lembaga yang Bersifat Nirlaba, Sukarela dan Sosial yang Dibentuk Berdasarkan Peraturan Perundang-Undangan",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "2.13.05.2.01.04",
            "nama": "Fasilitasi Penyediaan Sarana dan Prasarana Kelembagaan Lembaga Kemasyarakatan Desa/Kelurahan (RT, RW, PKK, Posyandu, LPM, dan Karang Taruna), Lembaga Adat Desa/Kelurahan dan Masyarakat Hukum Adat",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0001",
            "nama": "Belanja Bahan-Bahan Bangunan dan Konstruksi",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0036",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat/Bahan untuk Kegiatan Kantor Lainnya",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0032",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Perlengkapan Dinas",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0058",
            "nama": "Belanja Makanan dan Minuman Aktivitas Lapangan",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0075",
            "nama": "Belanja Pakaian Batik Tradisional",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.01.0028",
            "nama": "Belanja Jasa Tenaga Pelayanan Umum",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.04.0049",
            "nama": "Belanja Sewa Alat Angkutan Apung Bermotor untuk Penumpang",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.09.0014",
            "nama": "Belanja Jasa Konsultansi Berorientasi Layanan-Jasa Khusus",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "2.13.05.2.01.06",
            "nama": "Fasilitasi Pemerintah Desa dalam Pemanfaatan Teknologi Tepat Guna",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.01.01.0058",
            "nama": "Belanja Makanan dan Minuman Aktivitas Lapangan",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.02.05.0037",
            "nama": "Belanja Sewa Bangunan Gedung Tempat Kerja Lainnya",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "5.1.02.05.01.0001",
            "nama": "Belanja Hadiah yang Bersifat Perlombaan",
            "bagian": "BAGIAN PEMBERDAYAAN MASYARAKAT"
        },
        {
            "kode": "4.01.01.2.01",
            "nama": "Perenanaan, Penganggaran, dan Evaluasi Kinerja Perangkat Daerah",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "4.01.01.2.01.0001",
            "nama": "Penyusunan Dokumen Perencanaan Perangkat Daerah",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0043",
            "nama": "Belanja Natura dan Pakan-Natura",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0058",
            "nama": "Belanja Makanan dan Minuman Aktivitas Lapangan",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "4.01.01.2.01.0002",
            "nama": "Koordinasi dan Penyusunan Dokumen RKA-SKPD",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "4.01.01.2.01.0004",
            "nama": "Koordinasi dan Penyusunan Dokumen DPA-SKPD",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "4.01.01.2.01.0006",
            "nama": "Koordinasi dan Penyusunan Laporan Capaian Kinerja dan Ikhtisar Realisasi Kinerja SKPD",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "4.01.01.2.01.0007",
            "nama": "Evaluasi Kinerja Perangkat Daerah",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "4.01.01.2.02",
            "nama": "Administrasi Keuangan Perangkat Daerah",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "4.01.01.2.02.0001",
            "nama": "Penyediaan Gaji dan Tunjangan ASN",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.01.01.0001",
            "nama": "Belanja Gaji Pokok PNS",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.01.01.0002",
            "nama": "Belanja Gaji Pokok PPPK",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.01.02.0001",
            "nama": "Belanja Tunjangan Keluarga PNS",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.01.02.0002",
            "nama": "Belanja Tunjangan Keluarga PPPK",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.01.03.0001",
            "nama": "Belanja Tunjangan Jabatan PNS",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.01.04.0001",
            "nama": "Belanja Tunjangan Fungsional PNS",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.01.04.0002",
            "nama": "Belanja Tunjangan Fungsional PPPK",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.01.05.0001",
            "nama": "Belanja Tunjangan Fungsional Umum PNS",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.01.06.0001",
            "nama": "Belanja Tunjangan Beras PNS",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.01.06.0002",
            "nama": "Belanja Tunjangan Beras PPPK",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.01.07.0001",
            "nama": "Belanja Tunjangan PPh/Tunjangan Khusus PNS",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.01.08.0001",
            "nama": "Belanja Pembulatan Gaji PNS",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.01.08.0002",
            "nama": "Belanja Pembulatan Gaji PPPK",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.01.09.0001",
            "nama": "Belanja Iuran Jaminan Kesehatan PNS",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.01.09.0002",
            "nama": "Belanja Iuran Jaminan Kesehatan PPPK",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.01.10.0001",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja PNS",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.01.10.0002",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja PPPK",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.01.11.0001",
            "nama": "Belanja Iuran Jaminan Kematian PNS",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.01.11.0002",
            "nama": "Belanja Iuran Jaminan Kematian PPPK",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.02.01.0001",
            "nama": "Tambahan Penghasilan berdasarkan Beban Kerja PNS",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.02.03.0001",
            "nama": "Tambahan Penghasilan berdasarkan Kondisi Kerja PNS",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.02.04.0001",
            "nama": "Tambahan Penghasilan berdasarkan Kelangkaan Profesi PNS",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.03.07.0001",
            "nama": "Belanja Honorarium Penanggungjawaban Pengelola Keuangan",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.03.08.0002",
            "nama": "Belanja Jasa Pengelolaan BMD yang Tidak Menghasilkan Pendapatan",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "4.01.01.2.02.0002",
            "nama": "Penyediaan Administrasi Pelaksanaan Tugas ASN",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.03.07.0001",
            "nama": "Belanja Honorarium Penanggungjawaban Pengelola Keuangan",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.03.08.0002",
            "nama": "Belanja Jasa Pengelolaan BMD yang Tidak Menghasilkan Pendapatan",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0031",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Listrik",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.12.0001",
            "nama": "Belanja Kursus Singkat/Pelatihan",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "4.01.01.2.02.0003",
            "nama": "Pelaksanaan Penatausahaan dan Pengujian/Verifikasi Keuangan SKPD",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0027",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Benda Pos",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0043",
            "nama": "Belanja Natura dan Pakan-Natura",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0058",
            "nama": "Belanja Makanan dan Minuman Aktivitas Lapangan",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.01.0029",
            "nama": "Belanja Jasa Tenaga Ahli",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.01.0063",
            "nama": "Belanja Kawat/Faksimili/Internet/TV Berlangganan",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "4.01.01.2.02.0005",
            "nama": "Koordinasi dan Penyusunan Laporan Keuangan Akhir Tahun SKPD",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.05.0002",
            "nama": "Belanja Sewa Bangunan Gudang",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "4.01.01.2.02.0006",
            "nama": "Pengelolaan dan Penyiapan Bahan Tanggapan Pemeriksaan",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "4.01.01.2.02.0007",
            "nama": "Koordinasi dan Penyusunan Laporan Keuangan Bulanan/ Triwulanan/ Semesteran SKPD",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "4.01.01.2.11",
            "nama": "Administrasi Keuangan dan Operasional Kepala Daerah dan Wakil Kepala Daerah",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "4.01.01.2.11.0001",
            "nama": "Penyediaan Gaji dan Tunjangan Kepala Daerah dan Wakil Kepala Daerah",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.05.01.0001",
            "nama": "Belanja Gaji Pokok KDH/WKDH",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.05.02.0001",
            "nama": "Belanja Tunjangan Keluarga KDH/WKDH",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.05.03.0001",
            "nama": "Belanja Tunjangan Jabatan KDH/WKDH",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.05.04.0001",
            "nama": "Belanja Tunjangan Beras KDH/WKDH",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.05.05.0001",
            "nama": "Belanja Tunjangan PPh/Tunjangan Khusus KDH/WKDH",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.05.06.0001",
            "nama": "Belanja Pembulatan Gaji KDH/WKDH",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.05.07.0001",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi KDH/WKDH",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.05.08.0001",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja KDH/WKDH",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.05.09.0001",
            "nama": "Belanja Iuran Jaminan Kematian KDH/WKDH",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "4.01.01.2.11.0003",
            "nama": "Pelaksanaan Medical Check Up Kepala Daerah dan Wakil Kepala Daerah",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.02.02.01.0073",
            "nama": "Belanja Medical Check Up",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "4.01.01.2.11.0004",
            "nama": "Penyediaan Dana Penunjang Operasional Kepala Daerah dan Wakil Kepala Daerah",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "5.1.01.06.02.0001",
            "nama": "Belanja Dana Operasional KDH/WKDH",
            "bagian": "BAGIAN PERENCANAAN DAN KEKUANGAN"
        },
        {
            "kode": "4.01.01.2.03",
            "nama": "Administrasi Barang Milik Daerah pada Perangkat Daerah",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.03.02",
            "nama": "Pengamanan Barang Milik Daerah SKPD",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0060",
            "nama": "Belanja Tagihan Air",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0061",
            "nama": "Belanja Tagihan Listrik",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0063",
            "nama": "Belanja Kawat/Faksimili/Internet/TV Berlangganan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.02.0008",
            "nama": "Belanja Asuransi Barang Milik Daerah",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.05.0030",
            "nama": "Belanja Sewa Bangunan Gedung Tempat Kerja Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.05.0002",
            "nama": "Belanja Sewa Bangunan Gudang",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.03.06",
            "nama": "Penatausahaan Barang Milik Daerah pada SKPD",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0027",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0036",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat/Bahan untuk Kegiatan Kantor Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.05",
            "nama": "Administrasi Kepegawaian Perangkat Daerah",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.05.02",
            "nama": "Pengadaan Pakaian Dinas beserta Atribut Kelengkapannya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0032",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Perlengkapan Dinas",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0036",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat/Bahan untuk Kegiatan Kantor Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0059",
            "nama": "Belanja Pakaian Dinas KDH dan WKDH",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0061",
            "nama": "Belanja Pakaian Sipil Harian (PSH)",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0062",
            "nama": "Belanja Pakaian Sipil Lengkap (PSL)",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0063",
            "nama": "Belanja Pakaian Dinas Harian (PDH)",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0065",
            "nama": "Belanja Pakaian Sipil Resmi (PSR)",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0074",
            "nama": "Belanja Pakaian Adat Daerah",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0075",
            "nama": "Belanja Pakaian Batik Tradisional",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0076",
            "nama": "6 Belanja Pakaian Olahraga",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.05.01.0005",
            "nama": "Belanja Modal Alat Kantor Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.15.02.0003",
            "nama": "Belanja Modal Topi Kerja",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.05.09",
            "nama": "Pendidikan dan Pelatihan Pegawai Berdasarkan Tugas dan Fungsi",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.12.0001",
            "nama": "Belanja Kursus Singkat/Pelatihan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.06",
            "nama": "Administrasi Umum Perangkat Daerah",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.06.01",
            "nama": "Penyediaan Komponen Instalasi Listrik/Penerangan Bangunan Kantor",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0001",
            "nama": "Belanja Bahan-Bahan Bangunan dan Konstruksi",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0031",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Listrik",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0036",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat/Bahan untuk Kegiatan Kantor Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0035",
            "nama": "Belanja Jasa Tenaga Teknisi Mekanik dan Listrik",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.03.02.0005",
            "nama": "Belanja Modal Perkakas Standard (Standard Tools)",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.03.03.0001",
            "nama": "Belanja Modal Alat Ukur Universal",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.05.02.0007",
            "nama": "Belanja Modal Alat Pemadam Kebakaran",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.06.05",
            "nama": "Penyediaan Barang Cetakan dan Penggandaan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Umum"
        },
        {
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.06.06",
            "nama": "Penyediaan Bahan Bacaan dan Peraturan Perundang-undangan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0062",
            "nama": "Belanja Langganan Jurnal/Surat Kabar/Majalah",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.06.08",
            "nama": "Fasilitasi Kunjungan Tamu",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0045",
            "nama": "Belanja Natura dan Pakan-Natura dan Pakan Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0053",
            "nama": "Belanja Makanan dan Minuman Jamuan Tamu",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.06.09",
            "nama": "Penyelenggaraan Rapat Koordinasi dan Konsultasi SKPD",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.04.01.0003",
            "nama": "Belanja Perjalanan Dinas Dalam Kota",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.07",
            "nama": "Pengadaan Barang Milik Daerah Penunjang Urusan Pemerintah Daerah",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.07.02",
            "nama": "Pengadaan Kendaraan Dinas Operasional atau Lapangan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.02.01.0001",
            "nama": "Belanja Modal Kendaraan Dinas Bermotor Perorangan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.02.01.0002",
            "nama": "Belanja Modal Kendaraan Bermotor Penumpang",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.02.01.0003",
            "nama": "Belanja Modal Kendaraan Bermotor Angkutan Barang",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.02.01.0004",
            "nama": "Belanja Modal Kendaraan Bermotor Beroda Dua",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.02.01.0006",
            "nama": "Belanja Modal Kendaraan Bermotor Khusus",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.07.05",
            "nama": "Pengadaan Mebel",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.05.01.0004",
            "nama": "Belanja Modal Alat Penyimpan Perlengkapan Kantor",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.05.02.0006",
            "nama": "Belanja Modal Alat Rumah Tangga Lainnya (Home Use)",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.05.03.0001",
            "nama": "Belanja Modal Meja Kerja Pejabat",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.05.03.0003",
            "nama": "Belanja Modal Kursi Kerja Pejabat",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.05.03.0007",
            "nama": "Belanja Modal Lemari dan Arsip Pejabat",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.07.06",
            "nama": "Pengadaan Peralatan dan Mesin Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0014",
            "nama": "Belanja Suku Cadang-Suku Cadang Alat Besar",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0023",
            "nama": "Belanja Suku Cadang-Suku Cadang Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0030",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Perabot Kantor",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0035",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Suvenir/Cendera Mata",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0036",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat/Bahan untuk Kegiatan Kantor Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0014",
            "nama": "Belanja Jasa Konsultansi Berorientasi Layanan-Jasa Khusus",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.01.03.0005",
            "nama": "Belanja Modal Feeder",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.01.03.0005",
            "nama": "Belanja Modal Pompa",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.01.03.0006",
            "nama": "Belanja Modal Mesin Bor",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.03.01.0001",
            "nama": "Belanja Modal Perkakas Konstruksi Logam Terpasang pada Fondasi",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.03.01.0002",
            "nama": "Belanja Modal Perkakas Konstruksi Logam yang Transportable (Berpindah)",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.03.01.0003",
            "nama": "Belanja Modal Perkakas Bengkel Listrik",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.03.01.0006",
            "nama": "Belanja Modal Perkakas Bengkel Kayu",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.03.02.0005",
            "nama": "Belanja Modal Perkakas Standard (Standard Tools)",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.03.02.0013",
            "nama": "Belanja Modal Alat Bengkel Tak Bermesin Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.05.01.0001",
            "nama": "Belanja Modal Mesin Ketik",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.05.01.0003",
            "nama": "Belanja Modal Alat Reproduksi (Penggandaan)",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.05.01.0005",
            "nama": "Belanja Modal Alat Kantor Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.05.02.0003",
            "nama": "Belanja Modal Alat Pembersih",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.05.02.0004",
            "nama": "Belanja Modal Alat Pendingin",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.05.02.0005",
            "nama": "Belanja Modal Alat Dapur",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.05.02.0006",
            "nama": "Belanja Modal Alat Rumah Tangga Lainnya (Home Use)",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.06.01.0001",
            "nama": "Belanja Modal Peralatan Studio Audio",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.06.01.0002",
            "nama": "Belanja Modal Peralatan Studio Video dan Film",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.06.03.0021",
            "nama": "Belanja Modal Feeder",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.06.03.0047",
            "nama": "Belanja Modal Sumber Tenaga",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.06.02.0001",
            "nama": "Belanja Modal Alat Komunikasi Telephone",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.06.04.0007 ",
            "nama": "Belanja Modal Peralatan Komunikasi Navigasi Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.08.03.0012",
            "nama": "Belanja Modal Alat Peraga Praktek Sekolah Bidang Studi:PKN",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.10.01.0001",
            "nama": "Belanja Modal Komputer Jaringan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.10.01.0002",
            "nama": "Belanja Modal Personal Computer",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.10.02.0001",
            "nama": "Belanja Modal Peralatan Mainframe",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.10.02.0002",
            "nama": "Belanja Modal Peralatan Mini Computer",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.10.02.0003",
            "nama": "Belanja Modal Peralatan Personal Computer",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.10.02.0004",
            "nama": "Belanja Modal Peralatan Jaringan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.13.01.0003",
            "nama": "Belanja Modal Sumur Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.02.17.01.0024",
            "nama": "Belanja Modal Mechanical Process",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.05.08.01.0009",
            "nama": "Belanja Modal Aset Tidak Berwujud Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.03.04.01.0003",
            "nama": "Belanja Modal Pilar/Tugu/Tanda Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.05.02.01.0001",
            "nama": "Belanja Modal Alat Musik",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.08",
            "nama": "Penyediaan Jasa Penunjang Urusan Pemerintahan Daerah",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.08.01",
            "nama": "Penyediaan Jasa Surat Menyurat",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0032",
            "nama": "Belanja Jasa Tenaga Caraka",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0064",
            "nama": "Belanja Paket/Pengiriman",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.08.02",
            "nama": "Penyediaan Jasa Komunikasi, Sumber Daya Air dan Listrik",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0059",
            "nama": "Belanja Tagihan Telepon",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0060",
            "nama": "Belanja Tagihan Air",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0061",
            "nama": "Belanja Tagihan Listrik",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.08.03",
            "nama": "Penyediaan Jasa Peralatan dan Perlengkapan Kantor",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0035",
            "nama": "Belanja Jasa Tenaga Teknisi Mekanik dan Listrik",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.04.0022",
            "nama": "Belanja Sewa Electric Generating Set",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.04.0036",
            "nama": "Belanja Sewa Kendaraan Bermotor Penumpang",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.04.0048",
            "nama": "Belanja Sewa Alat Angkutan Apung Bermotor untuk Barang",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.04.0049",
            "nama": "Belanja Sewa Alat Angkutan Apung Bermotor untuk Penumpang",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.04.0117",
            "nama": "Belanja Sewa Alat Kantor Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.04.0118",
            "nama": "Belanja Sewa Mebel",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.04.0121",
            "nama": "Belanja Sewa Alat Pendingin",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.04.0123",
            "nama": "Belanja Sewa Alat Rumah Tangga Lainnya (Home Use)",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.04.0133",
            "nama": "Belanja Sewa Peralatan Studio Video dan Film",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.04.0137",
            "nama": "Belanja Sewa Alat Studio Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.04.0494",
            "nama": "Belanja Sewa Rambu-Rambu Lalu Lintas Darat Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.07.0057",
            "nama": "Belanja Sewa Tanaman",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.08.04",
            "nama": "Penyediaan Jasa Pelayanan Umum Kantor",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0030",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Perabot Kantor",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0036",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat/Bahan untuk Kegiatan Kantor Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0030",
            "nama": "Belanja Jasa Tenaga Kebersihan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.09",
            "nama": "Pemeliharaan Barang Milik Daerah Penunjang Urusan Pemerintahan Daerah",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.09.01",
            "nama": "Penyediaan Jasa Pemeliharaan, Biaya Pemeliharaan, dan Pajak Kendaraan Perorangan Dinas atau Kendaraan Dinas Jabatan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0004",
            "nama": "Belanja Bahan-Bahan Bakar dan Pelumas",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0067",
            "nama": "Belanja Pembayaran Pajak, Bea, dan Perizinan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.03.02.0035",
            "nama": "Belanja Pemeliharaan Alat Angkutan-Alat Angkutan Darat Bermotor-Kendaraan Dinas Bermotor Perorangan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.09.02",
            "nama": "Penyediaan Jasa Pemeliharaan, Biaya Pemeliharaan, Pajak dan Perizinan Kendaraan Dinas Operasional atau Lapangan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0004",
            "nama": "Belanja Bahan-Bahan Bakar dan Pelumas",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0013",
            "nama": "Belanja Suku Cadang-Suku Cadang Alat Angkutan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0013",
            "nama": "Belanja Suku Cadang-Suku Cadang Alat Besar",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0033",
            "nama": "Belanja Jasa Tenaga Supir",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0067",
            "nama": "Belanja Pembayaran Pajak, Bea, dan Perizinan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.03.02.0035",
            "nama": "Belanja Pemeliharaan Alat Angkutan-Alat Angkutan Darat Bermotor-Kendaraan Dinas Bermotor Perorangan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.03.02.0036",
            "nama": "Belanja Pemeliharaan Alat Angkutan-Alat Angkutan Darat Bermotor-Kendaraan Bermotor Penumpang",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.03.02.0037",
            "nama": "Belanja Pemeliharaan Alat Angkutan-Alat Angkutan Darat Bermotor-Kendaraan Bermotor Angkutan Barang",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.03.02.0038",
            "nama": "Belanja Pemeliharaan Alat Angkutan-Alat Angkutan Darat Bermotor-Kendaraan Bermotor Beroda Dua",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.03.02.0049 ",
            "nama": "Belanja Pemeliharaan Alat Angkutan-Alat Angkutan Apung Bermotor-Alat Angkutan Apung Bermotor untuk Penumpang",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.09.05",
            "nama": "Pemeliharaan Mebel",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.03.02.0123 ",
            "nama": "Belanja Pemeliharaan Alat Kantor dan Rumah Tangga-Alat Rumah Tangga-Alat Rumah Tangga Lainnya (Home Use)",
            "bagian": "Bagian Umum"
        },
        {
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.09.06",
            "nama": "Pemeliharaan Peralatan dan Mesin Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0004",
            "nama": "Belanja Bahan-Bahan Bakar dan Pelumas",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.09.0003",
            "nama": "Belanja Jasa Konsultansi Berorientasi Bidang-Telematika",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.09.0003",
            "nama": "Belanja Jasa Konsultansi Berorientasi Layanan-Jasa Konsultansi Manajemen",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0046 ",
            "nama": "Belanja Jasa Konversi Aplikasi/Sistem Informasi",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.03.02.0022",
            "nama": "Belanja Pemeliharaan Alat Besar-Alat Bantu-Electric Generating Set",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.03.02.0115",
            "nama": "Belanja Pemeliharaan Alat Kantor dan Rumah Tangga-Alat Kantor-Alat Reproduksi (Penggandaan)",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.03.02.0117",
            "nama": "Belanja Pemeliharaan Alat Kantor dan Rumah Tangga-Alat Kantor-Alat Kantor Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.03.02.0118",
            "nama": "Belanja Pemeliharaan Alat Kantor dan Rumah Tangga-Alat Rumah Tangga-Mebel",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.03.02.0121",
            "nama": "Belanja Pemeliharaan Alat Kantor dan Rumah Tangga-Alat Rumah Tangga-Alat Pendingin",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.03.02.0123",
            "nama": "Belanja Pemeliharaan Alat Kantor dan Rumah Tangga-Alat Rumah Tangga-Alat Rumah Tangga Lainnya (Home Use)",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.03.02.0195",
            "nama": "Belanja Pemeliharaan Alat Studio, Komunikasi, dan Pemancar-Peralatan Pemancar-Sumber Tenaga",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.03.02.0405",
            "nama": "Belanja Pemeliharaan Komputer-Komputer Unit-Personal Computer",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.03.02.0411",
            "nama": "Belanja Pemeliharaan Komputer-Peralatan Komputer-Peralatan Komputer Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.09.09",
            "nama": "Pemeliharaan/Rehabilitasi Gedung Kantor dan Bangunan Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0001",
            "nama": "Belanja Bahan-Bahan Bangunan dan Konstruksi",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0002",
            "nama": "2 Belanja Bahan-Bahan Kimia",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0008",
            "nama": "Belanja Bahan-Bahan/Bibit Tanaman",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0030",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Perabot Kantor",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0036",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat/Bahan untuk Kegiatan Kantor Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0016",
            "nama": "Belanja Jasa Tenaga Penanganan Prasarana dan Sarana Umum",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0052",
            "nama": "Belanja Jasa Pembersihan, Pengendalian Hama, dan Fumigasi",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.05.0030",
            "nama": "Belanja Sewa Bangunan Gedung Tempat Kerja Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0060",
            "nama": "Belanja Tagihan Air",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0061",
            "nama": "Belanja Tagihan Listrik",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.04.0123",
            "nama": "Belanja Sewa Alat Rumah Tangga Lainnya (Home Use)",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.05.0001",
            "nama": "Belanja Sewa Bangunan Gedung Kantor",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.08.0002",
            "nama": "Belanja Jasa Konsultansi Perencanaan Arsitektur-Jasa Desain Arsitektural",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.08.0002",
            "nama": "Belanja Jasa Konsultansi Perencanaan Arsitektur-Jasa Desain Interior",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.08.0019",
            "nama": "Belanja Jasa Konsultansi Pengawasan Rekayasa-Jasa Pengawas Pekerjaan Konstruksi Bangunan Gedung",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.03.01.0029",
            "nama": "Belanja Pemeliharaan Tanah-Lapangan-Tanah untuk Taman",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.03.03.0001",
            "nama": "Belanja Pemeliharaan Bangunan Gedung-Bangunan Gedung Tempat Kerja-Bangunan Gedung Kantor",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.03.03.0036",
            "nama": "Belanja Pemeliharaan Bangunan Gedung-Bangunan Gedung Tempat Kerja-Taman",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.2.03.01.01.0001",
            "nama": "Belanja Modal Bangunan Gedung Kantor",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.12",
            "nama": "Fasilitasi Kerumahtanggaan Sekretariat Daerah",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.12.01",
            "nama": "Penyediaan Kebutuhan Rumah Tangga Kepala Daerah",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0010 ",
            "nama": "Belanja Bahan-Isi Tabung Gas",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0030 ",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Perabot Kantor",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0036 ",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat/Bahan untuk Kegiatan Kantor Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0043",
            "nama": "Belanja Natura dan Pakan-Natura",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0045",
            "nama": "Belanja Natura dan Pakan-Natura dan Pakan Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0050",
            "nama": "Belanja Persediaan Dalam Proses-Persediaan Dalam Proses",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0053",
            "nama": "Belanja Makanan dan Minuman Jamuan Tamu",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0028 ",
            "nama": "Belanja Jasa Tenaga Pelayanan Umum",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0030 ",
            "nama": "Belanja Jasa Tenaga Kebersihan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0031",
            "nama": "Belanja Jasa Tenaga Keamanan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0033",
            "nama": "Belanja Jasa Tenaga Supir",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0049",
            "nama": "Belanja Jasa Pencucian Pakaian, Alat Kesenian dan Kebudayaan, serta Alat Rumah Tangga",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0063",
            "nama": "Belanja Kawat/Faksimili/Internet/TV Berlangganan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0067",
            "nama": "Belanja Pembayaran Pajak, Bea, dan Perizinan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.12.02",
            "nama": "Penyediaan Kebutuhan Rumah Tangga Wakil Kepala Daerah",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0010 ",
            "nama": "Belanja Bahan-Isi Tabung Gas",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0030",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Perabot Kantor",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0036",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat/Bahan untuk Kegiatan Kantor Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0043",
            "nama": "Belanja Natura dan Pakan-Natura",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0045",
            "nama": "Belanja Natura dan Pakan-Natura dan Pakan Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0050",
            "nama": "Belanja Persediaan Dalam Proses-Persediaan Dalam Proses",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0053",
            "nama": "Belanja Makanan dan Minuman Jamuan Tamu",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0028 ",
            "nama": "Belanja Jasa Tenaga Pelayanan Umum",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0030 ",
            "nama": "Belanja Jasa Tenaga Kebersihan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0031",
            "nama": "Belanja Jasa Tenaga Keamanan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0033",
            "nama": "Belanja Jasa Tenaga Supir",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0049",
            "nama": "Belanja Jasa Pencucian Pakaian, Alat Kesenian dan Kebudayaan, serta Alat Rumah Tangga",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0063",
            "nama": "Belanja Kawat/Faksimili/Internet/TV Berlangganan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0067",
            "nama": "Belanja Pembayaran Pajak, Bea, dan Perizinan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.12.03",
            "nama": "Penyediaan Kebutuhan Rumah Tangga Sekretariat Daerah",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0010",
            "nama": "Belanja Bahan-Isi Tabung Gas",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0030",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Perabot Kantor",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0036",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat/Bahan untuk Kegiatan Kantor Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0043",
            "nama": "Belanja Natura dan Pakan-Natura",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0045",
            "nama": "Belanja Natura dan Pakan-Natura dan Pakan Lainnya",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.01.01.0053",
            "nama": "Belanja Makanan dan Minuman Jamuan Tamu",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0025",
            "nama": "Belanja Jasa Tenaga Kesenian dan Kebudayaan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0026 ",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0028",
            "nama": "Belanja Jasa Tenaga Pelayanan Umum",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0029",
            "nama": "Belanja Jasa Tenaga Ahli",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0030",
            "nama": "Belanja Jasa Tenaga Kebersihan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0033",
            "nama": "Belanja Jasa Tenaga Supir",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0057",
            "nama": "Belanja Jasa Operator Kapal",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0063",
            "nama": "Belanja Kawat/Faksimili/Internet/TV Berlangganan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.01.0067",
            "nama": "Belanja Pembayaran Pajak, Bea, dan Perizinan",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "5.1.02.04.01.0003",
            "nama": "Belanja Perjalanan Dinas Dalam Kota",
            "bagian": "Bagian Umum"
        },
        {
            "kode": "4.01.01.2.14",
            "nama": "Pelaksanaan Protokol dan Komunikasi Pimpinan",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "4.01.01.2.14.01",
            "nama": "Fasilitasi Keprotokolan",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.01.01.0004",
            "nama": "Belanja Bahan-Bahan Bakar dan Pelumas",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.01.01.0030",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Perabot Kantor",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.01.01.0032",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Perlengkapan Dinas",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.01.01.0035",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Suvenir/Cendera Mata",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.01.01.0043",
            "nama": "Belanja Natura dan Pakan-Natura",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.01.01.0045",
            "nama": "Belanja Natura dan Pakan-Natura",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.01.01.0061",
            "nama": "Belanja Pakaian Sipil Harian (PSH)",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.01.01.0062",
            "nama": "Belanja Pakaian Sipil Lengkap (PSL)",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.01.0028",
            "nama": "Belanja Jasa Tenaga Pelayanan Umum",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.01.0030",
            "nama": "Belanja Jasa Tenaga Kebersihan",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.01.0031",
            "nama": "Belanja Jasa Tenaga Keamanan",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.01.0033",
            "nama": "Belanja Jasa Tenaga Supir",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.01.0059",
            "nama": "Belanja Tagihan Telepon",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.01.0060",
            "nama": "Belanja Tagihan Air",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.01.0061",
            "nama": "Belanja Tagihan Listrik",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.01.0063",
            "nama": "Belanja Kawat/Faksimili/Internet/TV Berlangganan",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.01.0066",
            "nama": "Belanja Registrasi/Keanggotaan",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.01.0067",
            "nama": "Belanja Pembayaran Pajak, Bea, dan Perizinan",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.04.0123",
            "nama": "Belanja Sewa Alat Rumah Tangga Lainnya (Home Use)",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.05.0043",
            "nama": "Belanja Sewa Hotel",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.09.0014",
            "nama": "Belanja Jasa Konsultansi Berorientasi Layanan-Jasa Khusus",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.12.0001",
            "nama": "Belanja Kursus Singkat/Pelatihan",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.03.02.0035",
            "nama": "Belanja Pemeliharaan Alat Angkutan-Alat Angkutan Darat Bermotor-Kendaraan Dinas Bermotor Perorangan",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.03.02.0038",
            "nama": "Belanja Pemeliharaan Alat Angkutan-Alat Angkutan Darat Bermotor-Kendaraan Bermotor Beroda Dua",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.04.01.0002",
            "nama": "Belanja Perjalanan Dinas Tetap",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.04.01.0003",
            "nama": "Belanja Perjalanan Dinas Dalam Kota",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.04.02.0001",
            "nama": "Belanja Perjalanan Dinas Biasa–Luar Negeri",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "4.01.01.2.14.02",
            "nama": "Fasilitasi Komunikasi Pimpinan",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "4.01.01.2.14.03",
            "nama": "Pendokumentasian Tugas Pimpinan",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.01.0028",
            "nama": "Belanja Jasa Tenaga Pelayanan Umum",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.01.0029",
            "nama": "Belanja Jasa Tenaga Ahli",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.01.0055",
            "nama": "Belanja Jasa Iklan/Reklame, Film, dan Pemotretan",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Protokol"
        },
        {
            "kode": "4.01.01.2.13",
            "nama": "Penataan Organisasi",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "4.01.01.2.13.01",
            "nama": "Pengelolaan Kelembagaan dan Analisis Jabatan",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.01.01.0027",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Benda Pos",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.12.0001",
            "nama": "Belanja Kursus Singkat/Pelatihan",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "4.01.01.2.13.02",
            "nama": "Fasilitasi Pelayanan Publik dan Tata Laksana",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.01.01.0027",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Benda Pos",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.01.01.0036",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat/Bahan untuk Kegiatan Kantor Lainnya",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.01.0007",
            "nama": "Honorarium Rohaniwan",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.01.0028",
            "nama": "Belanja Jasa Tenaga Pelayanan Umum",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.01.0046",
            "nama": "Belanja Jasa Konversi Aplikasi/Sistem Informasi",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.01.0055",
            "nama": "Belanja Jasa Iklan/Reklame, Film, dan Pemotretan",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.01.0059",
            "nama": "Belanja Tagihan Telepon",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.01.0062",
            "nama": "Belanja Langganan Jurnal/Surat Kabar/Majalah",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.09.0013",
            "nama": "Belanja Jasa Konsultansi Berorientasi Layanan-Jasa Konsultansi Manajemen",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.09.0014",
            "nama": "Belanja Jasa Konsultansi Berorientasi Layanan-Jasa Khusus",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.12.0001",
            "nama": "Belanja Kursus Singkat/Pelatihan",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.04.01.0003",
            "nama": "Belanja Perjalanan Dinas Dalam Kota",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "4.01.01.2.13.03",
            "nama": "Peningkatan Kinerja dan Reformasi Birokrasi",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.01.01.0027",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Benda Pos",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.01.0046",
            "nama": "Belanja Jasa Konversi Aplikasi/Sistem Informasi",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.09.0014 ",
            "nama": "Belanja Jasa Konsultansi Berorientasi Layanan-Jasa Khusus",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.02.12.0001 ",
            "nama": "Belanja Kursus Singkat/Pelatihan",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.04.01.0003",
            "nama": "Belanja Perjalanan Dinas Dalam Kota",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota",
            "bagian": "Bagian Organisasi"
        },
        {
            "kode": "4.01.02.2.01",
            "nama": "Administrasi Tata Pemerintahan",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "4.01.02.2.01.01",
            "nama": "Penataan Administrasi Pemerintahan",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "4.01.02.2.01.02",
            "nama": "Pengelolaan Administrasi Kewilayahan",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.01.01.0058",
            "nama": "Belanja Makanan dan Minuman Aktivitas Lapangan",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.01.0029",
            "nama": "Belanja Jasa Tenaga Ahli",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.09.0013 ",
            "nama": "Belanja Jasa Konsultansi Berorientasi Layanan-Jasa Konsultansi Manajemen",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.08.0015",
            "nama": "Belanja Jasa Konsultansi Perencanaan Penataan Ruang-Jasa Perencanaan Wilayah",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.09.0014",
            "nama": "Belanja Jasa Konsultansi Berorientasi Layanan-Jasa Khusus",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.03.06.0005",
            "nama": "Belanja Pemeliharaan Aset Tidak Berwujud-Software",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.04.01.0003",
            "nama": "Belanja Perjalanan Dinas Dalam Kota",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "4.01.02.2.01.03",
            "nama": "Fasilitasi Pelaksanaan Otonomi Daerah",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": " Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.01.01.0036",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat/Bahan untuk Kegiatan Kantor Lainnya",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.01.01.0075",
            "nama": "Belanja Pakaian Batik Tradisional",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.01.0025",
            "nama": "Belanja Jasa Tenaga Kesenian dan Kebudayaan",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.01.0046",
            "nama": "Belanja Jasa Konversi Aplikasi/Sistem Informasi",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.01.0029",
            "nama": "Belanja Jasa Tenaga Ahli",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.01.0047",
            "nama": "Belanja Jasa Penyelenggaraan Acara",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.01.0055",
            "nama": "Belanja Jasa Iklan/Reklame, Film, dan Pemotretan",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.09.0014",
            "nama": "Belanja Jasa Konsultansi Berorientasi Layanan-Jasa Khusus",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.04.0022",
            "nama": "Belanja Sewa Electric Generating Set",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.04.0036",
            "nama": "Belanja Sewa Kendaraan Bermotor Penumpang",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.04.0117",
            "nama": "Belanja Sewa Alat Kantor Lainnya",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.04.0123",
            "nama": "Belanja Sewa Alat Rumah Tangga Lainnya (Home Use)",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.04.0132",
            "nama": "Belanja Sewa Peralatan Studio Audio",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.04.0133",
            "nama": "Belanja Sewa Peralatan Studio Video dan Film",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.04.0137",
            "nama": "Belanja Sewa Alat Studio Lainnya",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.04.0364",
            "nama": "Belanja Sewa Photo and Film Equipment",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.02.05.0043",
            "nama": "Belanja Sewa Hotel",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.03.06.0005",
            "nama": "Belanja Pemeliharaan Aset Tidak Berwujud-Software",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.04.01.0003",
            "nama": "Belanja Perjalanan Dinas Dalam Kota",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "5.1.02.04.01.0005",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Luar Kota",
            "bagian": "Bagian Tata Pemerintahan"
        },
        {
            "kode": "4.01.02.2.02",
            "nama": "Pelaksanaan Kebijakan Kesejahteraan Rakyat",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "4.01.02.2.02.01",
            "nama": "Fasilitasi Pengelolaan Bina Mental Spiritual",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0032",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Perlengkapan Dinas",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0035",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Suvenir/Cendera Mata",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0036",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat/Bahan untuk Kegiatan Kantor Lainnya",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0039",
            "nama": "Belanja Barang untuk Dijual/Diserahkan kepada Masyarakat",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0043",
            "nama": "Belanja Natura dan Pakan-Natura",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0045",
            "nama": "Belanja Natura dan Pakan-Natura dan Pakan Lainnya",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0064",
            "nama": "Belanja Pakaian Dinas Lapangan (PDL)",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0075",
            "nama": "Belanja Pakaian Batik Tradisional",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0078",
            "nama": "Belanja Pakaian Jas/Safari",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.01.0027",
            "nama": "Belanja Jasa Tenaga Operator Komputer",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.01.0028",
            "nama": "Belanja Jasa Tenaga Pelayanan Umum",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.01.0029",
            "nama": "Belanja Jasa Tenaga Ahli",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.01.0037",
            "nama": "Belanja Jasa Juri Perlombaan/Pertandingan",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.05.0043 ",
            "nama": "Belanja Sewa Hotel",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.04.0123",
            "nama": "Belanja Sewa Alat Rumah Tangga Lainnya (Home Use)",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.09.0014",
            "nama": "Belanja Jasa Konsultansi Berorientasi Layanan-Jasa Khusus",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.04.01.0003",
            "nama": "Belanja Perjalanan Dinas Dalam Kota",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.04.02.0001",
            "nama": "Belanja Perjalanan Dinas Biasa–Luar Negeri",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.05.01.0001",
            "nama": "Belanja Hadiah yang Bersifat Perlombaan",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.05.01.0002",
            "nama": "Belanja Penghargaan atas Suatu Prestasi",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.05.05.01.0001",
            "nama": "Belanja Hibah Uang kepada Badan dan Lembaga yang Bersifat Nirlaba, Sukarela dan Sosial yang Dibentuk Berdasarkan Peraturan Perundang-Undangan",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.05.05.02.0001",
            "nama": "Belanja Hibah Uang kepada Badan dan Lembaga Nirlaba, Sukarela dan Sosial yang Telah Memiliki Surat Keterangan Terdaftar",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "4.01.02.2.02.02",
            "nama": "Pelaksanaan Kebijakan, Evaluasi, dan Capaian Kinerja Terkait Kesejahteraan Sosial",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.12.0001 ",
            "nama": "Belanja Kursus Singkat/Pelatihan ",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.04.01.0003",
            "nama": "Belanja Perjalanan Dinas Dalam Kota",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "4.01.02.2.02.03",
            "nama": "Pelaksanaan Kebijakan, Evaluasi, dan Capaian Kinerja Terkait Kesejahteraan Masyarakat",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.01.0037",
            "nama": "Belanja Jasa Juri Perlombaan/Pertandingan",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota ",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "5.1.02.05.01.0001",
            "nama": "Belanja Hadiah yang Bersifat Perlombaan",
            "bagian": "Bagian Kesejahteraan Masyarakat"
        },
        {
            "kode": "4.01.02.2.03",
            "nama": "Fasilitasi dan Koordinasi Hukum",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "4.01.02.2.03.01",
            "nama": "Fasilitasi Penyusunan Produk Hukum Daerah",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.01.01.0043",
            "nama": "Belanja Natura dan Pakan-Natura",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.01.0027",
            "nama": "Belanja Jasa Tenaga Operator Komputer",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.09.0014",
            "nama": "Belanja Jasa Konsultansi Berorientasi Layanan-Jasa Khusus",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.03.06.0009",
            "nama": "Belanja Pemeliharaan Aset Tidak Berwujud-Aset Tidak Berwujud Lainnya",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.04.01.0003",
            "nama": "Belanja Perjalanan Dinas Dalam Kota",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.2.05.08.01.0009",
            "nama": "Belanja Modal Aset Tidak Berwujud Lainnya",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "4.01.02.2.03.02",
            "nama": "Fasilitasi Bantuan Hukum",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.01.01.0012",
            "nama": "Belanja Bahan-Bahan Lainnya",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.01.01.0027",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Benda Pos",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.01.01.0036",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat/Bahan untuk Kegiatan Kantor Lainnya",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.01.01.0058",
            "nama": "Belanja Makanan dan Minuman Aktivitas Lapangan",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.01.0027",
            "nama": "Belanja Jasa Tenaga Operator Komputer",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.01.0029",
            "nama": "Belanja Jasa Tenaga Ahli",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.01.0047",
            "nama": "Belanja Jasa Penyelenggaraan Acara",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.09.0014",
            "nama": "Belanja Jasa Konsultansi Berorientasi Layanan-Jasa Khusus",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.04.01.0003",
            "nama": "Belanja Perjalanan Dinas Dalam Kota",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.05.02.0002",
            "nama": "Belanja Jasa yang Diberikan kepada Masyarakat",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.05.05.01.0001",
            "nama": "Belanja Hibah Uang kepada Badan dan Lembaga yang Bersifat Nirlaba, Sukarela dan Sosial yang Dibentuk Berdasarkan Peraturan Perundang-Undangan",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.06.01.01.0001",
            "nama": "Belanja Bantuan Sosial Uang yang Direncanakan kepada Individu",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "4.01.02.2.03.03",
            "nama": "Pendokumentasian Produk Hukum dan Pengelolaan Informasi Hukum",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "4.01.02.2.03.02.5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.01.0027",
            "nama": "Belanja Jasa Tenaga Operator Komputer",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.01.0029",
            "nama": "Belanja Jasa Tenaga Ahli",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.01.0039",
            "nama": "Belanja Jasa Tenaga Informasi dan Teknologi",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.02.09.0014",
            "nama": "Belanja Jasa Konsultansi Berorientasi Layanan-Jasa Khusus",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.03.06.0009",
            "nama": "Belanja Pemeliharaan Aset Tidak Berwujud-Aset Tidak Berwujud Lainnya",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.04.01.0003",
            "nama": "Belanja Perjalanan Dinas Dalam Kota",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota",
            "bagian": "Bagian Hukum"
        },
        {
            "kode": "4.01.02.2.04",
            "nama": "Fasilitasi Kerja Sama Daerah",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "4.01.02.2.04.01",
            "nama": "Fasilitasi Kerja Sama Dalam Negeri",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0027",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Benda Pos",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0032",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Perlengkapan Dinas",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0035",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Suvenir/Cendera Mata",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0036",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat/Bahan untuk Kegiatan Kantor Lainnya",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0043",
            "nama": "Belanja Natura dan Pakan-Natura",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0053",
            "nama": "Belanja Makanan dan Minuman Jamuan Tamu",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0074",
            "nama": "Belanja Pakaian Adat Daerah",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0075",
            "nama": "Belanja Pakaian Batik Tradisional",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0007",
            "nama": "Honorarium Rohaniwan",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0017",
            "nama": "Belanja Jasa Tenaga Ketenteraman, Ketertiban Umum, dan Perlindungan Masyarakat",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0008",
            "nama": "Honorarium Tim Penyusunan Jurnal, Buletin, Majalah, Pengelola Teknologi Informasi dan Pengelola Website",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0025",
            "nama": "Belanja Jasa Tenaga Kesenian dan Kebudayaan",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0028",
            "nama": "Belanja Jasa Tenaga Pelayanan Umum",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0029",
            "nama": "Belanja Jasa Tenaga Ahli",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0039",
            "nama": "Belanja Jasa Tenaga Informasi dan Teknologi",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0047",
            "nama": "Belanja Jasa Penyelenggaraan Acara",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0048",
            "nama": "Belanja Jasa Kontribusi Asosiasi",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0055",
            "nama": "Belanja Jasa Iklan/Reklame, Film, dan Pemotretan",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0064",
            "nama": "Belanja Paket/Pengiriman",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.04.0022",
            "nama": "Belanja Sewa Electric Generating Set",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.04.0117",
            "nama": "Belanja Sewa Alat Kantor Lainnya",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.04.0036",
            "nama": "Belanja Sewa Kendaraan Bermotor Penumpang",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.04.0045",
            "nama": "Belanja Sewa Kendaraan Tak Bermotor Penumpang",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.04.0049",
            "nama": "Belanja Sewa Alat Angkutan Apung Bermotor untuk Penumpang",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.04.0121",
            "nama": "Belanja Sewa Alat Pendingin",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.04.0123",
            "nama": "Belanja Sewa Alat Rumah Tangga Lainnya (Home Use)",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.04.0133",
            "nama": "Belanja Sewa Peralatan Studio Video dan Film",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.04.0137",
            "nama": "Belanja Sewa Alat Studio Lainnya ",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.04.0494",
            "nama": "Belanja Sewa Rambu-Rambu Lalu Lintas Darat Lainnya",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.05.0030",
            "nama": "Belanja Sewa Bangunan Gedung Tempat Kerja Lainnya",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.05.0037",
            "nama": "Belanja Sewa Bangunan Gedung Tempat Kerja Lainnya",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.05.0043",
            "nama": "Belanja Sewa Hotel",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.09.0014",
            "nama": "Belanja Jasa Konsultansi Berorientasi Layanan-Jasa Khusus",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.03.06.0009",
            "nama": "Belanja Pemeliharaan Aset Tidak Berwujud-Aset Tidak Berwujud Lainnya",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.03.02.0117",
            "nama": "Belanja Pemeliharaan Alat Kantor dan Rumah Tangga-Alat Kantor-Alat Kantor Lainnya",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "4.01.02.2.04.02",
            "nama": "Fasilitasi Kerja Sama Luar Negeri",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0027",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Benda Pos",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0035",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Suvenir/Cendera Mata",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0036",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat/Bahan untuk Kegiatan Kantor Lainnya",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0075",
            "nama": "Belanja Pakaian Batik Tradisional",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0007",
            "nama": "Honorarium Rohaniwan",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0028",
            "nama": "Belanja Jasa Tenaga Pelayanan Umum",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0029",
            "nama": "Belanja Jasa Tenaga Ahli",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0043",
            "nama": "Belanja Jasa Penulisan dan Penerjemahan",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0047",
            "nama": "Belanja Jasa Penyelenggaraan Acara",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0048",
            "nama": "Belanja Jasa Kontribusi Asosiasi",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0064",
            "nama": "Belanja Paket/Pengiriman",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.04.0022",
            "nama": "Belanja Sewa Electric Generating Set",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.04.0036",
            "nama": "Belanja Sewa Kendaraan Bermotor Penumpang",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.04.0117",
            "nama": "Belanja Sewa Alat Kantor Lainnya",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.04.0123",
            "nama": "Belanja Sewa Alat Rumah Tangga Lainnya (Home Use)",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.04.0133",
            "nama": "Belanja Sewa Peralatan Studio Video dan Film",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.04.0137 ",
            "nama": "Belanja Sewa Alat Studio Lainnya",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.05.0030",
            "nama": "Belanja Sewa Bangunan Gedung Tempat Kerja Lainnya",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.05.0037",
            "nama": "Belanja Sewa Bangunan Gedung Tempat Kerja Lainnya",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.05.0043",
            "nama": "Belanja Sewa Hotel",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.12.0001",
            "nama": "Belanja Kursus Singkat/Pelatihan",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.04.02.0001",
            "nama": "Belanja Perjalanan Dinas Biasa–Luar Negeri",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "4.01.02.2.04.03",
            "nama": "Evaluasi Pelaksanaan Kerja Sama",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0027",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Benda Pos",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0036",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat/Bahan untuk Kegiatan Kantor Lainnya",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0029",
            "nama": "Belanja Jasa Tenaga Ahli",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.01.0064",
            "nama": "Belanja Paket/Pengiriman",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.02.12.0001",
            "nama": " Belanja Kursus Singkat/Pelatihan ",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.04.01.0002",
            "nama": "Belanja Perjalanan Dinas Tetap",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.1.02.04.01.0003",
            "nama": "Belanja Perjalanan Dinas Dalam Kota",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.2.02.05.02.0006",
            "nama": "Belanja Modal Alat Rumah Tangga Lainnya (Home Use)",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "5.2.02.10.02.0003",
            "nama": "Belanja Modal Peralatan Personal Computer",
            "bagian": "Bagian Kerjasama"
        },
        {
            "kode": "4.01.03.2.01",
            "nama": "Pelaksanaan Kebijakan Perekonomian",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "4.01.03.2.01.01",
            "nama": "Koordinasi, Sinkronisasi, Monitoring dan Evaluasi Kebijakan Pengelolaan BUMD dan BLUD",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.01.01.0036",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat/Bahan untuk Kegiatan Kantor Lainnya",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.01.01.0058",
            "nama": "Belanja Makanan dan Minuman Aktivitas Lapangan",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.01.0007",
            "nama": "Honorarium Rohaniwan",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.01.0009",
            "nama": "Honorarium Penyelenggara Ujian",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.01.0027",
            "nama": "Belanja Jasa Tenaga Operator Komputer",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.01.0029",
            "nama": "Belanja Jasa Tenaga Ahli",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.01.0055",
            "nama": "Belanja Jasa Iklan/Reklame, Film, dan Pemotretan",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.09.0013",
            "nama": "Belanja Jasa Konsultansi Berorientasi Layanan-Jasa Konsultansi Manajemen",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.12.0001",
            "nama": "Belanja Kursus Singkat/Pelatihan",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.12.0004",
            "nama": "Belanja Diklat Kepemimpinan",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.04.01.0003",
            "nama": "Belanja Perjalanan Dinas Dalam Kota",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "4.01.03.2.01.02",
            "nama": "Pengendalian dan Distribusi Perekonomian",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Cetak",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.01.01.0036",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat/Bahan untuk Kegiatan Kantor Lainnya",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.01.01.0058",
            "nama": "Belanja Makanan dan Minuman Aktivitas Lapangan",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.01.0007",
            "nama": "Honorarium Rohaniwan",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.04.01.0003",
            "nama": "Belanja Perjalanan Dinas Dalam Kota",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "4.01.03.2.01.03",
            "nama": "Perencanaan dan Pengawasan Ekonomi Mikro Kecil",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.01.01.0036",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat/Bahan untuk Kegiatan Kantor Lainnya",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.01.01.0058",
            "nama": "Belanja Makanan dan Minuman Aktivitas Lapangan",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.01.0007",
            "nama": "Honorarium Rohaniwan",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.02.09.0013",
            "nama": "Belanja Jasa Konsultansi Berorientasi Layanan-Jasa Konsultansi Manajemen",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.04.01.0003",
            "nama": "Belanja Perjalanan Dinas Dalam Kota",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota",
            "bagian": "Bagian Perekonomian"
        },
        {
            "kode": "4.01.03.2.02",
            "nama": "Pelaksanaan Administrasi Pembangunan",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "4.01.03.2.02.0001 ",
            "nama": "\r\nFasilitasi Penyusunan Program Pembangunan\r\n",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0004",
            "nama": "Belanja Bahan-Bahan Bakar dan Pelumas ",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0026 ",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0031",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Listrik",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0035",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Suvenir/Cendera Mata",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0058",
            "nama": "Belanja Makanan dan Minuman Aktivitas Lapangan",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.01.0027",
            "nama": "Belanja Jasa Tenaga Operator Komputer",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.01.0029",
            "nama": "Belanja Jasa Tenaga Ahli",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.01.0060",
            "nama": "Belanja Tagihan Air",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.01.0061",
            "nama": "Belanja Tagihan Listrik",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.01.0063",
            "nama": " Belanja Kawat/Faksimili/Internet/TV Berlangganan",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.05.0030",
            "nama": " Belanja Sewa Bangunan Gedung Tempat Kerja Lainnya ",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.05.0050",
            "nama": "Belanja Sewa Bangunan Gedung Tempat Tinggal Lainnya",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.09.0014",
            "nama": "Belanja Jasa Konsultansi Berorientasi Layanan-Jasa Khusus",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.12.0001",
            "nama": "Belanja Kursus Singkat/Pelatihan",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.03.02.0035 ",
            "nama": " Belanja Pemeliharaan Alat Angkutan-Alat Angkutan Darat Bermotor-Kendaraan Dinas Bermotor Perorangan ",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.03.02.0121 ",
            "nama": "Belanja Pemeliharaan Alat Kantor dan Rumah Tangga-Alat Rumah Tangga-Alat Pendingin ",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.04.01.0003",
            "nama": "Belanja Perjalanan Dinas Dalam Kota",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.04.02.0001",
            "nama": "Belanja Perjalanan Dinas Biasa–Luar Negeri",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.2.02.02.01.0002",
            "nama": "Belanja Modal Kendaraan Bermotor Penumpang",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.2.02.05.01.0004",
            "nama": "Belanja Modal Alat Penyimpan Perlengkapan Kantor",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.2.02.05.01.0005",
            "nama": "Belanja Modal Alat Kantor Lainnya",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.2.02.05.02.0006",
            "nama": "Belanja Modal Alat Rumah Tangga Lainnya (Home Use) ",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.2.02.05.03.0001",
            "nama": "Belanja Modal Meja Kerja Pejabat",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.2.02.05.03.0003",
            "nama": "Belanja Modal Kursi Kerja Pejabat ",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.2.02.10.01.0002",
            "nama": "Belanja Modal Personal Computer",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.2.02.10.02.0003",
            "nama": "Belanja Modal Peralatan Personal Computer",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "4.01.03.2.02.02",
            "nama": "Pengendalian dan Evaluasi Program Pembangunan",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": " Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0031 ",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Listrik",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0043",
            "nama": "Belanja Natura dan Pakan-Natura",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0058",
            "nama": "Belanja Makanan dan Minuman Aktivitas Lapangan",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.04.01.0003",
            "nama": "Belanja Perjalanan Dinas Kota",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "4.01.03.2.02.03",
            "nama": "Pengelolaan Evaluasi dan Pelaporan Pelaksanaan Pembangunan",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0031",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Listrik",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.01.01.0058",
            "nama": "Belanja Makanan dan Minuman Aktivitas Lapangan",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.02.12.0001",
            "nama": "Belanja Kursus Singkat/Pelatihan",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "5.1.02.04.01.0003",
            "nama": " Belanja Perjalanan Dinas Dalam Kota ",
            "bagian": "Bagian Administrasi Pembangunan"
        },
        {
            "kode": "4.01.03.2.03",
            "nama": "Pengelolaan Pengadaan Barang dan Jasa",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "4.01.03.2.03.01",
            "nama": "Pengelolaan Pengadaan Barang dan Jasa",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "2 Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.01.01.0058",
            "nama": "Belanja Makanan dan Minuman Aktivitas Lapangan",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.03.02.0405 ",
            "nama": "Belanja Pemeliharaan Komputer-Komputer Unit-Personal Computer",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.04.01.0003",
            "nama": "Belanja Perjalanan Dinas Dalam Kota",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.2.02.10.01.0002",
            "nama": "Belanja Modal Personal Computer",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "4.01.03.2.03.02",
            "nama": "Pengelolaan Layanan Pengadaan Secara Elektronik",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.01.01.0031",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Listrik",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.01.0016",
            "nama": "Belanja Jasa Tenaga Penanganan Prasarana dan Sarana Umum",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.01.0029",
            "nama": "Belanja Jasa Tenaga Ahli",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.01.0046",
            "nama": "Belanja Jasa Konversi Aplikasi/Sistem Informasi",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.01.0063",
            "nama": "Belanja Kawat/Faksimili/Internet/TV Berlangganan",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.01.0064",
            "nama": "Belanja Paket/Pengiriman",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.09.0003",
            "nama": "Belanja Jasa Konsultansi Berorientasi Bidang-Telematika",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.09.0014",
            "nama": "Belanja Jasa Konsultansi Berorientasi Layanan-Jasa Khusus",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.10.0008",
            "nama": "Belanja Jasa Ketersediaan Layanan (Availibility Payment) Infrastruktur Telekomunikasi dan Informatika",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.15.0002",
            "nama": "Belanja Sewa Aset Tidak Berwujud-Lisensi dan Franchise",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.03.02.0404",
            "nama": "Belanja Pemeliharaan Komputer-Komputer Unit-Komputer Jaringan",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.2.02.05.02.0006",
            "nama": "Belanja Modal Alat Rumah Tangga Lainnya (Home Use)",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.2.02.08.04.0005",
            "nama": "Belanja Modal System/Power Supply",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.2.02.10.01.0002",
            "nama": "Belanja Modal Personal Computer",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.2.02.10.02.0001",
            "nama": "Belanja Modal Peralatan Mainframe",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.2.02.10.02.0003",
            "nama": "Belanja Modal Peralatan Personal Computer",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.2.02.10.02.0004",
            "nama": "Belanja Modal Peralatan Jaringan",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.2.02.10.02.0005",
            "nama": "Belanja Modal Peralatan Komputer Lainnya",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.2.05.08.01.0002",
            "nama": "Belanja Modal Lisensi dan Franchise",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.2.05.08.01.0005",
            "nama": "Belanja Modal Software",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "4.01.03.2.03.03",
            "nama": "Pembinaan dan Advokasi Pengadaan Barang dan Jasa",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.01.01.0024",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Tulis Kantor",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.01.01.0025",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Kertas dan Cover",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.01.01.0026",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor- Bahan Cetak",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.01.01.0029",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Bahan Komputer",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.01.01.0031",
            "nama": "Belanja Alat/Bahan untuk Kegiatan Kantor-Alat Listrik",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Rapat",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.01.01.0052",
            "nama": "Belanja Makanan dan Minuman Aktivitas Lapangan",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.01.0003",
            "nama": "Honorarium Narasumber atau Pembahas, Moderator, Pembawa Acara, dan Panitia",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.01.0004",
            "nama": "Honorarium Tim Pelaksana Kegiatan dan Sekretariat Tim Pelaksana Kegiatan",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.01.0011",
            "nama": "Honorarium Penyelenggaraan Kegiatan Pendidikan dan Pelatihan",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.01.0026",
            "nama": "Belanja Jasa Tenaga Administrasi",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.01.0028",
            "nama": "Belanja Jasa Tenaga Pelayanan Umum",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.01.0029",
            "nama": "Belanja Jasa Tenaga Ahli",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.01.0046",
            "nama": "Belanja Jasa Konversi Aplikasi/Sistem Informasi",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.02.0005",
            "nama": "Belanja Iuran Jaminan Kesehatan bagi Non ASN",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.02.0006",
            "nama": "Belanja Iuran Jaminan Kecelakaan Kerja bagi Non ASN",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.02.0007",
            "nama": "Belanja Iuran Jaminan Kematian bagi Non ASN",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.05.0002 ",
            "nama": "Belanja Sewa Bangunan Gudang",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.09.0003",
            "nama": "Belanja Jasa Konsultansi Berorientasi Bidang-Telematika",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.09.0014",
            "nama": "Belanja Jasa Konsultansi Berorientasi Layanan-Jasa Khusus",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.02.12.0001",
            "nama": "Belanja Kursus Singkat/Pelatihan",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.04.01.0001",
            "nama": "Belanja Perjalanan Dinas Biasa",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.04.01.0003",
            "nama": "Belanja Perjalanan Dinas Dalam Kota",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        },
        {
            "kode": "5.1.02.04.01.0004",
            "nama": "Belanja Perjalanan Dinas Paket Meeting Dalam Kota",
            "bagian": "Bagian Pengadaan Barang/Jasa"
        }
    ]

}
export default StaticData


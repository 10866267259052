import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { apiWithParams, getApi, postApiWithFile } from '../../../connection/Api'
import StaticData from '../../../connection/StaticData'
import Select from '../../../container/inputs/Select'
import PinManagement from '../../../container/mix/PinManagement'

const EditUser = () => {
    const { id } = useParams()
    const [role, setRole] = useState([])
    const [position, setPosition] = useState([])
    const [user, setUser] = useState([])
    const [ready, setReady] = useState(false)
    const [profileImage, setProfileImage] = useState()
    const [formUser, setFormUser] = useState()
    const navigate = useNavigate()
    const fileInputRef = useRef(null);
    const staticData = new StaticData()

    const getData = async () => {
        const getUser = await apiWithParams('user/get-by-id', id)
        setUser(getUser.data)
        setProfileImage(`${staticData.url}avatar/${getUser.data.avatar}`)

        let role = await getApi('user/role')
        // setRole(role.data)

        let mapPosition = []
        let position = await getApi('form/all-position')
        await position.data.map(element => {
            mapPosition.push({
                id: element.id,
                name: element.position_name
            })

        })
        setPosition(mapPosition)

        setFormUser([
            {
                label: 'Nama',
                name: 'name',
                type: 'text',
                id: 'name',
                value: getUser.data.name,
            },
            {
                label: 'PIN',
                name: 'pin',
                type: 'pin',
                id: 'pin',
                value: getUser.data.name,
            },
            {
                label: 'Email',
                name: 'email',
                type: 'email',
                id: 'email',
                value: getUser.data.email
            },
            {
                label: 'Role',
                name: 'role',
                type: 'option',
                id: 'role',
                data: role.data,
                value: getUser.data.role.id
            },
            {
                label: 'Posisi',
                name: 'position[]',
                type: 'checkbox',
                id: 'position',
                data: mapPosition,
                value: getUser.data.positions
            },
        ])

        setReady(true)
    }

    const submitFormUser = async (e) => {
        e.preventDefault()
        var form = new FormData(e.target)

        let inputFile = formUser.filter((item) => item.type === 'file')

        inputFile.map((item) => {
            var files = document.getElementById(item.id).files
            if (files) {
                form.append(item.name, files[0])
            }
        })

        form.append('id', id)

        let resultPost = await postApiWithFile('user/edit', form)

        console.log(resultPost)

        let textErr = document.getElementsByClassName('text-err')
        for (let i = 0; i < textErr.length; i++) {
            textErr[i].textContent = ""
        }

        if (resultPost.status === 'Error') {
            document.querySelector(`div[name=${resultPost.field}]`).textContent = resultPost.message
        }

        if (resultPost.status === "Success") {
            navigate('/dashboard/user', { replace: true })
        }
    }
    useEffect(() => {
        getData()
    }, [])


    if (ready === false) {
        return (
            <></>
        )
    } else {
        return (
            <div className='lg:grid lg:grid-cols-12'>
                <div className='lg:col-span-8'>
                    <form onSubmit={(e) => { submitFormUser(e) }} encType="multipart/form-data" method='POST'>
                        <div className='my-5'>
                            <div className='flex flex-auto justify-center items-center my-5'>
                                <div className='w-40 h-40 relative '>
                                    <img src={profileImage} alt="" className='w-full h-full rounded-full border-2 border-default' onClick={() => fileInputRef.current.click()} />
                                    <input name="avatar" id="avatar" ref={fileInputRef} type="file" className='hidden' accept='.png, .jpg, .jpeg' onChange={(e) => setProfileImage(URL.createObjectURL(e.target.files[0]))} />
                                    <div className='absolute bottom-4 end-2 rounded-full bg-default flex flex-auto justify-center items-center'>
                                        <i className='fa-solid fa-image text-white m-2'></i>
                                    </div>
                                    <div className='text-red-500' name="avatar"></div>
                                </div>
                            </div>
                        </div>
                        <div className='p-4 rounded-xl bg-white flex flex-col gap-4'>
                            {formUser.map(element => {
                                if (element.type === 'option') {
                                    return (
                                        <div className='mb-3'>
                                            <div className='mb-1'>
                                                <label htmlFor={element.id} className="form-label">{element.label}</label>
                                            </div>
                                            <div className='flex flex-auto'>
                                                <Select name={element.name} option={element.data} optionKeyName={'name'} optionKeyValue={'id'} selectedValue={element.value} firstOptionText={`-- Pilih ${element.lebel} --`} customTailwind='w-full' tailwindTheme='dark' selectCustomClass={'border-0'} />
                                            </div>
                                            <div className='text-danger text-err' name={element.name}></div>
                                        </div>
                                    )
                                } else if (element.type === "pin") {
                                    return (
                                        <PinManagement user={user} setUser={setUser}/>
                                    )
                                } else if (element.type === "checkbox") {
                                    return (
                                        <div className='flex flex-col mb-3'>
                                            <div className='mb-1'>
                                                <span className='form-label mb-2'>{element.label}</span>
                                            </div>
                                            <div className="lg:grid lg:grid-cols-12">
                                                {element.data.map((data) => {
                                                    let userPosition
                                                    if (user.positions != null) {
                                                        userPosition = user.positions.filter((position) => position.id === data.id)
                                                    }
                                                    return (
                                                        <div className="lg:col-span-6">
                                                            <input className="rounded-md mr-2" type={element.type} id={data.id} multiple name={element.name} value={data.id} defaultChecked={userPosition.length > 0 && true} />
                                                            <label htmlFor={data.id}>{data.name}</label>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className='text-danger text-err' name={element.id}></div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="flex flex-col">
                                            <div className='mb-1'>
                                                <label htmlFor={element.id} className="form-label">{element.label}</label>
                                            </div>
                                            <div className='flex flex-auto'>
                                                <input defaultValue={element.value} type={element.type} name={element.name} className="w-full rounded-md border-2 border-dark" id={element.id} accept={element.accept && element.accept} />
                                                <div className='text-danger text-err' name={element.name}></div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                            <div className='flex flex-auto gap-2'>
                                <button id="btn-submit-user" type="submit" className="bg-default px-5 py-2 rounded-xl text-white">Simpan</button>
                                <Link className='bg-gray-200 px-5 py-2 rounded-xl text-dark' to="/dashboard/user">Cancel</Link>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='lg:col-span-4'>

                </div>
            </div>
        )
    }

}

export default EditUser

// import ServerError from '../screens/user/ServerError'
import StaticData from './StaticData'
const staticData = new StaticData()

export function getToken() {
    return new Promise(resolve => {
        let dataLogin = {
            pin: localStorage.getItem('pin'),
        }
    
        let urlLogin = staticData.baseUrl + 'login'
        let options = {
            method: 'POST',
            body: JSON.stringify(dataLogin),
            headers: {
                'Content-Type': 'Application/json'
            }
        }
    
        fetch(urlLogin, options)
            .then((response) => {
                if (!response.ok) {
                    console.log(response)
                    resolve(response.json()) 
                }
                return response.json() 
            })
            .then((result) => {
                if (result.data.access_token) {
                    localStorage.clear();
                    let position = ""
                    let x = 1
                    localStorage.setItem('pin', dataLogin.pin)
                    localStorage.setItem('id', result.data.id)
                    localStorage.setItem('name', result.data.name)
                    localStorage.setItem('token', result.data.access_token)
                    localStorage.setItem('avatar', result.data.avatar)
                    localStorage.setItem('role', result.data.role)
                    localStorage.setItem('position', JSON.stringify(result.data.positions))
                    resolve("success") 
                } else {
                    console.log(result)
                    resolve('error') 
                }
            })
            .catch((err) => {
                console.log(err)
            })
    })
}

export function postApi(url, data) {
    return new Promise(resolve => {
        const apiUrl = staticData.baseUrl + url
        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            }
        }
        fetch(apiUrl, options)
            .then( async (response) => {
                let result = response.json()
                if (response.status === 200) {
                    return result
                } else if (response.status === 401) {
                    const gettoken = await getToken()
                    console.log(gettoken)
                    const reload = await postApi(url, data)
                    return reload
                } else if (response.status === 400) {
                    console.log(result)
                    return result
                } else {
                    console.log(response)
                    throw response
                }
            })
            .then((data) => {
                resolve(data)
            })
            .catch((err) => {
                console.log(err)
                resolve("error")
            })
    })
}
export function postApiWithFile(url, data) {
    return new Promise(resolve => {
        const apiUrl = staticData.baseUrl + url
        let options = {
            method: 'POST',
            body: data,
            processData: false,
            contentType: false,
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('token'),
            }
        }
        fetch(apiUrl, options)
            .then( async (response) => {
                let result = response.json()
                if (response.status === 200) {
                    return result
                } else if (response.status === 401) {
                    const gettoken = await getToken()
                    console.log(gettoken)
                    const reload = await postApiWithFile(url, data)
                    return reload
                } else if (response.status === 400) {
                    console.log(result)
                    return result
                } else {
                    console.log(response)
                    throw response
                }
            })
            .then((data) => {
                resolve(data)
            })
            .catch((err) => {
                console.log(err)
                resolve(err)
            })
        
    })
}

export function getApi(url) {
    return new Promise(resolve => {
        const apiUrl = staticData.baseUrl + url
        let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': "Bearer " + localStorage.getItem('token')
                },
            }
            
        fetch(apiUrl, options)
            .then( async (response) => {
                let result = response.json()
                if (response.status === 200) {
                    return result
                } else if (response.status === 401) {
                    const gettoken = await getToken()
                    console.log(gettoken)
                    const reload = await getApi(url)
                    return reload
                } else {
                    console.log(response)
                    throw response
                }
            })
            .then((data) => {
                resolve(data)
            })
            .catch((err) => {
                console.log(err)
                resolve(err)
            })
    })
}

export function getFile(url, param) {
    return new Promise(resolve => {
        const apiUrl = staticData.baseUrl + url + '/' + param
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': "Bearer " + localStorage.getItem('token'),
            },
            responseType: "blob",
        } 
        fetch(apiUrl, options)
            .then(async (response) => {
                if (response.status === 200) {
                    response.blob().then(blob => {
                        const fileURL = window.URL.createObjectURL(blob);
                        let alink = document.createElement('a');
                        alink.href = fileURL;
                        alink.download = 'file.pdf';
                        alink.click();
                    })
                } else if (response.status === 401) {
                    const gettoken = await getToken()
                    console.log(gettoken)
                    const reload = await getFile(url, param)
                    return reload
                } else {
                    console.log(response)
                    throw response 
                }
            })
            .catch((err) => {
                console.log(err)
                resolve(err)
            })
    })
}

export function getApiWithParams(url, params) {
    return new Promise(resolve => {
        const apiUrl = staticData.baseUrl + url + '?' + params
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            }
        }
            
        fetch(apiUrl, options)
            .then( async (response) => {
                let result = response.json()
                if (response.status === 200) {
                    return result
                } else if (response.status === 401) {
                    const gettoken = await getToken()
                    console.log(gettoken)
                    const reload = await getApiWithParams(url, params)
                        // console.log(reg)
                    return reload
                } else {
                    throw response
                }
            })
            .then((data) => {
                resolve(data)
            })
            .catch((err) => {
                resolve(err)
            })
    })
}

export function apiWithParams(url, params) {
    return new Promise(resolve => {
        const apiUrl = staticData.baseUrl + url + '/' + params
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            }
        }
            
        fetch(apiUrl, options)
            .then( async (response) => {
                let result = response.json()
                if (response.status === 200) {
                    return result
                } else if (response.status === 401) {
                    const gettoken = await getToken()
                    console.log(gettoken)
                    const reload = await apiWithParams(url, params)
                    return reload
                } else {
                    console.log(response)
                    throw response
                }
            })
            .then((data) => {
                resolve(data)
            })
            .catch((err) => {
                console.log(err)
                resolve(err)
            })
    })
}
export function apiPublicWithParams(url, params) {
    return new Promise(resolve => {
        const apiUrl = staticData.baseUrl + url + '/' + params
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
            }
        }
            
        fetch(apiUrl, options)
            .then( async (response) => {
                let result = response.json()
                if (response.status === 200) {
                    return result
                } else {
                    console.log(response)
                    throw response
                }
            })
            .then((data) => {
                resolve(data)
            })
            .catch((err) => {
                console.log(err)
                resolve(err)
        })
    })
}

export function getApiPublic(url){
    return new Promise(resolve => {
        const apiUrl = staticData.baseUrl + url
        let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'Application/json',
                }
            }
            
        fetch(apiUrl, options)
            .then( async (response) => {
                let result = response.json()
                if (response.status === 200) {
                    return result
                } else {
                    console.log(response)
                    throw response
                }
            })
            .then((data) => {
                resolve(data)
            })
            .catch((err) => {
                console.log(err)
                resolve(err)
        })
    })
}
export function postApiPublic(url, data) {
    return new Promise(resolve => {
        const apiUrl = staticData.baseUrl + url
        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'Application/json',
            }
        }
        fetch(apiUrl, options)
            .then( async (response) => {
                let result = response.json()
                if (response.status === 200) {
                    return result
                } else if (response.status === 400) {
                    console.log(result)
                    return result
                } else if (response.status === 500) { 
                    console.log(response)
                } else {
                    console.log(response)
                    throw response
                }
            })
            .then((data) => {
                resolve(data)
            })
            .catch((err) => {
                console.log(err)
                resolve(err)
            })
    })
}

export function getDataWithParams(url, params){
    return new Promise(resolve => {
        const apiUrl = staticData.baseUrl + url + params
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            }
        }
            
        fetch(apiUrl, options)
            .then( async (response) => {
                let result = response.json()
                if (response.status === 200) {
                    return result
                } else if (response.status === 401) {
                    const gettoken = await getToken()
                    console.log(gettoken)
                    const reload = await getApiWithParams(url, params)
                        // console.log(reg)
                    return reload
                } else {
                    throw response
                }
            })
            .then((data) => {
                resolve(data)
            })
            .catch((err) => {
                resolve(err)
            })
    })
}
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getApi, postApi } from '../../../connection/Api'
import CardDataDocument from '../../../container/card/CardDataDocument'
import DocumentCard from '../../../container/card/DocumentCard'
import InputOptionWithDesc from '../../../container/inputs/InputOptionWithDesc'
import TailwindModal from '../../../container/modal/TailwindModal'
import { useAllStatusTrack, useAllTrack, usePosition } from '../../../zustand/Index'
import ButtonPrimary from '../../../component/buttons/ButtonPrimary'

const DocumentValidation = () => {
    const [loading, setLoading] = useState(true)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [doc, setDoc] = useState()
    const [formAvailibility, setFormAvailibility] = useState([])
    const [documentAvailibility, setDocumentAvailibilty] = useState()
    const { position, setPosition } = usePosition()
    const { allTrack, setAllTrack } = useAllTrack()
    const { allStatusTrack, setAllStatusTrack } = useAllStatusTrack()
    const [showSuccess, setShowSuccess] = useState(false)
    const { id } = useParams()
    const getData = async () => {
        setLoading(true)
        let resultDoc = await getApi(`document-number/get-by-id?id=${id}`)
        setDoc(resultDoc.data)

        let resultPosition = await getApi('form/all-position')
        setPosition(resultPosition.data)

        let resultAllTrack = await getApi('document/all-track')
        setAllTrack(resultAllTrack.data)

        let resultAllStatusTrack = await getApi('document/all-status-track')
        setAllStatusTrack(resultAllStatusTrack.data)
        const resultForm = await getApi(`document/get-availibility-file-with-form/${resultDoc.data.jenis_ls_id}/${resultDoc.data.id}`)
        setFormAvailibility(resultForm.data)

        setLoading(false)
    }
    const submitValidation = async (e) => {
        setLoadingSubmit(true)
        e.preventDefault()
        var form = new FormData(e.target)
        form.append('document_number', doc.number)
        var data = Object.fromEntries(form.entries())
        const postAvailibility = await postApi('form/post-availibility-file', data)
        setLoadingSubmit(false)
        setShowSuccess(true)
    }
    useEffect(() => {
        console.log(localStorage.getItem('avatar'))
        getData()
    }, [])

    return (
        <div>
            {loading === false ? (
                <div className='lg:grid lg:grid-cols-12 gap-2'>
                    <div className='lg:col-span-4 mb-2 lg:mb-0'>
                        <div className='sticky top-2'>
                            <div className='mb-2'>
                                <DocumentCard position={position} allTrack={allTrack} allStatusTrack={allStatusTrack} item={doc} setDoc={(value) => setDoc(value)} showValidasiButton={false} />
                            </div>
                            <div className="my-2">
                                <CardDataDocument documentNumber={doc} documentNumberId={doc.id}/>
                            </div>
                        </div>
                    </div>
                    <div className='lg:col-span-8'>
                        <div className='bg-white p-4 rounded-xl'>
                            <center>
                                <span className='font-bold text-xl'>Validasi Dokumen</span>
                            </center>
                            <form onSubmit={(e) => {
                                submitValidation(e)
                            }}>
                                <div className='lg:grid lg:grid-cols-12 lg:gap-2 my-2'>
                                    {
                                        formAvailibility.map((element) => {
                                            var selectValue
                                            var descSelect
                                            if (Object.entries(element.availibility_file[0]).length === 0) {
                                                selectValue = "1"
                                            } else {
                                                selectValue = element.availibility_file[0].type
                                                if (selectValue === "4") {
                                                    descSelect = element.availibility_file[0].desc_availibility_file.desc
                                                } else {
                                                    descSelect = ""
                                                }
                                            }
                                            return (
                                                <div className="lg:col-span-6">
                                                    <InputOptionWithDesc label={element.label} name={element.name} selectValue={selectValue} descSelect={descSelect} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className='mt-3'>
                                    <center>
                                        <ButtonPrimary type="submit" style={'bg-default'} loading={loadingSubmit} textStyle={'text-white'} text_button={'Simpan'} />
                                    </center>
                                </div>
                            </form>
                        </div>
                    </div>
                    {showSuccess === true && 
                        <TailwindModal showCancelButton={false} confirmButtonText={"Ok"} showConfirmButton={true} title={"Berhasil"} text={"Dokumen berhasil divalidasi"} confirmButtonAction={() => setShowSuccess(false)} type="success"/>
                    }
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

export default DocumentValidation

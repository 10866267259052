import $ from 'jquery'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ButtonPrimary from '../../../component/buttons/ButtonPrimary'
import { apiWithParams, getApi, postApi } from '../../../connection/Api'
import StaticData from '../../../connection/StaticData'
import { useAllStatusTrack, useAllTrack, usePosition } from '../../../zustand/Index'
import DocumentCard from '../../../container/card/DocumentCard'
import Input from '../../../container/inputs/Input'
import InputRadio from '../../../container/inputs/InputRadio'
import Select from '../../../container/inputs/Select'
import TailwindModal from '../../../container/modal/TailwindModal'

const EditDocument = () => {
    const { id } = useParams()
    const staticData = new StaticData()
    const [dataDocument, setDataDocument] = useState([])
    const [form, setForm] = useState([])
    const [jenisLs, setJenisLs] = useState([])
    const [currentLs, setCurrentLs] = useState()
    const [showInfoUpdate, setShowInfoUpdate] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const [nilai, setNilai] = useState(0)
    const [startVal, setStartVal] = useState(false)
    const [pajak, setPajak] = useState(0)
    const [nilaiPajak, setNilaiPajak] = useState(0)
    const [idPajak, setIdPajak] = useState()
    const [pph, setPph] = useState(0)
    const [nilaiPph, setNilaiPph] = useState(0)
    const [jenisKodeRekening, setJenisKodeRekening] = useState(0)
    const { position, setPosition } = usePosition()
    const { allTrack, setAllTrack } = useAllTrack()
    const { allStatusTrack, setAllStatusTrack } = useAllStatusTrack()
    const [dataKodeRekening, setDataKodeRekening] = useState(staticData.accountCode)
    const navigate = useNavigate()
    

    const getData = async () => {
        setLoading(true)
        const resultDocument = await getApi(`document-number/get-by-id?id=${id}`)
        setDataDocument(resultDocument.data)

        const resultPosition = await getApi('form/all-position')
        setPosition(resultPosition.data)

        const resultAllTrack = await getApi('document/all-track')
        setAllTrack(resultAllTrack.data)

        const resultAllStatusTrack = await getApi('document/all-status-track')
        setAllStatusTrack(resultAllStatusTrack.data)

        const getForm = await apiWithParams('form/form-by-ls', resultDocument.data.jenis_ls_id)
        setForm(getForm.data)

        const allJenisLs = await getApi('form/jenis-ls')
        setJenisLs(allJenisLs.data)

        setCurrentLs(resultDocument.data.jenis_ls_id)
        setLoading(false)
    }

    const changeLs = async (e) => {
        const lsId = e.target.value
        setCurrentLs(lsId)
        const getForm = await apiWithParams('form/form-by-ls', e.target.value)
        setForm(getForm.data)

        let selectls = document.getElementsByName('jenis_ls')[0].value
        document.getElementById('form-edit-user').reset()
        document.getElementsByName('jenis_ls')[0].value = selectls

        setNilai(0)
        setPajak(0)
        setPph(2)
        setJenisKodeRekening('')
    }

    const updateDocument = async (e) => {
        e.preventDefault()
        setLoadingUpdate(true)
        const formData = new FormData(e.target)
        formData.append('document_number', dataDocument.number)
        let data = Object.fromEntries(formData.entries())
        const update = await postApi('document/update', data)
        const resultDocument = await getApi(`document-number/get-by-id?id=${dataDocument.id}`)
        setDataDocument(resultDocument.data)
        setShowInfoUpdate(true)
        setLoadingUpdate(false)
    }

    const showww = (company) => {
        document.getElementsByName('nama_perusahaan')[0].value = company.company_name
        document.getElementsByName('nama_pimpinan')[0].value = company.directur_name
        document.getElementsByName('no_rekening')[0].value = company.account_number
        document.getElementsByName('no_npwp')[0].value = company.npwp_number
    }

    const changeNilaiBersih = async (e) => {
        console.log("change")
        var Nilai, NilaiPajak, Pph, NilaiPph, JenisKodeRekening, PersenPajak, ShowNilaiPajak, ShowNilaiPph, PersenPph, JenisLs, IdPajak, ElementPajak


        if(currentLs === 3){
            if(e.target.name === 'nilai_hibah'){
                document.getElementsByName('nilai_hibah')[0].value = staticData.intToRupiah(staticData.rupiahToInt(document.getElementsByName('nilai_hibah')[0].value))
            }
            if(e.target.name === "tgl_spp"){
                document.getElementsByName('tgl_spm')[0].value = e.target.value
            }
        }else{
            Nilai = staticData.rupiahToInt(document.getElementsByName('nilai')[0].value)

            if(e.target.name === 'nilai'){
                document.getElementsByName('nilai')[0].value = staticData.intToRupiah(Nilai)
            }
            if(e.target.name === 'nilai_pajak'){
                document.getElementsByName('nilai_pajak')[0].value = staticData.intToRupiah(staticData.rupiahToInt(document.getElementsByName('nilai_pajak')[0].value))
            }
            if(e.target.name === 'nilai_pph'){
                document.getElementsByName('nilai_pph')[0].value = staticData.intToRupiah(staticData.rupiahToInt(document.getElementsByName('nilai_pph')[0].value))
            }

            if (e.target.name === "nama_perusahaan") {
                let div, span
                if (e.target.value != "") {
                    const data = {
                        'company_name': e.target.value
                    }
                    const company = await postApi('company/all', data)
                    let result = company.data
                    if (document.getElementById('wrap-company-name')) {
                        document.getElementById('wrap-company-name').remove()
                    }
                    if (result.length >= 1) {     
                        div = document.createElement('div')
                        div.setAttribute('id', 'wrap-company-name')
                        result.map(company => {
                            span = document.createElement('span')
                            span.setAttribute('class', 'block')
                            span.onclick = () => {showww(company)}
                            span.innerHTML = '> '+company.company_name
                            div.append(span)
                        })
                        e.target.parentNode.parentNode.parentNode.append(div)
                    }
                } else {
                    if (document.getElementById('wrap-company-name')) {
                        document.getElementById('wrap-company-name').remove()
                    }
                }
            }

            if(JenisLs === '1'){
                if(e.target.name === "tgl_spp"){
                    document.getElementsByName('tgl_spm')[0].value = e.target.value
                }
            }

            if(e.target.name === 'nilai' || e.target.name === 'pph'){
                PersenPph = document.getElementsByName('pph')[0].value
                ShowNilaiPph = Nilai * (PersenPph / 100)
                document.getElementsByName('nilai_pph')[0].value = staticData.intToRupiah(ShowNilaiPph.toFixed())
            }

            ElementPajak =  document.getElementsByName('pajak')
            for(let i = 0; i < ElementPajak.length; i++){
                if(ElementPajak[i].checked){
                    IdPajak = ElementPajak[i].id
                }
            }

            if(e.target.name === 'pajak' || e.target.name === 'nilai'){
                if(IdPajak === "25" || IdPajak === "34"){
                    PersenPajak = 11
                    ShowNilaiPajak = Nilai * (PersenPajak / 100)
                    document.getElementsByName('nilai_pajak')[0].value = staticData.intToRupiah(ShowNilaiPajak.toFixed())

                }else if(IdPajak === "26" || IdPajak === "35"){
                    PersenPajak = 10
                    ShowNilaiPajak = Nilai * (PersenPajak / 100)
                    document.getElementsByName('nilai_pajak')[0].value = staticData.intToRupiah(ShowNilaiPajak.toFixed())
                }
            }

            JenisKodeRekening = document.getElementsByName('jenis_kode_rekening')[0].value
            NilaiPajak = staticData.rupiahToInt(document.getElementsByName('nilai_pajak')[0].value)
            NilaiPph = staticData.rupiahToInt(document.getElementsByName('nilai_pph')[0].value)


            if(isNaN(NilaiPajak)){
                NilaiPajak = 0
            }
            if(isNaN(NilaiPph)){
                NilaiPph = 0
            }

            if(IdPajak === "25" || IdPajak === "34"){
                if(Nilai >= 2300000){
                    Nilai = Nilai - NilaiPajak
                    document.getElementsByName('nilai_pajak')[0].classList.remove('input-bg-secondary')
                }else{
                    document.getElementsByName('nilai_pajak')[0].classList.add('input-bg-secondary')
                }
            }else if(IdPajak === "26" || IdPajak === "35"){
                if(JenisKodeRekening === 'Makan & Minum'){
                    Nilai = Nilai - NilaiPajak
                    document.getElementsByName('nilai_pajak')[0].classList.remove('input-bg-secondary')
                }else{
                    document.getElementsByName('nilai_pajak')[0].classList.add('input-bg-secondary')

                }
            }

            Nilai = Nilai - NilaiPph

            if(Nilai < 0){
                Nilai = 0
            }
            document.getElementsByName('nilai_bersih')[0].value = staticData.intToRupiah(Nilai)
        }
    }


    function showForm() {
        return (
            form.map(item => {
                console.log(dataDocument.document.filter((doc) => doc.form_document_by_ls_id === item.id)[0])
                console.log(item.id)
                let value
                if (dataDocument.document.filter((doc) => doc.form_document_by_ls_id === item.id)[0] === undefined) {
                    value = ''
                }else {
                    value = dataDocument.document.filter((doc) => doc.form_document_by_ls_id === item.id)[0].data_value
                }
                if (item.type === "option") {
                    return (
                        <Select name={item.name} option={item.form_option} onchange={(e) => changeNilaiBersih(e)} optionKeyValue={'value'} optionKeyName={'text'} tailwindTheme='gray-500' label={item.label} selectedValue={value} customTailwind={`my-2`}/>
                    )
                } else if (item.type === "text" || item.type === "date") {
                    if (item.name === "nilai_hibah") {
                        value = staticData.intToRupiah(value)
                    }
                    return (
                        <div>
                            <Input readonly={item.readonly} textLabel={item.label} name={item.name} required={item.required} type={item.type} onchange={(e) =>  changeNilaiBersih(e) } value={value} tailwindTheme='gray-500' customTailwind={`my-2`}/>
                            <div>
                                {item.id == 4 || item.id == 23 ? (
                                    <div className='bg-gray-300 p-3 rounded-xl mt-2'>
                                        <div>
                                            <input type="text" className='rounded-xl border-gray-200 w-full' placeholder='Cari' onChange={(e) => {
                                                let value = e.target.value
                                                // Convert the searchTerm to lowercase for case-insensitive search
                                                const lowerCaseSearchTerm = value.toLowerCase();

                                                // Filter the JSON array based on the search term
                                                let dataJson = staticData.accountCode
                                                const searchResults = dataJson.filter(item => {
                                                    // Check if the searchTerm matches any key in the current object
                                                    return Object.values(item).some(value =>
                                                        value.toString().toLowerCase().includes(lowerCaseSearchTerm)
                                                    );
                                                });

                                                setDataKodeRekening(searchResults)
                                            }} />
                                        </div>
                                        <div className='max-h-60 overflow-y-scroll my-3'>
                                            {dataKodeRekening.map((data, index) => (
                                                <div id={data.kode} className='my-2 mx-2' onClick={() => {
                                                    $('input[name="kode_rekening"]').val(data.kode)
                                                }}>
                                                    <p>{`${index + 1}. ${data.kode} -> ${data.nama} -> ${data.bagian}`}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                        
                    )
                } else if (item.type === "radio") {
                    return (
                        <InputRadio readonly={item.readonly} value={value} textLabel={item.label} name={item.name} required={item.required} type={item.type} options={item.form_option} onclick={(e) => { changeNilaiBersih(e) }} tailwindTheme='gray-500' customTailwind={`my-2`}/>
                    )
                } else {
                    return (
                        <div>
                            <span>Field Tidak Ada</span>
                        </div>
                    )
                }
            })
        )
    }
    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if (startVal === true) {
            changeNilaiBersih()
            setStartVal(false)
        }
    }, [nilai, pph, pajak, jenisKodeRekening])

    return (
        <div>
            {loading === false ? (
                <div className='lg:grid lg:grid-cols-12 gap-2'>
                    <div className='lg:col-span-4'>
                        <div className='sticky top-2 mb-2'>
                            <DocumentCard item={dataDocument} allStatusTrack={allStatusTrack} allTrack={allTrack} position={position} setDoc={(value) => setDataDocument(value)} />
                        </div>
                    </div>
                    <div className='lg:col-span-8 bg-white rounded-xl px-3 py-2'>
                        <div className='flex flex-auto justify-center items-center'>
                            <span className='text-gray-600 text-lg font-bold'>Edit Dokumen</span>
                        </div>
                        <form id="form-edit-user" onSubmit={(e) => { updateDocument(e) }}>
                            <Select name={'jenis_ls'} onchange={(e) => changeLs(e)} option={jenisLs} optionKeyName={'text'} optionKeyValue={'value'} selectedValue={dataDocument.jenis_ls_id} tailwindTheme='gray-500' label='Pilih Jenis LS' />
                            {showForm()}
                            <div className='my-2'>
                                <ButtonPrimary type="submit" text_button="Update" style={`bg-default py-2 my-4`} loading={loadingUpdate} textStyle={`text-white`}/>
                            </div>
                        </form>
                    </div>
                    {showInfoUpdate === true && 
                        <TailwindModal showCancelButton={false} confirmButtonText={"Ok"} showConfirmButton={true} title={"Berhasil"} text={"Dokumen berhasil divalidasi"} confirmButtonAction={() => {
                            setShowInfoUpdate(false)
                            navigate(`/dashboard/dokumen/validasi/${id}`)
                        }} type="success"/>
                    }
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

export default EditDocument

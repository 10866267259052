import React, { useState } from 'react'

const AvailibilityAccordion = ({ name, type, desc }) => {
    const [showDesc, setShowDesc] = useState(false)
    return (
        <div>
            <div className='flex flex-row' onClick={() => {if(type == 4 ){setShowDesc(showDesc === false ? true : false)}}}>
                <div>
                    {
                        type == 4 ? (
                            <div>
                                {
                                    showDesc === true ? (
                                        <i className='fa-solid fa-chevron-down text-xs mr-2'></i>
                                    ) : (
                                        <i className='fa-solid fa-chevron-right text-xs mr-2'></i>
                                    )
                                }
                            </div>
                        ) : (
                            <i className='fa-regular fa-circle text-xs mr-2'></i>
                        )
                    }
                </div>
                <div className='flex flex-auto'>
                    <span>{name}</span>
                </div>
                <div>
                    {
                        type == 1 &&
                        <i className='fa-solid fa-check text-green-400 text-sm'></i>
                    }
                    {
                        type == 2 &&
                        <i className='fa-solid fa-xmark text-red-400 text-sm'></i>
                    }
                    {
                        type == 3 &&
                        <i className='fa-solid fa-minus text-gray-400 text-sm'></i>
                    }
                    {
                        type == 4 &&
                        <i className='fa-solid fa-rotate-left text-dark text-sm'></i>
                    }
                </div>
            </div>
            {showDesc === true &&
                <div className='mt-1'>
                    <div className='w-full rounded-md bg-gray-100 px-3 py-1'>
                        <div>
                            {desc}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default AvailibilityAccordion

import React from 'react'
import { useNavigate } from 'react-router-dom'

const RegisterCard = () => {
    const navigate = useNavigate()
    return (
        <div className='w-full rounded-lg border-0 bg-white'>
            <div className='w-full rounded-xl bg-white p-2'>
                <div className='flex flex-row'>
                    <div className='w-auto h-full p-2 justify-center items-center'>
                        <i className='fa-regular fa-file-lines text-dark text-4xl'></i>
                    </div>
                    <div className='flex flex-col px-2'>
                        <div>
                            <span className='text-dark text-lg'>Registrasi Dokumen</span>
                        </div>
                        <div className='flex flex-row'>
                            <div className='mr-2'>
                                <button onClick={() => navigate('/dashboard/dokumen/registrasi')} className='rounded-xl border-2 border-default py-0 px-2 text-default font-bold'>Klik Disini <i className='fa-solid fa-arrow-right'></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegisterCard

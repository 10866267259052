import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getApi, getApiPublic } from "../../connection/Api";
import CardAuth from "../../container/card/CardAuth";
import {
    useAllStatusTrack,
    useAllTrack,
    useDocs,
    usePosition,
} from "../../zustand/Index";
import DocumentCardGuest from "../../container/card/DocumentCardGuest";

const ListDocumentByAccountNumber = () => {
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1)
    const { docs, setDocs } = useDocs();
    const { accountNumber } = useParams();
    const { position, setPosition } = usePosition();
    const { allTrack, setAllTrack } = useAllTrack();
    const { allStatusTrack, setAllStatusTrack } = useAllStatusTrack();

    const getData = async () => {
        const document = await getApiPublic(
            `document/get-by-account-number?page=1&account_number=${accountNumber}`
        );
        setDocs(document.data);

        const resultPosition = await getApi('form/all-position')
        setPosition(resultPosition.data)

        const resultAllTrack = await getApi('document/get-all-track')
        setAllTrack(resultAllTrack.data)

        const resultAllStatusTrack = await getApi('document/all-status-track')
        setAllStatusTrack(resultAllStatusTrack.data)
        setLoading(false);
    };
    const settingData = async (currentPage) => {
        // setLoadingFilter(true)
        const resultDocument = await getApiPublic(
            `document/get-by-account-number?page=${currentPage}&account_number=${accountNumber}`
        );
        setPage(currentPage)
        setDocs(resultDocument.data)
        // setLoadingFilter(false)
        window.location.href = '#document-wrapper'
    }
    useEffect(() => {
        getData();
    }, []);
    return (
        <div className="flex flex-1 bg-secondary w-full min-h-screen">
            {loading === false ? (
                <div className="lg:grid lg:grid-cols-12 w-full gap-2">
                    <div className="lg:col-span-3 p-6 my-1 lg:my-0">
                        <CardAuth />
                    </div>
                    <div id="document-wrapper" className="lg:col-span-9 p-6">
                        <div className="my-3">
                            <span className="text-xl block">No. Rekening</span>
                            <span className="text-3xl font-bold">{accountNumber}</span>
                        </div>
                        <div className="lg:grid lg:grid-cols-3 justify-center lg:gap-5">
                            {docs.data.map((item) => (
                                <div className="my-4 lg:my-0">
                                    <DocumentCardGuest
                                        item={item}
                                        position={position}
                                        allTrack={allTrack}
                                        allStatusTrack={allStatusTrack}
                                        setDoc={() => { }}
                                    />
                                </div>
                            ))}
                        </div>
                        <div>
                            <center>
                                <div className='flex flex-auto justify-center items-center'>
                                    <div className='gap-1 w-full lg:w-auto'>
                                        {docs.links.map(item => {
                                            return (
                                                <button
                                                    disabled={item.url === null ? true : false}
                                                    className={`  
                                        px-2 
                                        my-2
                                        mx-1
                                        rounded-lg
                                        ${item.active === true &&
                                                        `bg-default text-white border-default text-default`
                                                        }
                                        ${item.active === false && item.url !== null ?
                                                            `bg-secondary border-default text-default` : ``
                                                        }
                                        ${item.active === false && item.url === null ?
                                                            `bg-white border-gray text-gray-300` : ``
                                                        }`}
                                                    onClick={() => {
                                                        let showPage
                                                        if (item.label === 'pagination.previous' && item.url !== null) {
                                                            showPage = docs.current_page - 1
                                                        } else if (item.label === 'pagination.next' && item.url !== null) {
                                                            showPage = docs.current_page + 1
                                                        } else if (item.label !== 'pagination.next' && item.label !== 'pagination.previous') {
                                                            showPage = item.label
                                                        } else {
                                                            showPage = 1
                                                        }
                                                        console.log(item.label)
                                                        settingData(showPage)
                                                    }}
                                                >
                                                    {item.label === 'pagination.previous' &&
                                                        <i className='fa-solid fa-chevron-left'></i>
                                                    }
                                                    {item.label === 'pagination.next' &&
                                                        <i className='fa-solid fa-chevron-right'></i>
                                                    }
                                                    {item.label !== 'pagination.next' && item.label !== 'pagination.previous' ? (
                                                        item.label
                                                    ) : ''
                                                    }
                                                </button>
                                            )
                                        })}
                                    </div>
                                </div>
                            </center>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default ListDocumentByAccountNumber;

import React from "react"
import { useNavigate } from "react-router-dom"

const PageNotFound = () => {
    const navigate = useNavigate()
    return (
        <div className="flex h-screen w-screen justify-center items-center">
            <div className="lg:w-80 w-auto">
                <div>
                    <img src={process.env.PUBLIC_URL + "/icon/NotFound.png"} alt="" />
                </div>
                <div className="flex flex-col w-full items-start justify-start">
                    <div className="rounded-r-full rounded-bl-full px-2 border-2 border-default bg-purple-200 text-default">
                        <span>Err: 404</span>
                    </div>
                    <div>
                        <span className="text-xl text-default font-bold">Upps, Halaman tidak ditemukan</span>
                    </div>
                </div>
                <div className="flex w-full justify-center items-center mt-4">
                    <button onClick={() => navigate('/dashboard')} className="py-2 px-3 rounded-2xl bg-default text-white"><i className="fa-solid fa-home mr-2"></i>Kembali ke Beranda</button>
                </div>
            </div>
        </div>
    )
}
export default PageNotFound
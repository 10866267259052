import React from 'react'
import TailwindSpin from '../../component/loading/TailwindSpin'

const TailwindModal = ({ title, text, showCancelButton, cancelButtonText, canceButtonAction = {}, showConfirmButton, confirmButtonText, confirmButtonAction = {}, loadingConfirm, type = "danger" }) => {
    return (
        <div>
            <div class="relative z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
                    <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div class="sm:flex sm:items-start">
                                    <div class={`
                                        mx-auto 
                                        flex 
                                        h-12 
                                        w-12 
                                        flex-shrink-0 
                                        items-center 
                                        justify-center 
                                        rounded-full
                                        ${type === "danger" &&
                                            `bg-red-100` 
                                        } 
                                        ${type === "warning" &&
                                            `bg-amber-100` 
                                        } 
                                        ${type === "success" &&
                                            `bg-green-100` 
                                        } 
                                        sm:mx-0 
                                        sm:h-10 
                                        sm:w-10
                                    `}>
                                        <i class={`${type != "success" ? "fa-solid fa-triangle-exclamation" : "fa-solid fa-check"}`}></i>
                                    </div>
                                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">{title}</h3>
                                        <div class="mt-2">
                                            <p class="text-sm text-gray-500">{text}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                {showConfirmButton === true &&
                                    <button 
                                        onClick={confirmButtonAction} 
                                        type="button" 
                                        className={`
                                            inline-flex 
                                            w-full 
                                            justify-center 
                                            rounded-md
                                            ${type === "danger" &&
                                                `bg-red-600 
                                                hover:bg-red-500`
                                            }
                                            ${type === "warning" &&
                                                `bg-amber-300 
                                                hover:bg-amber-200`
                                            }
                                            ${type === "success" &&
                                                `bg-green-600 
                                                hover:bg-green-500`
                                            }
                                            text-white 
                                            px-3 
                                            py-2 
                                            text-sm 
                                            font-semibold 
                                            shadow-sm 
                                            sm:ml-3 
                                            sm:w-auto
                                        `}>
                                            {loadingConfirm === true ? (<TailwindSpin/>) : confirmButtonText}
                                        </button>
                                }
                                {showCancelButton === true &&
                                    <button onClick={canceButtonAction} type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">{cancelButtonText}</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TailwindModal
